import axios from "axios";
import React, { useState, useEffect } from "react";
import apiUrl from "../../../../../config";
import { MyProfileContext } from "../../../../../context/ProfileProvider";
import ProfileProgress from "./ProfileProgress";
import SuccessPopUp from "../../../../../popups/SuccessPopUp";
import ErrorPopup from "../../../../../popups/ErrorPopup";
import DetailsData from "../../../../../data/State.json";

export const PersonalDetails = () => {
  const userProfileId = localStorage.getItem("profileId");
  const { myProfile, userProfile } = React.useContext(MyProfileContext);
  const [clickedEdit, setClickedEdit] = useState(false);
  const [clickUpdate, setClickUpdate] = useState(false);
  const [age, setAge] = useState(0);
  const [errors, setErrors] = useState("");
  const [updateProfile, setUpdateProfile] = useState({
    maritalStatus: myProfile.maritalStatus,
    familyStatus: myProfile.familyStatus,
    height: myProfile.height,
    disabilities: myProfile.disabilities,
    familyType: myProfile.familyType,
  });

  const [showError, setShowError] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [success, setSuccess] = useState("");
  const handleShowError = () => {
    setShowError(true);
  };

  const handleCloseError = () => {
    setShowError(false);
  };

  const handleShowSuccess = () => {
    setshowSuccess(true);
  };

  const handleCloseSuucess = () => {
    setshowSuccess(false);
  };

  const Updatedetali = async () => {
    try {
      const res = await axios.patch(
        apiUrl + `v1/profile/updateProfile/${userProfileId}`,
        updateProfile
      );
      setSuccess("Profile Updated successfully");
      handleShowSuccess();
      if (res.status == 200) {
        userProfile();
      }
    } catch (err) {
      console.log(err);
      setErrors(err.response.data.message);
      handleShowError();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "feet" || name === "inches") {
      setUpdateProfile((prevDetails) => ({ ...prevDetails, [name]: value }));
      setUpdateProfile((prevDetails) => ({ ...prevDetails, height: `${prevDetails.feet}-${prevDetails.inches}` }));
    }
    setUpdateProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const commonFunction = () => {
    setClickedEdit(false);
    Updatedetali();
  };
  useEffect(() => {
    if (myProfile?.dateOfBirth) {
      const birthDate = new Date(myProfile.dateOfBirth);
      const currentDate = new Date();
      const ageDiff = currentDate.getFullYear() - birthDate.getFullYear();
      setAge(ageDiff);
    }
  }, [myProfile?.dateOfBirth]);

  return (
    <>
      <div className="basic-card">
        <div className="profile-progress">
          <ProfileProgress />
        </div>
        <div className="basic-details-edit">
          <div className="basic-details-edit-button">
            <h4 className="basic-details-heading">
              <b>Personal Details</b>
            </h4>
            <div className="edit-name-button">
              {clickedEdit ? (
                <p className="edit-name" onClick={() => commonFunction()}>
                  Update
                </p>
              ) : (
                <span
                  className="edit-name"
                  onClick={() => setClickedEdit((preState) => !preState)}
                >
                  Edit
                  <img className="edit-button" src="/images/edit.png" alt="" />
                </span>
              )}{" "}
            </div>
          </div>
          {clickedEdit && (
            <>
              <div className="input-details">
                <div className="first-label">
                  <div className="label-input">
                    <div className="label-edit">
                      <div className="label-input-fields">
                        <label htmlFor="" className="label-content">
                        Marital status
                        </label>
                        <select
                          className="input-edit-data"
                          type="text"
                          placeholder="Maritual Status"
                          value={updateProfile.maritalStatus}
                          onChange={handleInputChange}
                          name="maritalStatus"
                        >
                          <option value="">Select</option>
                          {DetailsData.maritualStatus.map((maritalStatus) => (
                            <option key={maritalStatus} value={maritalStatus}>
                              {maritalStatus}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="label-input-fields">
                        <label htmlFor="" className="label-content">
                          Height
                        </label>
                        <div className="personal-hight">
                        <select
                          className="personal-height-select"
                          name="feet"
                          onChange={handleInputChange}
                          value={updateProfile.feet}
                        >
                          <option value="">Feet</option>
                          {DetailsData.heights[0].feet.map((feet) => (
                            <option key={feet} value={feet}>
                              {feet}
                            </option>
                          ))}
                        </select>
                        <select
                          className="personal-height-select"
                          name="inches"
                          onChange={handleInputChange}
                          value={updateProfile.inches}
                        >
                          <option value="">Inches</option>
                          {DetailsData.heights[0].inches.map((inches) => (
                            <option key={inches} value={inches}>
                              {inches}
                            </option>
                          ))}
                        </select>
                        </div>
                      </div>
                      <div className="label-input-fields">
                        <label htmlFor="" className="label-content">
                          Family type
                        </label>

                        <select
                          className="input-edit-data"
                          placeholder="Family type"
                          value={updateProfile.familyType}
                          onChange={handleInputChange}
                          name="familyType"
                        >
                          <option value="" className="">
                            {" "}
                            Select{" "}
                          </option>
                          {DetailsData.familyType.map((familyType) => (
                            <option key={familyType} value={familyType}>
                              {familyType}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="label-input-change">
                    <div className="label-edit">
                      <div className="label-input-fields">
                        <label htmlFor="" className="label-content">
                          Family Status
                        </label>
                        <select
                          className="input-edit-data"
                          placeholder="Family Status"
                          value={updateProfile.familyStatus}
                          onChange={handleInputChange}
                          name="familyStatus"
                        >
                          <option value="" className="">
                            Select
                          </option>
                          {DetailsData.familySttus.map((familySttus) => (
                            <option key={familySttus} value={familySttus}>
                              {familySttus}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="label-input-fields">
                        <label htmlFor="" className="label-content">
                          Any Disabilities
                        </label>
                        <select
                          className="input-edit-data"
                          value={updateProfile.disabilities}
                          onChange={handleInputChange}
                          name="disabilities"
                        >
                          <option value="">Select</option>
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                        {updateProfile.disabilities === "Yes" && (
                          <input
                            className="input-edit-data"
                            type="text"
                            placeholder="Enter disabilities"
                            value={updateProfile.otherDisabilities}
                            onChange={handleInputChange}
                            name="otherDisabilities"
                          />
                        )}
                      </div>
                      {/* <div className="label-input-fields">
                        <label htmlFor="" className="label-content">Any disabilities</label>
                        <input
                          className="input-edit-data"
                          type="text"
                          placeholder={"Loremprssu"}
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {!clickedEdit && (
            <>
              <div className="input-details">
                <div className="first-label">
                  <div className="label-input">
                    <div className="label">
                      <div className="label-input-fields">
                        <label htmlFor="" className="label-content">
                        Marital status
                        </label>{" "}
                        <p className="input-data-basic-details">
                          {myProfile?.maritalStatus}
                        </p>
                      </div>
                      <div className="label-input-fields">
                        {" "}
                        <label htmlFor="" className="label-content">
                          Height
                        </label>
                        <p className="input-data-basic-details">
                          {myProfile?.height}
                        </p>
                      </div>
                      <div className="label-input-fields">
                        <label htmlFor="" className="label-content">
                          Family type
                        </label>{" "}
                        <p className="input-data-basic-details">
                          {myProfile?.familyType}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="label-input-change-data">
                    <div className="label">
                      <div className="label-input-fields">
                        <label htmlFor="" className="label-content">
                          Family Status
                        </label>{" "}
                        <p className="input-data-basic-details">
                          {myProfile?.familyStatus}
                        </p>
                      </div>
                      <div className="label-input-fields">
                        {" "}
                        <label htmlFor="" className="label-content">
                          Any Disabilities
                        </label>{" "}
                        <p className="input-data-basic-details">
                          {myProfile?.disabilities}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {showSuccess && (
          <SuccessPopUp message={success} onClose={handleCloseSuucess} />
        )}
        {showError && (
          <ErrorPopup message={errors} onClose={handleCloseError} />
        )}
      </div>
    </>
  );
};
