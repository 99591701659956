import React from "react";
import Navbar from "../../../components/Navbar";
import Header from "./Header";
import Footer from "../../../components/Footer";

const Home = () => {
  return (
    <>
    <div className="sticky-top"><Navbar /></div>
      <Header />
      <Footer />
    </>
  );
};

export default Home;
