import React, { useEffect, useState } from "react";
import "../css/Header.css";
import Feature from "./Feature";
import ReviewsSlider from "../reviewscarousel/ReviewsSlider";
import JoinWithus from "./JoinWithus";
import { Link, useResolvedPath } from "react-router-dom";
import DetailsData from "../../../../src/data/State.json";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ErrorPopup from "../../../popups/ErrorPopup";
import Religion from "../../../data/Religion.json";
export default function Header() {
  const [selectedCaste, setSelectedCaste] = useState("");
  const [details, setDetails] = useState({
    religion: "",
    caste: "",
    subCaste: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDetails({ ...details, [name]: value });
  };
  const [showError, setShowError] = useState(false);
  const [errors, setErrors] = useState("");
  const religions = Object.keys(Religion);
  useEffect(() => {
    setErrors("Register to view more");
  }, []);
  const handleShowError = () => {
    setShowError(true);
  };
  const handleCloseError = () => {
    setShowError(false);
  };
  function getOption(caste) {
    if (details.caste) {
      const subcaste = caste?.filter((val) => val.name === details.caste);
      return subcaste[0]?.subcaste;
    }
  }
  // const handleChange=(e)=>{
  //   const { name, value } = e.target;
  //   setDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  //   if(name==="caste"  )
  //     setSelectedCaste(value);
  //   setDetails((prevDetails) => ({
  //     ...prevDetails,
  //     [name]: value,
  //     subCaste: "",
  //   }));
  // }
  return (
    <div className="container-fluid">
      <div className="container">
        <div className="container-main">
          <div className="">
            <div className="header-top">
              <h1 className="header-heading">
                Maangalyaam: Where Love Finds its Forever
              </h1>
              <p className="header-para">
                Discover Endless Possibilities in Your Matrimony Journey
              </p>

              <a className="start-Journey" href="#Join-With-us">
                {" "}
                <button className="header-startbtn">Start Your Journey</button>
              </a>
            </div>
            <div className="header-bottom ">
              <div className="header-bottomfields ">
                <div className="header-inner inputfields">
                  <p className="mainhead">Main Heading</p>
                  <div className="inner-first">
                    <div className="first">
                      <div className="heading-input-label-para">
                        {" "}
                        <label className="header-label">Looking for</label>
                      </div>
                      <select className="form-select">
                        <option>Bride or Groom</option>
                        <option>Bride</option>
                        <option>Groom</option>
                      </select>
                    </div>
                    <div className="first">
                      <div className="heading-input-label-para">
                        {" "}
                        <label className="header-label">Age</label>
                      </div>
                      <select className="form-select">
                        <option>Select Age</option>
                        <option>18-25</option>
                        <option>25-30</option>
                        <option>30-35</option>
                        <option>35-40</option>
                      </select>
                    </div>
                  </div>
                  <div className="inner-second">
                    <div className="first">
                      <div className="heading-input-label-para">
                        <label className="header-label">Religion</label>
                      </div>
                      <select
                        className="form-select "
                        name="religion"
                        // onChange={handleChange}
                      >
                        <option className="form-select">Select</option>
                        {DetailsData.religions?.map((religion) => (
                          <option key={religion} value={religion}>
                            {religion}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="first">
                      <div className="heading-input-label-para">
                        <label className="header-label">Mother Tongue</label>
                      </div>
                      <select
                        className="form-select "
                        name="motherTounge"
                        // onChange={handleChange}
                        // value={details.motherTounge}
                      >
                        <option>Select</option>
                        {DetailsData.languages.map((languages) => (
                          <option key={languages} value={languages}>
                            {languages}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="header-search-btn">
                    <button className="searchbtn">
                      <a
                        href="#homepage-filter-cards"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        Search
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main">
          <div className="head-part">
            <h1 className="header-heading">
              Discover Your Perfect Match with Our Powerful Features
            </h1>
            <p className="headpart-para">
              Unlock a world of possibilities with our cutting-edge features
              designed to make your journey to finding love seamless and
              enjoyable. From advanced search options to privacy controls,
              explore the tools that set us apart and maximize your chances of
              meeting your ideal life partner.
            </p>
          </div>
          <div className="second row gx-0">
            <div className="header-search col-lg-3">
              <div className="">
                <h1 className="advance-search">Advance Search</h1>
              </div>
              <div className="header-form-fileds">
                <div className="basicdetails">Basic Details</div>
                <div>
                  <div className="searchlabel">
                    <label>Age</label>
                  </div>
                  <select className="searchinput  form-control ">
                    <option>Select Age</option>
                    <option>18-25</option>
                    <option>25-30</option>
                    <option>30-35</option>
                    <option>35-40</option>
                  </select>
                </div>
                <div className="searchlabel">
                  <label>Height</label>
                </div>
                <div className="d-flex gap-2 ">
                  <select
                    className="searchinput form-control "
                    name="feet"
                    // onChange={handleChange}
                    // value={details.feet}
                  >
                    <option value="">From</option>
                    {DetailsData.heights[0].feet.map((feet) => (
                      <option key={feet} value={feet}>
                        {feet}
                      </option>
                    ))}
                  </select>
                  <select
                    className="searchinput form-control"
                    name="feet"
                    // onChange={handleChange}
                    // value={details.feet}
                  >
                    <option value="">to</option>
                    {DetailsData.heights[0].feet.map((feet) => (
                      <option key={feet} value={feet}>
                        {feet}
                      </option>
                    ))}
                  </select>
                </div>
                <p className="basicdetails">Caste & Religion Details</p>
                <div>
                  <label>Religion</label>
                  <select
                    className="searchinput form-control "
                    name="religion"
                    onChange={handleChange}
                    value={details.religion}
                  >
                    <option value="">Select</option>
                    {religions.map((religion) => (
                      <option key={religion} value={religion}>
                        {religion}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <div className="searchlabel">
                    <label>Caste</label>
                  </div>
                  <select
                    className="form-control select"
                    name="caste"
                    value={details.caste}
                    onChange={handleChange}
                  >
                    <option value="">Select Caste</option>
                    {Religion[`${details.religion}`]?.map((caste) => (
                      <option key={caste.name} value={caste.name}>
                        {caste.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <div className="searchlabel">
                    <label>Sub-Caste</label>
                  </div>

                  <select
                    className="searchinput form-control select"
                    name="subCaste"
                    value={details.subCaste}
                    onChange={handleChange}
                  >
                    <option value="">Select Sub Caste</option>
                    {getOption(Religion[details.religion])?.map((val) => {
                      return (
                        <option key={val} value={val}>
                          {val}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="header-viewmore">
                  <button
                    className="header-viewmore-btn"
                    // onClick={handleShowError}
                  >
                    View more
                  </button>
                </div>
              </div>
            </div>
            <div
              className="header-cards-container col-lg-8"
              id="homepage-filter-cards"
            >
              <p className="header-cards-container-prefference">
                Based on your preference
              </p>
              <div className="header-cards-main">
                <div className="header-cards" style={{ filter: "blur(4px)" }}>
                  <img
                    className="first-img-home"
                    src="../Images/cardsfirstimage.svg"
                    alt="Image not found"
                  />
                  <div className="right-card">
                    <h5 className="right-card-head-name">Sravani Reddykonda</h5>
                    <p className="right-card-content-name">26 yrs | 5ft 6in</p>
                    <p className="right-card-content-name">Phn - 912177</p>
                    <p className="right-card-content-name">
                      Mail - sravani@gmail.com
                    </p>
                    <p className="right-card-content-name">Caste</p>
                    <p className="right-card-content-name">Occupation</p>
                  </div>
                </div>
                <div className="header-cards" style={{ filter: "blur(4px)" }}>
                  <img
                    className="first-img-home"
                    src="../Images/five.png"
                    alt="Image not found"
                  />
                  <div className="right-card">
                    <h5 className="right-card-head-name">Sravani Reddykonda</h5>
                    <p className="right-card-content-name">26 yrs | 5ft 6in</p>
                    <p className="right-card-content-name">Phn - 912177</p>
                    <p className="right-card-content-name">
                      Mail - sravani@gmail.com
                    </p>
                    <p className="right-card-content-name">Caste</p>
                    <p className="right-card-content-name">Occupation</p>
                  </div>
                </div>
              </div>
              <div className="header-cards-main">
                <div className="header-cards" style={{ filter: "blur(4px)" }}>
                  <img
                    className="first-img-home"
                    src="../Images/four.png"
                    alt="Image not found"
                  />
                  <div className="right-card">
                    <h5 className="right-card-head-name">Sravani Reddykonda</h5>
                    <p className="right-card-content-name">26 yrs | 5ft 6in</p>
                    <p className="right-card-content-name">Phn - 912177</p>
                    <p className="right-card-content-name">
                      Mail - sravani@gmail.com
                    </p>
                    <p className="right-card-content-name">Caste</p>
                    <p className="right-card-content-name">Occupation</p>
                  </div>
                </div>
                <div className="header-cards" style={{ filter: "blur(4px)" }}>
                  <img
                    className="first-img-home"
                    src="../Images/one.png"
                    alt="Image not found"
                  />
                  <div className="right-card">
                    <h5 className="right-card-head-name">Sravani Reddykonda</h5>
                    <p className="right-card-content-name">26 yrs | 5ft 6in</p>
                    <p className="right-card-content-name">Phn - 912177</p>
                    <p className="right-card-content-name">
                      Mail - sravani@gmail.com
                    </p>
                    <p className="right-card-content-name">Caste</p>
                    <p className="right-card-content-name">Occupation</p>
                  </div>
                </div>
              </div>
              <div className="header-cards-main">
                <div className="header-cards" style={{ filter: "blur(4px)" }}>
                  <img
                    className="first-img-home"
                    src="../Images/emptydp.jpeg"
                    alt="Image not found"
                  />
                  <div className="right-card">
                    <h5 className="right-card-head-name">Sravani Reddykonda</h5>
                    <p className="right-card-content-name">26 yrs | 5ft 6in</p>
                    <p className="right-card-content-name">Phn - 912177</p>
                    <p className="right-card-content-name">
                      Mail - sravani@gmail.com
                    </p>
                    <p className="right-card-content-name">Caste</p>
                    <p className="right-card-content-name">Occupation</p>
                  </div>
                </div>
                <div className="header-cards" style={{ filter: "blur(4px)" }}>
                  <img
                    className="first-img-home"
                    src="../Images/cardsfirstimage.svg"
                    alt="Image not found"
                  />
                  <div className="right-card">
                    <h5 className="right-card-head-name">Sravani Reddykonda</h5>
                    <p className="right-card-content-name">26 yrs | 5ft 6in</p>
                    <p className="right-card-content-name">Phn - 912177</p>
                    <p className="right-card-content-name">
                      Mail - sravani@gmail.com
                    </p>
                    <p className="right-card-content-name">Caste</p>
                    <p className="right-card-content-name">Occupation</p>
                  </div>
                </div>
              </div>
              {showError && (
                <ErrorPopup message={errors} onClose={handleCloseError} />
              )}
            </div>
          </div>
        </div>
      </div>
      <Feature />
      <ReviewsSlider />
      <div id="Join-With-us">
        <JoinWithus />
      </div>
    </div>
  );
}
