import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import apiUrl from "../config";

const MyProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
    const [myProfile, setMyProfile] = useState([]);
    const [allProfiles, setAllProfiles] = useState([]);
    const userProfileId = localStorage.getItem("profileId");
    const userProfile = async () => {
        try {
            if (userProfileId !== undefined && userProfileId !== null) {
                const res = await axios.get(apiUrl + `v1/profile/myProfile/${userProfileId}`);
                setMyProfile(res.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const getAllProfiles = async () => {
        try {
            const res = await axios.get(apiUrl + "v1/profile/allProfiles");
            const blockedProfileIds = myProfile?.additionalDetails?.blockedProfiles || [];
            const filteredProfiles = res.data.filter((val) => {
                const profileId = parseInt(userProfileId);
                const blockedIds = blockedProfileIds.map(obj => obj.id);
                const isBlocked = blockedIds.includes(val.id);
                return val.id !== profileId && val.gender !== myProfile.gender && !isBlocked;
            });
            setAllProfiles(res.data);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            await userProfile();
        };
        fetchData();
    }, [userProfileId]);

    useEffect(() => {
        if (myProfile.gender) {
            getAllProfiles();
        }
    }, [myProfile.gender]);

    const currentDate = new Date();
    if (myProfile && myProfile.additionalDetails && Array.isArray(myProfile.additionalDetails.myPaymentsDetails)) {
        const sortedPayments = myProfile.additionalDetails.myPaymentsDetails.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        if (sortedPayments.length > 0) {
            const mostRecentPayment = sortedPayments[0];
            const paymentCreationDate = new Date(mostRecentPayment.createdAt);
            const timeDifference = currentDate - paymentCreationDate;
            const monthsDifference = timeDifference / (1000 * 60 * 60 * 24 * 30);
            async function updatePaymentStatus() {
                if (mostRecentPayment.paymentType === "Monthly" && monthsDifference >= 6) {
                    try {
                        const res = await axios.patch(apiUrl + `v1/profile/updateProfile/${userProfileId}`, { payment: false });
                    } catch (error) {
                        console.error("Error updating monthly payment status:", error.message);
                    }
                } else if (mostRecentPayment.paymentType === "Yearly" && monthsDifference >= 12) {
                    try {
                        const res = await axios.patch(apiUrl + `v1/profile/updateProfile/${userProfileId}`, { payment: false });
                    } catch (error) {
                        console.error("Error updating yearly payment status:", error.message);
                    }
                }
            }
            updatePaymentStatus();
        }
    }

    const contextValue = { myProfile, allProfiles, userProfile, getAllProfiles };
    return (
        <MyProfileContext.Provider value={contextValue}>
            {children}
        </MyProfileContext.Provider>
    );
};

export { MyProfileContext };
