import React, { useState } from "react";
import "./css/mailVerification.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import apiUrl from "../../config";
import axios from "axios";

const MailVerification = () => {
  const [error, setError] = useState('')
  const [verificationCode, setVerificationCode] = useState(["", "", "", ""]);
  const otp = verificationCode.join("");
  const verificationotp = otp;
  const location = useLocation();
  const details = location.state;
  const navigate = useNavigate();

  const handleInputChange = (index, value) => {
    const newVerificationCode = [...verificationCode];
    newVerificationCode[index] = value;
    setVerificationCode(newVerificationCode);
    if (value !== "" && index < newVerificationCode.length - 1) {
      document.getElementById(`input-${index + 1}`).focus();
    }
  };

  const handleKeyDown = (index, event) => {

    if (event.key === "Backspace" && index >= 0) {
      const newVerificationCode = [...verificationCode];
      if (index > 0) {
        newVerificationCode[index] = "";
        setVerificationCode(newVerificationCode);
      }
      if (index == 3) {
        if (verificationCode[index] === "") {
          console.log(verificationCode[index], "verificationCode[index]")
          document.getElementById(`input-${index - 1}`).focus();
        } else {
          console.log(verificationCode[index], "verificationCode[index]")
          document.getElementById(`input-${index - 0}`).focus();

        }
      } else if (index > 0) {
        document.getElementById(`input-${index - 1}`).focus();
      }
    }
  };


  const otpVerify = async () => {
    try {
      const res = await axios.post(apiUrl + "v1/profile/verifyOtp", {
        otp: verificationotp,
      });
      if (res.data.success === true) {
        const form = new FormData();
        for (const key in details) {
          form.append(key, details[key]);
        }
        const response = await axios.post(apiUrl + "v1/profile/profile", form);
        if (response.data = true) {
          await axios.post(apiUrl + "v1/profile/send", { email: details.email });
          navigate("/setpassword");
        }
      }
    } catch (err) {
      console.log(err);
      setError(err.response.data.message)
    }
  };

  const resendOtp = async () => {
    try {
      setVerificationCode(["", "", "", ""]);
      await axios.post(apiUrl + "v1/profile/sendOtp");
      setError("");
    } catch (err) {
      console.log(err);
    }
  };


  return (
    <div className="mail-verify-bg-container">
      <div className="mail-verify-main-container">
        <div className="mail-logo">
          <img src="../images/maangalyam-logo.svg" alt="image not found" />
        </div>
        <h3 className="mail-heading">Verify Your Mobile</h3>
        <p className="mail-para">We've sent a code to {details?.mobile}</p>
        <div>
          {verificationCode.map((value, index) => (
            <input
              id={`input-${index}`}
              key={index}
              className="mail-input"
              type="text"
              maxLength="1"
              value={value}
              onChange={(e) => handleInputChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
            />
          ))}
        </div>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <p className="mail-bottom-para">
          Didn’t get a code?{" "}
          <span
            className="mail-bottom-para-span"
            onClick={resendOtp}
            style={{ cursor: "pointer" }}
          >
            Click to resend.
          </span>
        </p>
        <div className="mail-buttons">
          <button className="mail-cancel-button" onClick={() => navigate('/registration')}>Cancel</button>{" "}
          <button className="mail-verify-button" onClick={otpVerify}>
            Verify
          </button>
        </div>
      </div>
    </div>
  );
};

export default MailVerification;