import React, { useState } from "react";
import { Outlet, useNavigate, useResolvedPath } from "react-router-dom";
import "./User.css";
import { CiSearch } from "react-icons/ci";

const User = () => {
  let navigate = useNavigate();
  const { pathname } = useResolvedPath();
  let [selectedItem, setSelectedItem] = useState("all");

  const clickData = (nav) => {
    navigate(`${nav}`);
    setSelectedItem(nav);
  };

  const listItemStyle = (item) => {
    return {
      borderBottom: selectedItem === item ? "2px solid #FFB000" : "none",
      fontWeight: selectedItem === item ? "600" : "",
    };
  };

  return (
    <>
      <div
        style={{ display: pathname !== "/admin/users/all" && pathname !== "/admin/users/membership" ? "none" : "inline", }}
      >
        <div className="main-user-container">
          <ul className="user-ul-navbar">
            <li style={listItemStyle("all")} onClick={() => clickData("all")}>
              All
            </li>
            <li
              style={listItemStyle("membership")}
              onClick={() => clickData("membership")}
              className="ms-3"
            >
              Membership
            </li>
          </ul>
        </div>
        {/* <div className="user-inputfield">
          <button className="navbar-btn">{<CiSearch />}</button>
          <input type="search" placeholder="Type to search" />
        </div> */}
        <h6 className="user-head">Recently Added</h6>
      </div>
      <div>
        <Outlet />
      </div>
    </>
  );
};

export default User;
