import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./resetpassword.css";
import Navbar from "../../components/Navbar";
import { FaEye, FaRegEyeSlash } from "react-icons/fa";
import apiUrl from "../../config";
import Footer from "../Footer";

const ResetPassword = () => {
  const [isFieldFocused, setIsFieldFocused] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams();
  const [details, setDetails] = useState({
    email: "",
    password: "",
  });
  const [rePassword, setRePassword] = useState("");
  const [error, setError] = useState("");
  const userProfileId = localStorage.getItem("profileId");
  console.log(userProfileId, "profofo");
  const togglePasswordVisibility = () => {
    setIsFieldFocused(!isFieldFocused);
  };

  const handleSubmit = async (e) => {
    if (details.password !== rePassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const res = await axios.post(
        apiUrl + `v1/profile/reset-password/${token}`,
        details,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (res.data = true) {
        navigate("/login");
    }
    } catch (err) {
      console.error(err);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const FormSubmit = (e) => {
    e.preventDefault()
  }

  return (
    <>
      <div className="sticky-top">
        <Navbar />
      </div>
      <div className="container-fluid">
        <div className="container resetpassword-container">
          <div className="resetpassword-main-container">
            <div className="reserpwd-img">
              {/* <img src='./Images/maangalyaam.svg' alt="Logo" /> */}
            </div>
            <div className="resetpasword-names">
              <h1 className="">Reset Password</h1>
              <p className="resetpassword-content">
                Please enter a new password to reset your account.
              </p>
            </div>
            <div className="resetpwd-form-container">
              <form onSubmit={FormSubmit}>
                <div>
                  <label className="resetpassword-form-label">Email</label>
                  <input
                    name="email"
                    value={details.email}
                    onChange={handleChange}
                    placeholder="Enter Email"
                    className="form-control"
                  />
                </div>
                <div className="resetpassword-form">
                  <label className="resetpassword-form-label">
                    New Password
                  </label>
                  <input
                    name="password"
                    value={details.password}
                    onChange={handleChange}
                    placeholder="Enter New Password"
                    className="form-control"
                    type={isFieldFocused ? "text" : "password"}
                  />
                  {isFieldFocused ? (
                    <FaRegEyeSlash
                      onClick={togglePasswordVisibility}
                      className="reset-eye-icon-1"
                    />
                  ) : (
                    <FaEye
                      onClick={togglePasswordVisibility}
                      className="reset-eye-icon-1"
                    />
                  )}
                </div>
                <div className="resetpassword-form">
                  <label className="resetpassword-form-label">
                    Re Enter Password
                  </label>
                  <input
                    name="renewpassword"
                    value={rePassword}
                    onChange={(e) => setRePassword(e.target.value)}
                    placeholder="Re Enter New Password"
                    className="form-control"
                    type={isFieldFocused ? "text" : "password"}
                  />
                </div>
                {error && <p className="error-message">{error}</p>}
                <div className="resetpwd-btn">
                  <button
                    type="submit"
                    className="resetpwd-btn-name"
                    onClick={handleSubmit}
                  >
                    Set Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <Footer />
      </div>
    </>
  );
};

export default ResetPassword;
