import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./navbar.css";
import logo from '.././logo/maangalya.png';

const Navbar = () => {
  const [show, setShow] = useState(false);

  const toggleNavbar = () => {
    setShow(!show);
  };

  return (
    <div className="container-fluid landing-page-navbar">
      <div className="container">
        <div className="navbar-mainhome">
          <nav className="navbar navbar-expand-lg ">
            <a className="navbar-brand" href="#">
              <img src={logo} height="50px" width="200px" alt="Logo" />
            </a>
            <button
              className="navbar-toggler ms-auto"
              type="button"
              onClick={toggleNavbar}
              aria-controls="navbarSupportedContent"
              aria-expanded={show ? "true" : "false"}
              aria-label="Toggle navigation"
            >
              <span>
                <svg
                  width="40px"
                  height="40px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 6H20M4 12H20M4 18H20"
                    stroke="#000000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </button>
            <div
              className={`collapse navbar-collapse home ${show ? 'show' : ''}`}
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink
                    exact
                    to="/"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="" className="nav-link">
                    Plans
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="" className="nav-link">
                    Help
                  </NavLink>
                </li>
              </ul>
              <form className="d-flex justify-content-center" role="search">
                <NavLink to="/">
                  <button className="rounded btn btn-success" type="submit">
                    Login
                  </button>
                </NavLink>
              </form>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
