import React from "react";
import "../css/feature.css";
const Feature = () => {
  const items = [
    {
      image: "../images/search.svg",
      title: "Precision Matchmaking at Your Fingertips",
      contant: "Experience matchmaking like never before. Our platform sophisticated algorithms and detailed criteria to ensure you connect with individuals who share your values, interests, and aspirations. Take the first step towards a meaningful relationship with our precision matchmaking features.",
    },
    {
      image: "../images/verified.svg",
      title: "Verified Profiles for Trusted Connections",
      contant:
        "Your safety is our priority. Every profile on our platform undergoes a rigorous verification process, providing you with the assurance that you are connecting with genuine, like-minded individuals. Build meaningful connections with confidence through our verified profiles.",
    },
    {
      image: "../images/precision.svg",
      title: "Tailored Search, Tailored Results",
      contant:
        "Customize your search for love with our comprehensive filter options. Whether its age,location, or interests, our tailored search feature allows you to fine-tune your preferences, ensuring that every match aligns with your unique criteria.",
    },
    {
      image: "../images/inspire.svg",
      title: "Success Stories That Inspire",
      contant:
        "Join the ranks of those who found love on our platform. Explore real-life success stories and testimonials from couples who embarked on their journey with us. Let their stories inspire and reassure you that your perfect match may be just a click away.",
    },
    {
      image: "../images/privacy.svg",
      title: "Your Privacy, Your Control",
      contant:
        "Take charge of your online journey with our robust privacy controls. Manage who sees your profile and choose the level of information you share. Your privacy is paramount, and we give you the tools to navigate your matrimony search on your terms.",
    },
    {
      image: "../images/messagimg.svg",
      title: "Stay Connected with Seamless Messaging",
      contant:
        "Communication is key, and weve made it easier than ever. Our intuitive messaging platform lets you connect effortlessly with potential matches. From icebreakers to heartfelt conversations, foster connections with ease and grace.",
    },
  ];
  return (

    <div className=" mt-5">
      <div className="container feature-container">
        <div className="feature-header">
          <h6 className="feature-header-head-main ">Feature Highlights</h6>
          <h1 className="feature-header-heading">
            Unveiling the Heart of Our Platform
          </h1>
          <p className="feature-header-header-content">
            Unlock the Door to Love: Register Now for a Journey of Connections
            and Compatibility.
          </p>
        </div>
        <div className="feature-cards-container row">
          {items.map((val) => (
            <div
              className="feature-card  col-lg-4"
              style={{ width: "22rem" }}
            >
              <img
                src={val.image}
                height={70}
                className="feature-card-image"
                alt="..."
              />
              <div className="feature-card-body">
                <h5 className="feature-card-title">{val.title}</h5>
                <p className="feature-card-text">{val.contant}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Feature;
