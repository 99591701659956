import React, { useEffect, useState } from "react";
import "../../css/chatpage.css";
import { useNavigate } from "react-router-dom";
import Chat from "./Chat";
import ChatRequest from "./ChatRequest";
import NavBar from "../DashBoard/NavBar";
import { MyProfileContext } from "../../../../context/ProfileProvider";
import BottomNavbar from "../../../../components/BottomNavbar";
import apiUrl from "../../../../config";
import axios from "axios";
import Footer from "../../../../components/Footer";

export default function Chatpage() {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [requestRoom, setRequestRoom] = useState(false);
  const [screenWidth, setScreenWidth] = useState("");
  const [requestedProfiles, setRequestedProfiles] = useState([]);
  const [interestedProfiles, setInterestedProfiles] = useState([]);
  const [hasOfflineMessages, setHasOfflineMessages] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [messages, setMessages] = useState([]);
  const [totalOfflineMessageLength, setTotalOfflineMessageLength] = useState(0);
  const [activeItem, setActiveItem] = useState("myInterest");
  const userProfileId = localStorage.getItem("profileId");
  const updateTotalLength = (length) => {
    setTotalOfflineMessageLength((prevLength) => prevLength + length);
  };

  const { myProfile } = React.useContext(MyProfileContext);
  const navigate = useNavigate();
  const MyComponent = () => {
    const isMobile = window.innerWidth;
    setScreenWidth(isMobile);
  };

  const formatTime = (timestamp) => {
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    return new Date(timestamp).toLocaleTimeString(undefined, options);
  };

  const RequestedProfiles = () => {
    setRequestedProfiles(myProfile?.additionalDetails?.chatReceiver);
  };

  const InterestedProfiles = () => {
    setInterestedProfiles(myProfile?.additionalDetails?.chatInterest);
  };

  const handleRequestClick = (profile) => {
    setRequestRoom(true);
    setIsChatOpen(false);
    setSelectedProfile(profile);
  };

  const handleChatOpen = (profile) => {
    setIsChatOpen(true);
    setSelectedProfile(profile);
  };

  const getMessage = async (id) => {
    const roomId = parseInt(userProfileId) + parseInt(id);

    try {
      const res = await axios.get(apiUrl + `v3/chat/myMessage/${roomId}`);
      const lastMessage = res.data[res.data.length - 1];
      setMessages(lastMessage);
    } catch (error) {
      console.log("Error fetching messages:", error);
    }
  };

  const toggleComponents = () => {
    setToggle(true);
    setActiveItem("request");
    setIsChatOpen(false);
  };

  const toggleRequest = () => {
    setToggle(false);
    setActiveItem("myInterest");
    setIsChatOpen(false);
    setRequestRoom(false);
  };

  useEffect(() => {
    MyComponent();
    RequestedProfiles();
    InterestedProfiles();
  }, []);

  useEffect(() => {
    MyComponent();
    setRequestedProfiles(myProfile?.additionalDetails?.chatReceiver);
    setInterestedProfiles(myProfile?.additionalDetails?.chatInterest);
  }, [myProfile]);

  useEffect(() => {
    if (interestedProfiles && interestedProfiles.length > 0) {
      interestedProfiles.forEach((val) => getMessage(val.id));
    }
  }, [interestedProfiles]);

  const handleChatNavigation = (profileData) => {
    navigate("/chat", { state: profileData });
  };

  const handleChatRequest = (profileData) => {
    navigate("/chatrequest", { state: profileData });
  };
  return (
    <div className="">
      <div className="sticky-top">
        <NavBar />
      </div>
      {screenWidth >= 769 ? (
        <div className="container-fluid desktop-component">
          <div className="container">
            <div className="chatpage-main-container">
              <div className="chat">
                <div className="chat-ul">
                  <ul>
                    <li className={activeItem === "myInterest" ? "active" : ""}>
                      <b onClick={toggleRequest}>My Interest</b>
                    </li>
                    <li className={activeItem === "request" ? "active" : ""}>
                      <b onClick={toggleComponents}>Request</b>
                    </li>
                  </ul>
                  {toggle ? (
                    <div className="chat-container">
                      {requestedProfiles.length > 0 ? requestedProfiles?.map((val) => {
                        return (
                          <>
                            <div
                              className="chat-contact-one"
                              onClick={() => handleRequestClick(val)}
                            >
                              <div className="dp-pic-one">
                                <img
                                  src={val.image[0]}
                                  style={{
                                    width: "60px",
                                    height: "60px",
                                    borderRadius: "50%",
                                  }}
                                />
                              </div>
                              <div className="chat-details">
                                <div className="chat-contant">
                                  <h6 className="chat-garge-name">
                                    {val.firstName} {val.secondName}
                                  </h6>
                                  <p className="para-chat">
                                    Accept the Chat.. checking
                                  </p>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      }) : 
                        <h6 className="chatrequest-no-profiles">No Requested profile</h6>
                       

                      }
                    </div>
                  ) : (
                    <div>
                      <h6 className="recent-chats">Recent Chat</h6>
                      <div className="chat-container ">
                        <div >
                          {interestedProfiles?.map((val) => {
                            // getMessage(val.id)
                            return (
                              <div className="chat-contact-one d-flex flex-wrap">
                                <div className="d-flex" style={{cursor:"pointer"}}  onClick={() => handleChatOpen(val)}>
                                  <div
                                    className="dp-pic-one "
                                    // onClick={() => handleChatOpen(val)}
                                  >
                                    <img
                                      src={val.image[0]}
                                      style={{
                                        width: "60px",
                                        height: "60px",
                                        borderRadius: "50%",
                                      }}
                                    />
                                  </div>
                                  <div className="chat-details">
                                    <div className="chat-contant">
                                      <h6 className="chat-page-name">
                                        {val.firstName}
                                        {val.secondName}
                                      </h6>
                                      <p className="para-chat">
                                        {messages ? (
                                          <>
                                            <p>{messages.message}</p>
                                            <p>{formatTime(messages.createdAt)}</p>
                                          </>
                                        ) : (
                                          "No messages yet"
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {isChatOpen && selectedProfile && (
                <Chat
                  key={selectedProfile.id}
                  data={selectedProfile}
                  messageLength={selectedProfile.messageLength}
                  hasOfflineMessages={hasOfflineMessages}
                  updateTotalLength={updateTotalLength}
                />
              )}
              {requestRoom && (
                <ChatRequest key={selectedProfile.id} data={selectedProfile} />
              )}
            </div>
          </div>
        </div>
      ) : null}

      {screenWidth <= 768 ? (
        <div className="container-fluid mobile-component ">
          <div className="container">
            <div className="chatpage-main-container ">
              <div className="chat">
                <div className="chat-ul">
                  <ul>
                    <li className={activeItem === "myInterest" ? "active" : ""}>
                      <b onClick={toggleRequest}>My Interest</b>
                    </li>
                    <li className={activeItem === "request" ? "active" : ""}>
                      <b onClick={toggleComponents}>Request</b>
                    </li>
                  </ul>
                  {toggle ? (
                    <div
                      className="chat-container"
                      onClick={handleRequestClick}
                    >
                      {requestedProfiles?.map((val) => {
                        return (
                          <>
                            <div className="chat-contact-one">
                              <div className="dp-pic-one">
                                <img src={val.image[0]} />
                              </div>
                              <div
                                className="chat-details"
                                onClick={() => handleChatRequest(val)}
                              >
                                <div className="chat-contant">
                                  <h6 className="chat-garge-name">
                                    {val.firstName}
                                  </h6>
                                  <p className="para-chat">
                                    Accept the Chat.. checking
                                  </p>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  ) : (
                    <div>
                      <h6 className="recent-chats">Recent Chat</h6>
                      <div className="chat-container">
                        <div
                          className="chat-contact-one"
                          onClick={handleChatOpen}
                        >
                          {interestedProfiles?.map((val) => (
                            <div className="chat-contant-main">
                              <div className="dp-pic-one">
                                <img
                                  src={val.image[0]}
                                  style={{
                                    width: "60px",
                                    height: "60px",
                                    borderRadius: "50%",
                                  }}
                                  alt={`Profile of ${val.firstName}`}
                                />
                              </div>
                              <div
                                className="chat-details"
                                onClick={() => handleChatNavigation(val)}
                              >
                                <div className="chat-contant">
                                  <h6 className="chat-page-name">
                                    {val.firstName}
                                  </h6>
                                  <p className="para-chat">
                                    {messages ? (
                                      <>
                                        <p>{messages.message}</p>

                                        <p>{formatTime(messages.createdAt)}</p>
                                      </>
                                    ) : (
                                      "No messages yet"
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className=" sticky-bottom">
        <BottomNavbar />
      </div>
      <div className="mt-5">
        <Footer />
      </div>
    </div>
  );
}
