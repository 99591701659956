import axios from "axios";
import React, { useState, useEffect } from "react";
import apiUrl from "../../../../../config";
import { MyProfileContext } from "../../../../../context/ProfileProvider";
import { Link } from "react-router-dom";
import ProfileProgress from "./ProfileProgress";
import DetailsData from "../../../../../data/State.json";
import Religion from "../../../../../data/Religion.json";
import SuccessPopUp from "../../../../../popups/SuccessPopUp";
import ErrorPopup from "../../../../../popups/ErrorPopup";


export const BasicDetails = () => {
  const userProfileId = localStorage.getItem("profileId");
  const { myProfile, userProfile } = React.useContext(MyProfileContext);
  const [clickedEdit, setClickedEdit] = useState(false);
  const [errors, setErrors] = useState("");
  const [mobileError, setMobileError] = useState("")
  const [selectedState, setSelectedState] = useState("");
  const [emailError, setEmailError] = useState("");
  const [showError, setShowError] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [success, setSuccess] = useState("")


  const [editedProfile, setEditedProfile] = useState({
    profile: myProfile.profile,
    firstName: myProfile.firstName,
    secondName: myProfile.secondName,
    gender: myProfile.gender,
    mobile: myProfile.mobile,
    email: myProfile.email,
    country: myProfile.country,
    city: myProfile.city,
    state: myProfile.state,
    religion: myProfile.religion,
    caste: myProfile.caste,
    subCaste: myProfile.subCaste,
    aboutMe: myProfile.aboutMe,
  });


  function getOption(val) {
    if (editedProfile.caste) {
      const subcaste = val?.filter((val) => val.name === editedProfile.caste)
      return subcaste[0]?.subcaste
    }
  }

  const religionOptions = Object.keys(Religion);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedProfile((prevProfile) => ({ ...prevProfile, [name]: value }));
    if (name === "firstName" || name === "secondName") {
      const lettersOnly = value.replace(/[^A-Za-z]/g, "");
      setEditedProfile((prevProfile) => ({
        ...prevProfile,
        [name]: lettersOnly,
      }));
    } else if (name === "email") {
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      if (!isValidEmail) {
        setEmailError("Please enter a valid email address");
      } else {
        setEmailError("");
      }
    } else if (name === "mobile") {
      const isValidMobile = editedProfile.mobile && editedProfile.mobile.length === 10;
      if (!isValidMobile) {
        setMobileError("Please enter a valid Mobile");
      } else {
        setMobileError("");
      }
    } else if (name === "state") {
      setSelectedState(value);
      setEditedProfile((prevProfile) => ({
        ...prevProfile,
        [name]: value,
        city: "",
      }));
    }
  };

  const Updatedetali = async () => {
    try {
      const res = await axios.patch(apiUrl + `v1/profile/updateProfile/${userProfileId}`, editedProfile);
      setSuccess("Profile Updated successfully")
      handleShowSuccess()
      setEmailError()
      setMobileError()
      if (res.status == 200) {
        userProfile();
      }
    } catch (err) {
      console.log(err);
      setErrors(err.response.data.message);
      handleShowError()
    }
  };

  const handleShowError = () => {
    setShowError(true);
  };

  const handleCloseError = () => {
    setShowError(false);
  };

  const handleShowSuccess = () => {
    setshowSuccess(true);
  };

  const handleCloseSuucess = () => {
    setshowSuccess(false);
  };

  const commonFunction = () => {
    setClickedEdit();
    Updatedetali();
  };

  useEffect(() => {

    userProfile();
  }, [editedProfile]);

  return (
    <div className="basic-card">
      <div>
        <ProfileProgress />
      </div>
      <div className="basic-details-edit">
        <div className="basic-details-edit-button">
          <h4 className="basic-details-heading">
            <b className="sections-Headings sections-Headings">Basic Details</b>
          </h4>
          <div className="edit-name-button">
            {clickedEdit ? (
              <p className="edit-name" onClick={() => commonFunction()}>
                Update
              </p>
            ) : (
              <span
                className="edit-name"
                onClick={() => setClickedEdit((preState) => !preState)}
              >
                Edit
                <img className="edit-button" src="/images/edit.png" alt="" />
              </span>
            )}
          </div>
        </div>
        {clickedEdit && (
          <>
            <div className="input-details">
              <div className="first-label">
                <div className="label-input">
                  <div className="label-edit">
                    <div className="label-input-fields">
                      <label htmlFor="" className="label-content">
                        Profile
                      </label>
                      <select
                        className="input-edit-data "
                        type="text"
                        name="profile"
                        placeholder="profile"
                        value={editedProfile.profile}
                        onChange={handleInputChange}
                      >
                        <option value="">Select</option>
                        {DetailsData.profile.map((profile) => (
                          <option key={profile} value={profile}>
                            {profile}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="label-input-fields">
                      <label htmlFor="" className="label-content">
                        First Name
                      </label>
                      <input
                        className="input-edit-data"
                        type="text"
                        placeholder="First Name"
                        name="firstName"
                        value={editedProfile.firstName}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="label-input-fields">
                      <label htmlFor="" className="label-content">
                        Phone Number
                      </label>
                      <input
                        className="input-edit-data"
                        type="text"
                        placeholder="Phone Number"
                        value={editedProfile.mobile}
                      // onInput={(e) => {
                      //   const numericValue = e.target.value.replace(/\D/g, "").slice(0, 10);
                      //   handleInputChange({
                      //     target: { name: "mobile", value: numericValue },
                      //   });
                      // }}
                      // onKeyDown={(e) => {
                      //   if (
                      //     !(e.key === "Backspace" || e.key === "Delete") &&
                      //     !/[\d]/.test(e.key)
                      //   ) {
                      //     e.preventDefault();
                      //   }
                      // }}
                      />
                    </div>
                    {mobileError && <p style={{ color: "red" }}>{mobileError}</p>}
                  </div>
                </div>
                <div className="label-input-change">
                  <div className="label-edit">
                    <div className="label-input-fields">
                      <label htmlFor="" className="label-content">
                        Gender
                      </label>
                      <select
                        className="input-edit-data"
                        type="text"
                        name="gender"
                        placeholder="Gender"
                        value={editedProfile.gender}
                        onChange={handleInputChange}
                      >
                        <option value="" className="select-option">
                          Select
                        </option>
                        <option value="male" className="select-option">
                          {" "}
                          Male
                        </option>
                        <option value="female" className="select-option">
                          Female
                        </option>
                      </select>
                    </div>
                    <div className="label-input-fields">
                      <label htmlFor="" className="label-content">
                        Second Name
                      </label>
                      <input
                        className="input-edit-data"
                        type="text"
                        placeholder="second Name"
                        value={editedProfile.secondName}
                        onChange={handleInputChange}
                        name="secondName"
                      />
                    </div>
                    <div className="label-input-fields">
                      <label htmlFor="" className="label-content">
                        Email
                      </label>
                      <input
                        className="input-edit-data"
                        type="email"
                        placeholder="email"
                        value={editedProfile.email}
                        onChange={handleInputChange}
                      />
                    </div>
                    {emailError && <p style={{ color: "red" }}>{emailError}</p>}
                  </div>
                </div>
              </div>
              <div className="second-label">
                <div className="label-input">
                  <div className="label-edit">
                    <div className="label-input-fields">
                      <label htmlFor="" className="label-content">
                        Country
                      </label>
                      <select
                        className="input-edit-data"
                        type="text"
                        placeholder="country"
                        value={editedProfile.country}
                        onChange={handleInputChange}
                        name="country"
                      >
                        <option value="">Select</option>
                        {DetailsData.country.map((country) => (
                          <option key={country} value={country}>
                            {country}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="label-input-fields">
                      <label htmlFor="" className="label-content">
                        State
                      </label>
                      <select
                        className="input-edit-data"
                        placeholder="state"
                        name="state"
                        value={editedProfile.state}
                        onChange={handleInputChange}
                      >
                        <option value="">Select State</option>
                        {DetailsData.states.map((state) => (
                          <option key={state.name} value={state.name}>
                            {state.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="label-input-fields">
                      <label htmlFor="" className="label-content">
                        City
                      </label>
                      <select
                        className="input-edit-data"
                        placeholder="city"
                        value={editedProfile.city}
                        onChange={handleInputChange}
                        name="city"
                      >
                        <option value="">Select City</option>
                        {selectedState &&
                          DetailsData.states
                            .find((s) => s.name === selectedState)
                            ?.cities.map((city) => (
                              <option key={city} value={city}>
                                {city}
                              </option>
                            ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="label-input-change">
                  <div className="label-edit">
                    <div className="label-input-fields">
                      <label htmlFor="" className="label-content">
                        Religion
                      </label>
                      <select
                        defaultValue={""}
                        className="input-edit-data"
                        aria-label="Default select example"
                        onChange={handleInputChange}
                        value={editedProfile.religion}
                        name="religion"
                      >
                        <option value="">Select Religion</option>
                        {religionOptions.map((religion) => (
                          <option key={religion} value={religion}>
                            {religion}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="label-input-fields">
                      <label htmlFor="" className="label-content">
                        Caste
                      </label>
                      <select
                        defaultValue={""}
                        className="input-edit-data"
                        aria-label="Default select example"
                        onChange={handleInputChange}
                        value={editedProfile.caste}
                        name="caste"
                      >
                        <option value="">Select Caste</option>
                        {Religion[`${editedProfile.religion}`]?.map((caste) => (
                          <option key={caste.name} value={caste.name}>
                            {caste.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="label-input-fields">
                      <label htmlFor="" className="label-content">
                        Sub-Caste
                      </label>
                      <select
                        defaultValue={""}
                        className="input-edit-data"
                        aria-label="Default select example"
                        onChange={handleInputChange}
                        value={editedProfile.subCaste}
                        name="subCaste"
                      >
                        <option value="">Select SubCaste</option>
                        {
                          getOption(Religion[editedProfile.religion])?.map((val) => {
                            return (
                              <option key={val} value={val}>
                                {val}
                              </option>
                            )
                          })
                        }
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {!clickedEdit && (
          <>
            <div className="input-details">
              <div className="first-label">
                <div className="label-input">
                  <div className="label">
                    <div className="label-input-fields">
                      {" "}
                      <label htmlFor="" className="label-content">
                        Profile
                      </label>{" "}
                      <p className="input-data-basic-details">
                        {myProfile?.profile}
                      </p>
                    </div>
                    <div className="label-input-fields">
                      <label htmlFor="" className="label-content">
                        First Name
                      </label>
                      <p className="input-data-basic-details">
                        {myProfile?.firstName}
                      </p>
                    </div>
                    <div className="label-input-fields">
                      <label htmlFor="" className="label-content">
                        Phone Number
                      </label>
                      <p className="input-data-basic-details">
                        {myProfile?.mobile}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="label-input-change-data">
                  <div className="label">
                    <div className="label-input-fields">
                      <label htmlFor="" className="label-content">
                        Gender
                      </label>
                      <p className="input-data-basic-details">
                        {myProfile?.gender}
                      </p>
                    </div>
                    <div className="label-input-fields">
                      <label htmlFor="" className="label-content">
                        Second Name
                      </label>{" "}
                      <p className="input-data-basic-details">
                        {myProfile?.secondName}
                      </p>
                    </div>
                    <div className="label-input-fields">
                      {" "}
                      <label htmlFor="" className="label-content">
                        Email
                      </label>{" "}
                      <p className="input-data-basic-details">
                        {myProfile?.email}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="second-label">
                <div className="label-input">
                  <div className="label">
                    <div className="label-input-fields">
                      <label htmlFor="" className="label-content">
                        Country
                      </label>
                      <p className="input-data-basic-details">
                        {myProfile?.country}
                      </p>
                    </div>
                    <div className="label-input-fields">
                      <label htmlFor="" className="label-content">
                        State
                      </label>{" "}
                      <p className="input-data-basic-details">
                        {myProfile?.state}
                      </p>
                    </div>
                    <div className="label-input-fields">
                      <label htmlFor="" className="label-content">
                        City
                      </label>
                      <p className="input-data-basic-details">
                        {myProfile?.city}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="label-input-change-data">
                  <div className="label">
                    <div className="label-input-fields">
                      {" "}
                      <label htmlFor="" className="label-content">
                        Caste
                      </label>
                      <p className="input-data-basic-details">
                        {myProfile?.caste}
                      </p>
                    </div>
                    <div className="label-input-fields">
                      <label htmlFor="" className="label-content">
                        Sub-Caste
                      </label>{" "}
                      <p className="input-data-basic-details">
                        {myProfile?.subCaste}
                      </p>
                    </div>
                    <div className="label-input-fields">
                      <label htmlFor="" className="label-content">
                        Religion
                      </label>
                      <p className="input-data-basic-details">
                        {myProfile?.religion}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {showSuccess && <SuccessPopUp message={success} onClose={handleCloseSuucess} />}
      {showError && <ErrorPopup message={errors} onClose={handleCloseError} />}
    </div>
  );
};
