import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../css/joinwithus.css";
import DetailsData from "../../../data/State.json";
const JoinWithus = () => {
  const [mobileError, setMobileError] = useState("");
  const [startDetails, setStartDetails] = useState({
    profile: "",
    gender: "",
    firstName: "",
    secondName: "",
    mobile: ""
  })

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStartDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
    if (name === "firstName" || name === "secondName") {
      const lettersOnly = value.replace(/[^A-Za-z]/g, '');
      setStartDetails((prevDetails) => ({ ...prevDetails, [name]: lettersOnly }));
    }
  }

  const onFormSubmit = (e) => {
    let isValidMobile = true;
    isValidMobile = startDetails.mobile.length === 10;
    setMobileError(isValidMobile ? "" : "Invalid mobile");
    if (isValidMobile) {
      setStartDetails((prevDetails) => ({ ...prevDetails, mobile: startDetails.mobile }));
    }
    e.preventDefault()
  }
  return (
    <>
      <div className="container">
        <div className=" joinwithus-container mt-4">
          <div className="joinwithus-head-container">
            <h6 className="joinwithus-header-name">Join Us Today</h6>
            <h1 className="joinwithus-header">
              Your Journey to Love Starts Here
            </h1>
            <p className="joinwithus-header-content">
              Unlock the Door to Love: Register Now for a Journey of Connections
              and Compatibility.
            </p>
          </div>
          <div className="joinwithus-form-container">
            <form className="joinwithus-form" onSubmit={onFormSubmit}>
              <div className="joinwithus-input-fields">
                <div className="joinwithus-input-fields-1 ">
                  <label className="joinwithus-form-label">Profile</label>
                  <select
                    className="form-control select"
                    name="profile"
                    value={startDetails.profile}
                    onChange={handleChange}
                  >
                    <option>Select</option>
                    {DetailsData.profile.map((profile) => (
                        <option key={profile} value={profile}>
                          {profile}
                        </option>
                      ))}
                  </select>
                  {/* <input className="joinwithus-form-input" type="text" name="profile" value={startDetails.profile} onChange={handleChange} /> */}
                </div>
                <div className="joinwithus-input-fields-1 ">
                  <label className="joinwithus-form-label">Gender</label>
                  <select
                    className="form-control select"
                    name="gender"
                    value={startDetails.gender}
                    onChange={handleChange}
                  >
                    <option>Select</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>
              </div>
              <div className="joinwithus-input-fields ">
                <div className="joinwithus-input-fields-1 ">
                  <label className="joinwithus-form-label">Surname</label>
                  <input className="joinwithus-form-input  form-control"  placeholder="Enter Surname"type="text" name="firstName" value={startDetails.firstName} onChange={handleChange} />
                </div>
                <div className="joinwithus-input-fields-1 ">
                  <label className="joinwithus-form-label"> Name</label>
                  <input className="joinwithus-form-input form-control" placeholder="Enter Name"type="text" name="secondName" value={startDetails.secondName} onChange={handleChange} />
                </div>
              </div>
              <div className="joinwithus-input-fields ">
                <div className="joinwithus-input-fields-1 ">
                  <label className="joinwithus-form-label">Mobile Number</label>
                  <input
                    className="form-control"
                    value={startDetails.mobile}
                    placeholder="Enter your Mobile Number"
                    name="mobile"
                    onInput={(e) => {
                      const numericValue = e.target.value.replace(/\D/g, "").slice(0, 10);
                      handleChange({
                        target: { name: "mobile", value: numericValue },
                      });
                    }}
                    onKeyDown={(e) => {
                      if (
                        !(e.key === "Backspace" || e.key === "Delete") &&
                        !/[\d]/.test(e.key)
                      ) {
                        e.preventDefault();
                      }
                    }}
                    type="text"
                  />

                  {mobileError && <p className="error-message" style={{ color: 'red' }}>{mobileError}</p>}
                </div>
              </div>
              <div className="joinwithus-start-buttons">
                <Link className="joinwithus-button-getstarted" state={startDetails}><button className="joinwithus-button">Get Started                </button>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinWithus;