import React, { useState } from "react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import "../css/reviewsslider.css";
import SwiperNavButton from "./SwiperNavButton";

const ReviewsSlider = () => {
  const reviewss = [
    {
      id: 1,
      image: "../images/reviews-img-1.svg",
      title:
        "We are grateful to this platform for bringing us together. Despite living in different cities, we connected instantly and knew we were meant to be. Today, we are happily married, all thanks to this website!",
      names: "Raghu & Shwetha",
      expanded: false,
    },
    {
      id: 2,
      image: "../images/reviews-img-2.svg",
      title:
        "We found each other through matrimonial website, and it turned out to be the best decision of our lives. Thank you for helping us find our perfect match!",
      names: "John and Sarah",
      expanded: false,
    },
    {
      id: 3,
      image: "../images/reviews-img-1.svg",
      title:
      "After years of searching for the right partner, I finally met my soulmate on this maangalyaam website. Highly recommend this platform to anyone seeking true love!" ,
      names: "David and Jennifer",
      expanded: false,
    },
    {
      id: 4,
      image: "../images/reviews-img-2.svg",
      title:
      "We were both skeptical about maangalyaam websites at first, but this platform exceeded our expectations. We met, clicked instantly, and got married within a year. Its like a fairy tale come true!",
      names:"Mark and Lisa",
      expanded: false,
    },
  
  ];

  const [reviews, setReviews] = useState(reviewss);

  const toggleContent = (id) => {
    setReviews((prevReviews) =>
      prevReviews.map((review) =>
        review.id === id ? { ...review, expanded: !review.expanded } : review
      )
    );
  };

  return (
    <>
      <div className=" reviews-container">
        <div className="container ">
          <div className="d-flex justify-content-center reviewss">
            <div className="reviews-slider-container ">
              <div className="reviewss-slider">
                <p className="reviewssp ">394+ Happy Maangalaaym Users</p>
                <h1 className="reviewssh1">Don’t just take our words</h1>
              </div>
              <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                spaceBetween={50}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 1,
                  },
                  1000: {
                    slidesPerView: 1,
                  },
                  1024: {
                    slidesPerView: 2,
                  },
                }}
              >
                {reviews.map((review) => (
                  <SwiperSlide key={review.id}>
                    <div className="reviews-slider-card">
                      <div className="reviews-slider-card-image">
                        <img
                          src={review.image}
                          className="reviews-slider-image"
                          height={150}
                          alt=""
                        />
                      </div>
                      <div className="reviews-slider-card-content">
                        <h6 className="reviews-title">
                          {review.expanded
                            ? review.title
                            : review.title.slice(0, 110)}.....
                          {review.title.length > 110 && (
                            <span
                              className="see-more"
                              onClick={() => toggleContent(review.id)}
                            >

                              {review.expanded ?<span className="review-seeless">See Less </span> : <span  className="review-seeless">See More </span>}
                            </span>
                          )}
                        </h6>
                        <h6 className="reviews-couple-names">
                          {review.names}{" "}
                        </h6>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
                <div className="slider-button">
                  <SwiperNavButton />
                </div>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewsSlider;
