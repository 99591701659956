import React, { useState } from "react";
import axios from "axios";
import "../css/contactus.css";
import Navbar from "../components/DashBoard/NavBar";
import apiUrl from "../../../config";
import NavBar from "../../../../src/components/Navbar";
import Footer from "../../../components/Footer";

const ContactUs = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [istechnical, setIsTechnical] = useState(true);
  const [issupport, setIsSupport] = useState(true);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const toggleVisibility1 = () => {
    setIsTechnical(!istechnical);
  };
  const toggleVisibility2 = () => {
    setIsSupport(!issupport);
  };
  const isLoggedIn = localStorage.getItem("profileId");

  return (
    <>
      {isLoggedIn ? (
        <div className="sticky-top">
          <Navbar />
        </div>
      ) : (
        <div className="sticky-top">
          <NavBar />
        </div>
      )}
      <div className="container-fluid">
        <div className="container">
          <div className="contactus-container">
            <div className="contactus-heading">
              <h1 className="Feedback-heading">
                Have a Question?{" "}
                <span className="contactus-heading-sub">
                  We’re Here to Help!
                </span>
              </h1>

              <p className="contactus-heading-para">
                Helping You Every Step of the Way
              </p>
            </div>
            <div className="contactus-toggle-container">
              <div className="contactus-toggle-b">
                <div className="mb-3">
                <h3 className="contact-number">Contact Numbers:</h3>
                <span className="number-links">
                  <a href="tel:+9000382913">9000382913</a>
                </span>
                <span className="number-links">
                  <a href="tel:+9000382914">9000382914</a>
                </span>
                </div>
                <div className="contactus-toggle">
                  <h4>Customer Support</h4>
                  <button
                    onClick={toggleVisibility}
                    className="contactus-toggle-btn"
                  >
                    {isVisible ? "-" : "+"}
                  </button>
                </div>
                {isVisible && (
                  <div className="contactus-toggle-content">
                    <p>
                      For general inquiries or assistance, please email us at{" "}
                      <a href="mailto:customersupport@maangalyaam.com">
                      customersupport@maangalyaam.com
                      </a>
                      
                    </p>
                  </div>
                )}
              </div>
              <div className="contactus-toggle-b">
                <div className="contactus-toggle">
                  <h4>Technical Support</h4>
                  <button
                    onClick={toggleVisibility1}
                    className="contactus-toggle-btn"
                  >
                    {istechnical ? "-" : "+"}
                  </button>
                </div>
                {istechnical && (
                  <div className="contactus-toggle-content">
                    <p>
                      If you're experiencing technical issues, contact our
                      technical support team at{" "}
                      <a href="mailto:technicalsupport@maangalyaam.com">
                      technicalsupport@maangalyaam.com 
                      </a>
                      .
                    </p>
                  </div>
                )}
              </div>
              <div className="contactus-toggle-b">
                <div className="contactus-toggle">
                  <h4>Business Support</h4>
                  <button
                    onClick={toggleVisibility2}
                    className="contactus-toggle-btn"
                  >
                    {issupport ? "-" : "+"}
                  </button>
                </div>
                {issupport && (
                  <div className="contactus-toggle-content">
                    <p>
                      For partnership opportunities or business-related
                      inquiries, reach out to us at{" "}
                      <a href="mailto:  businesssupport@maangalyaam.com">
                      businesssupport@maangalyaam.com
                      </a>
                      .
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className="contact-Business">
              <h4 className="contact-Business-heading">Business Hours</h4>
              <p className="contact-Business-content">
                Our support team is available to assist you during the following
                hours:
              </p>
              <ul className="contact-Business-ul">
                <li>Monday to Friday: 9:00 AM to 6:00 PM IST</li>
                <li>Saturday and Sunday: Closed</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <Footer />
      </div>
    </>
  );
};

export default ContactUs;
