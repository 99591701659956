import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../../../pages/Home/css/profile.css";
import { MyProfileContext } from "../../../../context/ProfileProvider";
import BottomNavbar from "../../../../components/BottomNavbar";

export const Profile = () => {
  let navigate = useNavigate();
  const { myProfile, userProfile } = React.useContext(MyProfileContext);

  useEffect(() => {
    userProfile();
  }, []);

  return (
    <>
      <div className="profile-main-container ">
        <div className="profile-component-1">
          <div className="main-profile ">
            <div className="">
            <div>
              {myProfile && myProfile.image && myProfile.image[0] && (
                <img
                  className="image-profile"
                  src={myProfile.image[0]}
                  style={{
                    width: "120px",
                    height: "120px",
                    border: `3.5px solid #ffb000`,
                  }}
                  alt="Profile Image"
                />
              )}
            </div>
            <div className="profile-page-person-names">
              <h5 className="section-header">
                {myProfile.firstName} {myProfile.secondName}
              </h5>
              <p className="profile-page-number">{myProfile.mobile} </p>
              {/* <p className="profile-page-number">{myProfile.email} </p> */}
              <a onClick={() => navigate("/edit/basicdetails", { state: myProfile })} className="anchor-pro" style={{ cursor: "pointer" }}>
                Edit <img src="/images/edit.png" />
              </a>
            </div>
            <div className="mid-profile"></div>
            <div className="profile-btn-pro-container">
              <button
                className="btn-pro"
                onClick={() => navigate("/verification")}
              >
                <div className="d-flex">
                  <img className="pro-ig" src="/images/Verify.png" />{" "}
                  <p className="profile-profiles">Profile Verification</p>
                </div>
                <div>
                  <img className="pro-imone" src="/images/Arrow.png" />
                </div>
              </button>
              <button
                onClick={() => navigate("/settings/mail")}
                className="btn-pro"
              >
                <div className="d-flex ">
                  {" "}
                  <img className="pro-ig" src="/images/Verify.png" />
                  <p className="profile-profiles"> Settings </p>
                </div>

                <div>
                  <img className="pro-imone" src="/images/Arrow.png" />
                </div>
              </button>
              <button onClick={() => navigate("/contactus")} className="btn-pro">
                <div className="d-flex">
                  <img className="pro-ig" src="/images/Verify.png" />
                  <p className="profile-profiles">Help </p>{" "}
                </div>
                <div>
                  <img className="pro-imone" src="/images/Arrow.png" />
                </div>
              </button>
            </div>

            </div>
          
          </div>
        </div>
        <div className="sticky-bottom">
          <BottomNavbar />
        </div>
      </div>
    </>
  );
};