import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import "./resetpassword.css";
import Navbar from "../../components/Navbar";
import apiUrl from "../../config";
import ErrorPopup from "../../popups/ErrorPopup";
import SuccessPopUp from "../../popups/SuccessPopUp";
import Footer from "../Footer";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState("");
    const [showError, setShowError] = useState(false);
    const [emailError, setEmailError] = useState("");

    const [showSuccess, setshowSuccess] = useState(false);
    const [success, setSuccess] = useState("")


    const handleShowError = () => {
        setShowError(true);
    };

    const handleCloseError = () => {
        setShowError(false);
    };

    const handleShowSuccess = () => {
        setshowSuccess(true);
    };

    const handleCloseSuucess = () => {
        setshowSuccess(false);
    };

    const handleForgotPassword = async () => {
        try {
            const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
            if (email && !isValidEmail) {
                setEmailError("Invalid Email");
                return;
            }
            if (isValidEmail) {
                setEmailError("")
                setErrors("")
                const res = await axios.post(apiUrl + "v1/profile/forgotpassword", { email });
                setSuccess("The reset password link is sent to your Email");
                handleShowSuccess();
                setEmail("")
            }
        } catch (err) {
            console.log(err);
            setErrors(err.response.data.message);
            handleShowError()
        }
    };

    const FormSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <>
            <div className="sticky-top">
                <Navbar />
            </div>
            <div className="container-fluid">
                <div className="container forgotpassword-container">
                    <div className="forgotpassword-main-container">
                        <div className="forgotpwd-img">
                            {/* <img src="../Images/maangalya.png" alt="Logo" /> */}
                        </div>
                        <div className="forgot-passworrd-content-main">
                            <h1 className="forgotpassword-heading">Forgot Password </h1>
                            <p className="forgotpassword-content">
                                Enter your email address below, and we'll send you a link to reset your password.
                            </p>
                        </div>
                        <div className="forgotpwd-form-container">
                            <form onSubmit={FormSubmit}>
                                <div className="form">
                                    <label className="forgotpassword-form-label">
                                        Email
                                    </label>
                                    <input className="form-control" type="email" placeholder="Enter your Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                {emailError && <p className="error-message">{emailError}</p>}
                                <div className="resetpwd-btn">
                                    <button type="submit" className="resetpwd-btn-name" onClick={handleForgotPassword}>
                                        Send
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {showSuccess && <SuccessPopUp message={success} onClose={handleCloseSuucess} />}
                {showError && <ErrorPopup message={errors} onClose={handleCloseError} />}
            </div>
            <div className="mt-5">
                <Footer />
            </div>
        </>
    );
};

export default ForgotPassword;
