import React, { useEffect, useState, useContext } from "react";
import "../../../../pages/Home/css/homecenter.css";
import { IoIosArrowForward } from "react-icons/io";
import Popup from "reactjs-popup";
import ProfilePopup from "../ProfilePopup";
import { MyProfileContext } from "../../../../context/ProfileProvider";
import { Link, useNavigate } from "react-router-dom";
import { ArrowRight, CheckCircle } from "react-bootstrap-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
const HomeCenter = ({ filteredProfiles }) => {

  const { myProfile, allProfiles, getAllProfiles } = useContext(MyProfileContext);
  const navigate = useNavigate();
  const [nearedProfiles, setNearedProfiles] = useState([]);
  const [premiumFiltered, setPremiumFiltered] = useState([]);
  const [nonPremiumFiltered, setNonPremiumFiltered] = useState([]);
  const [profileCompletion, setProfileCompletion] = useState(0);

  const calculateProfileCompletion = () => {
    const selectedFields = [
      "profile", "gender", "mobile", "email", "dateOfBirth", "firstName", "secondName", "motherTounge", "country", "state", "city", "religion",
      "caste", "subCaste", "maritalStatus", "familyType", "familyStatus", "disabilities", "higherEducation", "courseName", "collegeName", "occupation", "annualIncome",
      "verificationProof", "image"];
    const filledFields = selectedFields.filter((field) => myProfile[field] !== null && myProfile[field] !== undefined && myProfile[field] !== "").length;
    const totalFields = selectedFields.length;
    const completionPercentage = (filledFields / totalFields) * 100;
    setProfileCompletion(completionPercentage.toFixed(2));
  };

  const NearProfiles = () => {
    const filterProfile = allProfiles.filter((val) => val.state === myProfile.state);
    setNearedProfiles(filterProfile);
  };
  const AllFilteredProfiles = () => {
    const premiumProfile = filteredProfiles.filter((val) => val.payment !== false);
    setPremiumFiltered(premiumProfile);
  };

  const AllNonPremiumFiltered = () => {
    const nonPremiumProfile = filteredProfiles.filter((val) => val.payment === false);
    setNonPremiumFiltered(nonPremiumProfile);
  };

  const useForceUpdate = () => {
    const [, forceUpdate] = useState(0);
    return () => forceUpdate((prev) => prev + 1);
  };

  const forceUpdate = useForceUpdate();

  useEffect(() => {
    NearProfiles();
    AllFilteredProfiles();
    AllNonPremiumFiltered();
    getAllProfiles()
    forceUpdate();
    calculateProfileCompletion();
  }, [filteredProfiles, myProfile]);

  useEffect(() => { }, [premiumFiltered, allProfiles]);

  const calculateAge = (dateOfBirth) => {
    const birthDate = new Date(dateOfBirth);
    const currentDate = new Date();
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return {
      age,
    };
  };

  const ProfileCard = ({ data }) => {
    const { age } = calculateAge(data.dateOfBirth);
    const verified = data.isVerified;
    return (
      <div className="shaadhi-card">
        <div className="shaadhi-card-body ">
          <div className="shaadhi-card-img">
            <Swiper
              spaceBetween={50}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
              style={{ height: "200px", width: "200px" }}
            >
              {data.image && data.image.map((image, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={image}
                    className="shaadhi-img"
                    style={{ filter: myProfile.payment === false ? "blur(4px)" : "none" }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            {verified && (<img className="verified-tag" src="./Images/varified-tag.svg" />)}
          </div>
          <div className="shaadhi-card-data">
            <div className="premium-badge-home-container">
              <h4 className="section-header">{data.firstName}{" "}{data.secondName}</h4>
              {data.payment && <div className="premium-badge-home">
                <img src="../Images/badge.svg" />
              </div>}
            </div>
            <hr />
            <div className="shaadhi-card-datamain">
              <div className="shaadhi-card-datatext1">
                <span>{age}years</span>
                <span>{data.height}</span>
                <span>{data.religion}</span>
                <span>{data.caste}</span>
                <span>{data.motherTounge}</span>
                <span>{data.maritalStatus}</span>
                <span>{data.state}</span>
                <span>{data.city}</span>
                <span>{data.occupation}</span>
              </div>
            </div>
            <p className="homecenter-popup-aboutme">
              {data.aboutMe.substring(0, 70)}...
              <span>
                <>
                  <Popup
                    modal
                    className="popup-modal"
                    trigger={<button className="Userview-profile-btn">View Profile</button>}
                    position="right top"
                  >
                    <div className="popup-container">
                      <ProfilePopup profileDetails={data} />
                    </div>
                  </Popup>{" "}
                </>
              </span>
            </p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="">
        <div className="">
          <div className="maindiv">
            <div className="home-container">
              <div className="content-one">
                <img src="/Images/send.png" />
                <p className="fav-para">
                  <Link to="/matches" style={{ textDecoration: "none", color: "black" }}>
                    {" "}
                    My Favorites <IoIosArrowForward className="icon-data" />
                  </Link>
                </p>
                <h5 className="huge">
                  {myProfile?.additionalDetails?.favouriteProfiles?.length}
                </h5>
              </div>
              <div className="content-two">
                <img src="/Images/person.png" />
                <p className="fav-para">
                  <Link to="/matches" style={{ textDecoration: "none", color: "black" }}>
                    Profile Interests <IoIosArrowForward className="icon-data" />
                  </Link>
                </p>
                <h5 className="huge">

                  {myProfile?.additionalDetails?.chatInterest?.length}
                </h5>
              </div>
              <div className="content-three">
                <img src="/Images/mssg.png" />
                <p className="fav-para">
                  <Link to="/chatpage" style={{ textDecoration: "none", color: "black" }}>
                    Messages <IoIosArrowForward className="icon-data" />
                  </Link>
                </p>
                {/* <h5 className="name-bold">320+</h5> */}
              </div>
            </div>
            <div className="maindiv-one">
              <div className="maindiv-sub">
                <div className="percentage-no">{profileCompletion}%</div>
                <div className="profile-prog">
                  <h5 className="program-data"> Profile Progress: {profileCompletion}% complete</h5>
                  <p className="word-one">Take it to 100% for More Features Platform Experience!</p>
                </div>
              </div>
              <div className="complete-button">
                <button className="complete-now" onClick={() => navigate("/edit/basicdetails")}> Complete Now</button>
              </div>
              <div className="homecenter-arrow-button">
                <button className="arrow-buttom" onClick={() => navigate("/edit/basicdetails")}><ArrowRight /></button>
              </div>
            </div>
            <div className="base-on">
              <h4 className="on-on section-header">Non-Premium Members</h4>
              <h5 className="view-all section-header" onClick={() => navigate("/matches")}>
                view all
              </h5>
            </div>
            <div className="maindata">
              {nonPremiumFiltered.length > 0 ? nonPremiumFiltered.map((data) => {
                return (<ProfileCard data={data} />);
              })
                : allProfiles.slice(0, 4).filter((val) => val.payment === false).map((data) => {
                  return (<ProfileCard data={data} />);
                })}
            </div>
            <div className="based">
              <h4 className="on section-header">Premium Members</h4>
              <h5 className="view-all section-header" onClick={() => navigate("/matches")}>view all</h5>
            </div>
            <div className="maindata">
              {premiumFiltered.length > 0 ? premiumFiltered.map((data) => {
                return (<ProfileCard data={data} />);
              })
                : allProfiles.slice(0, 4).filter((val) => val.payment === true).map((data) => {
                  return (<ProfileCard data={data} />);
                })}
            </div>
            <div className="card-one" onClick={() => navigate("/Payments")}>
              <h4 className="premium-homecenter">Premium Card</h4>
              <div className="">
                <div className="try-premium-container">
                  <div className=" try-premium-cards ">
                    <div>
                      <div className="try-premium-card">
                        <CheckCircle />
                        <p>Connect Easily with Premium Chat.</p>
                      </div>
                      <div className="try-premium-card">
                        <CheckCircle />
                        <p>Get Exclusive Matrimony Insights.</p>
                      </div>
                      <div className="try-premium-card">
                        <CheckCircle />
                        <p>Access All Features for a Richer Experience.</p>
                      </div>
                    </div>
                    <div>
                      <div className="try-premium-card">
                        <CheckCircle />
                        <p>Priority Match Viewing.</p>
                      </div>
                      <div className="try-premium-card">
                        <CheckCircle />
                        <p>Profile Highlight for Premium Members.</p>
                      </div>
                      <div className="try-premium-card">
                        <CheckCircle />
                        <p>Unlock Premium to See More.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="match-data">
              <h3 className="homecenter-allmathes section-header">{" "}All Matches</h3>
              <h5 className="view-all section-header" onClick={() => navigate("/matches")}> view all</h5>
            </div>
            <div className="maindata">
              {allProfiles.slice(0, 4).map((data) => {
                return (<ProfileCard data={data} />);
              })}
            </div>
            <div className="near-data">
              <h3 className="homecenter-allmathes section-header">
                {" "}
                Near By Location
              </h3>
              <h5 className="view-all section-header" onClick={() => navigate("/matches")}>
                view all
              </h5>
            </div>
            <div className="divdata-one">
              {nearedProfiles.slice(0, 4).map((data) => {
                return (<ProfileCard data={data} />);
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HomeCenter;