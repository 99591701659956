import React, { useEffect, useState } from "react";
import axios from "axios";
import "./feedBack.css";
import apiUrl from "../../../../config";

const FeedBacks = () => {
  const [feedBack, setFeedBack] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredFeedback = feedBack.filter(
    (item) =>
      item.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const currentItems = filteredFeedback.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredFeedback.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const getFeedBack = async () => {
    try {
      const res = await axios.get(apiUrl + "v4/feedback/getfeedback");
      setFeedBack(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getFeedBack();
  }, []);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset page number when search query changes
  };

  return (
    <div className="admin-feedack-main">
      <h1 className="feedback-heading">Feedback</h1>
      <div className="search-bar">
        <input
          className="form-control"
          type="search"
          placeholder="Search by email or name"
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>
      <div className="w-100">
        <table className="table table-striped table-hover">
          <thead>
            <tr  style={{ textAlign: "justify"}}>
              <th scope="col">S.No</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Subject</th>
              <th scope="col">Message</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((val, index) => (
              <tr key={index}>
                <td >{index + indexOfFirstItem + 1}</td>
                <td>{val.name}</td>
                <td>{val.email}</td>
                <td>{val.subject}</td>
                <td>{val.message}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ul className="pagination">
        {pageNumbers.map((number) => (
          <li key={number} className="page-item">
            <a onClick={() => paginate(number)} className="page-link">
              {number}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FeedBacks;
