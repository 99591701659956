import React, { useState } from "react";
import axios from "axios";
import apiUrl from "../../config";
import { useNavigate } from "react-router-dom";
import { FaEye, FaRegEyeSlash } from "react-icons/fa6";

const AdminLogin = () => {
  const navigate = useNavigate();
  const [isFieldFocused, setIsFieldFocused] = useState(false);
  const [login, setLogin] = useState({
    userName: "",
    password: "",
  });
 
  const OnFormSubmit = (e) => {
    e.preventDefault();
  };

  const handlechange = (e) => {
    const { name, value } = e.target;
    setLogin((prevState) => ({ ...prevState, [name]: value }));
  };

  const FormSubmit = async () => {
    try {
      const res = await axios.post(apiUrl + "v5/admin/adminLogin", login);
      if (res.data && res.data.adminId) {
        localStorage.setItem("adminId", res.data.adminId);
        navigate("/admin");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const togglePasswordVisibility = () => {
    setIsFieldFocused(!isFieldFocused);
  };

  return (
    <>
      <div className="center-container">
        <div className="admin-img">
          <img src="../Images/maangalya.png" />
        </div>
        <form onSubmit={OnFormSubmit} className="admin-form-main">
          <h3 className="admin-heading">Admin Login</h3>
          <div className="admin-form">
            <div className="mb-4 w-100">
              <input
                placeholder="Enter Email"
                type="text"
                className="form-control"
                name="userName"
                value={login.userName}
                onChange={handlechange}
              />
            </div>
            <div className=" admin-pwd mb-4">
              <input
                 type={isFieldFocused ? "text" : "password"}
                placeholder="Enter Password"
                name="password"
                className="form-control"
                value={login.password}
                onChange={handlechange}
              />
              {isFieldFocused ? (
                <FaRegEyeSlash
                  onClick={togglePasswordVisibility}
                  className="admin-eye-icon"
                />
              ) : (
                <FaEye
                  onClick={togglePasswordVisibility}
                  className="admin-eye-icon"
                />
              )}
            </div>
            <div className="admin-login-main">
              <button className="admin-form-btn" onClick={FormSubmit}>
                Login
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AdminLogin;
