import React, { useState, useRef } from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";
import "../../css/profileVerification.css";
import { MyProfileContext } from "../../../../context/ProfileProvider";
import apiUrl from "../../../../config";
import axios from "axios";
import BottomNavbar from "../../../../../src/components/BottomNavbar";
import NavBar from "./NavBar";
import Footer from "../../../../components/Footer";

export const ProfileVerification = () => {
  const { myProfile, userProfile } = React.useContext(MyProfileContext);
  const userProfileId = localStorage.getItem("profileId");
  const fileInputRef = useRef(null);
  const [error, setError] = useState("");
  const [select, setSelect] = useState("");
  const [details, setDetails] = useState({
    verificationProof: [],
  });

  const isAllowedFileType = (file) => {
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png", "image/svg+xml"];
    return allowedTypes.includes(file.type);
  };

  const handleFileUpload = () => {
    setError("");

    const fileInput = fileInputRef.current;
    const files = fileInput.files;

    if (select === "pan") {
      if (files.length > 0) {
        const selectedFile = files[0];
        if (isAllowedFileType(selectedFile)) {
          setDetails((prevDetails) => ({
            ...prevDetails,
            verificationProof: [selectedFile],
          }));
        } else {
          setError("Please select a valid image file for PAN verification (jpg, jpeg, png, or svg).");
        }
      } else {
        setError("Please select a file for PAN verification.");
      }
    } else if (files.length >= 2) {
      const selectedFiles = Array.from(files).slice(0, 2);
      if (selectedFiles.every(isAllowedFileType)) {
        setDetails((prevDetails) => ({
          ...prevDetails,
          verificationProof: selectedFiles,
        }));
        setError("");
      } else {
        setError("Please select valid image files (jpg, jpeg, png, or svg).");
      }
    } else {
      setError("Please select at least two files.");
    }
  };

  const CheckOptionSelected = () => {
    if (!select || select === "select") {
      setError("Select the document")
    }
  }
  const Updatedetalis = async () => {
    try {
      const form = new FormData();
      details.verificationProof.forEach((file, index) => {
        form.append("verificationProof", file);
      });

      const res = await axios.patch(
        apiUrl + `v1/profile/verification/${userProfileId}`,
        form
      );
      if (res.status === 200) {
        userProfile();
      }
    } catch (err) {
      console.log(err);
      setError(err.response.data.message)
    }
  };

  const cancel = () => {
    setDetails({
      verificationProof: [],
    });
    setError("");
  }

  return (
    <>
      <div className="sticky-top">
        <NavBar />
      </div>
      <div className="container-fluid">
        <div className="container profile-varification-container">
          <div className="profile-verification-page">
            <div className="w-100 mt-2">
              <div className="profile-verification-top-div">
                <h1 className="profile-page-heading">Profile Verification</h1>
                <p className="profile-page-para">
                Upload your documents to verify your profile and gain access to view more  profiles.
                </p>
              </div>
              <div className="profile-page-center-div  shadow-sm p-3 mb-5 bg-body-tertiary rounded">
                {myProfile.verificationProof ? (
                  <div className="varification-img-container">
                    <img
                      className="varification-img"
                      src={myProfile.verificationProof[0]}
                    />
                    <img
                      className="varification-img"
                      src={myProfile.verificationProof[1]}
                    />
                  </div>
                ) : (
                  <div>
                    <div>
                      <h1 className="verify-your-profile">
                        Verify your profile
                      </h1>
                      <div className="image-details">
                        <div>
                          {select ? (
                            <label htmlFor="fileInput">
                              {details.verificationProof.length > 0 ? (
                                <div>
                                  {select === "pan" ? (
                                    <img
                                      src={URL?.createObjectURL(
                                        details?.verificationProof[0]
                                      )}
                                      alt=""
                                      style={{
                                        width: "150px",
                                        height: "150px",
                                        padding: "5px",
                                      }}
                                    />
                                  ) : (
                                    <>
                                      <img
                                        src={URL?.createObjectURL(
                                          details?.verificationProof[0]
                                        )}
                                        alt=""
                                        style={{
                                          width: "150px",
                                          height: "150px",
                                          padding: "5px",
                                        }}
                                      />
                                      <img
                                        src={URL.createObjectURL(
                                          details?.verificationProof[1]
                                        )}
                                        alt=""
                                        style={{
                                          width: "150px",
                                          height: "150px",
                                          padding: "5px",
                                        }}
                                      />
                                    </>
                                  )}
                                </div>
                              ) : (
                                <img
                                  src="./Images/verification.svg"
                                  alt=""
                                  style={{ cursor: "pointer", opacity: select ? 1 : 0.5 }}
                                  title={select ? "Click to upload" : "Please select an option"}
                                />
                              )}
                            </label>
                          ) : (
                            <img
                              src="./Images/verification.svg"
                              alt=""
                              style={{ cursor: "not-allowed" }}
                              title="Please select an option"
                              onClick={CheckOptionSelected}
                            />
                          )}
                          <input
                            id="fileInput"
                            type="file"
                            accept=".jpg, .jpeg, .png, .svg"
                            multiple
                            ref={fileInputRef}
                            onChange={handleFileUpload}
                            style={{ display: "none" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="Icon-upload-div">
                        <p>
                          <IoIosInformationCircleOutline
                            style={{ fontSize: "30px" }}
                          />
                        </p>
                        <p className="upload-my">Upload any .</p>
                      </div>
                      {error && <p style={{ color: "red" }}>{error}</p>}
                      <select
                        className="personal-credentials"
                        value={select}
                        onChange={(e) => setSelect(e.target.value)}
                      >
                        <option value="">Select</option>
                        <option value="pan">Pan</option>
                        <option value="aadhar">Aadhar</option>
                        <option value="licence">Driving Licence</option>
                        <option value="passport">Passport</option>
                      </select>
                      <div className="verification-btns">

                        <button className="cancel-button" onClick={cancel}>Cancel</button>
                        <button
                          className="profile-page-submit-btn"
                          onClick={Updatedetalis}
                        >
                          {" "}
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <Footer />
      </div>
      <div className="sticky-bottom">
        <BottomNavbar />
      </div>
    </>
  );
};
