import React, { useState } from "react";
import apiUrl from "../../../../../config";
import axios from "axios";
import { FaEye, FaLongArrowAltLeft, FaRegEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export const Delete = () => {
  const [isFieldFocused, setIsFieldFocused] = useState(false);
  const navigate = useNavigate();
  const userProfileId = localStorage.getItem("profileId");
  const [errors, setErrors] = useState("");
  const [passwords, setPasswords] = useState("");

  const onFormSubmit = (e) => {
    e.preventDefault();
  };

  const back = () => {
    navigate("/settings");
  };

  const togglePasswordVisibility = () => {
    setIsFieldFocused(!isFieldFocused);
  };

  const deleteProfile = async () => {
    try {
      if (!passwords) {
        setErrors("Please enter your password to delete your profile.");
        return;
      }
      const res = await axios.delete(
        apiUrl + `v1/profile/deleteProfile/${userProfileId}`,
        {
          data: { password: passwords },
        }
      );
      if (res.data === true) { // Corrected condition here
        navigate("/");
      }
    } catch (err) {
      console.log(err);
      setErrors(err.response.data.message);
    }
  };

  return (
    <>
      <div className="Email-Phone-Number-container">
        <button
          onClick={() => back()}
          className="Email-Phone-Number-container-icon"
        >
          <FaLongArrowAltLeft />
        </button>
        <h4 className="mobive-view-btns">Delete Profile</h4>
      </div>
      <div className="del-container">
        <div className="">
          <div className="para-del">
            <h2 className="head-name settings-heading-name">
              Delete Your Profile
            </h2>
            <p className="settings-sub-heading-name">
              Ready to go? Deleting your profile is easy. Just confirm below,
              and your matches and messages will be gone. Thanks for being part
              of <span className="head-del">MAANGALYAAM</span>, and best wishes
              ahead!
            </p>
          </div>
          <div className="pass-del-cont">
            <h6 className="pass-del">Reason for deleting profile</h6>
            <div className="radeo-btns">
              <input type="radio" name="anyone" value="Found a match" />
              <label className="one-del">Found a match</label>
            </div>
            <div className="radeo-btns">
              <input type="radio" name="anyone" value="No longer interested" />
              <label className="two-del">No longer interested</label>
            </div>
          </div>
          <form onSubmit={onFormSubmit}>
            <div className="delete-page-form-container">
              <label className="pass-del">Password Confirmation</label>
              <input
                className="inp-del form-control"
                placeholder="Enter Here"
                onChange={(e) => setPasswords(e.target.value)}
                type={isFieldFocused ? "text" : "password"} // Show password if focused
              />
              {isFieldFocused ? (
                <FaRegEyeSlash
                  onClick={togglePasswordVisibility}
                  className="delete-pwd-eye-icon"
                />
              ) : (
                <FaEye
                  onClick={togglePasswordVisibility}
                  className="delete-pwd-eye-icon"
                />
              )}
              </div>
              {errors && (
                <p style={{ color: "red", margin: "0px" }}>{errors}</p>
              )}
              <button className="btn-del" onClick={deleteProfile}>
                Continue
              </button>
       
          </form>
        </div>
      </div>
    </>
  );
};
