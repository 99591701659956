import React, { useEffect, useState, useRef, useContext } from "react";
import "../../css/chat.css";
import { TelephoneFill, ThreeDotsVertical } from "react-bootstrap-icons";
import { IoArrowBackOutline } from "react-icons/io5";
import io from "socket.io-client";
import axios from "axios";
import apiUrl from "../../../../config";
import { Link, useLocation } from "react-router-dom";
import NavBar from "../DashBoard/NavBar";
import { MyProfileContext } from "../../../../context/ProfileProvider";
import SuccessPopUp from "../../../../popups/SuccessPopUp";
import ErrorPopup from "../../../../popups/ErrorPopup";

const Chat = (props) => {
  const Receiver = props?.data;
  const userProfileId = localStorage.getItem("profileId");
  const location = useLocation();
  const receiver = location.state;
  const senderId = parseInt(userProfileId);
  const receiverId = Receiver?.id || receiver?.id;
  const roomId = senderId + receiverId;
  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [messageLength, setMessageLength] = useState(0);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [success, setSuccess] = useState("")
  const chatContainerRef = useRef(null);
  const [hasOfflineMessages, setHasOfflineMessages] = useState(false);
  const [error, setError] = useState('')
  const { updateTotalLength } = props;
  const [showBlockButton, setShowBlockButton] = useState(false);
  const { userProfile, myProfile } = useContext(MyProfileContext)


  const handleShowSuccess = () => {
    setshowSuccess(true);
  };

  const handleCloseSuucess = () => {
    setshowSuccess(false);
  };

  const handleShowError = () => {
    setShowError(true);
  };

  const handleCloseError = () => {
    setShowError(false);
  };

  useEffect(() => {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, [messages]);


  const getMessage = async () => {
    try {
      const res = await axios.get(apiUrl + `v3/chat/myMessage/${roomId}`);
      setMessages(res.data);
      console.log("Messages retrieved successfully:", res.data);
    } catch (error) {
      console.log("Error fetching messages:", error);
    }
  };

  const checkstatus = () => {
    if (isTyping) {
      return <h6 className="online-para online">Typing...</h6>;
    } else if (socket && socket.connected) {
      return <h6 className="online-para online">Online</h6>;
    } else {
      return <h6 className="online-para offline">Offline</h6>;
    }
  };

  useEffect(() => {
    getMessage();

    const newSocket = io("http://localhost:4000");
    setSocket(newSocket);

    newSocket.on("connect", () => {
      newSocket.emit("join chat", roomId);
    });

    const handleMessage = ({ message, timestamp }) => {
      console.log("Received message:", {
        message,
        senderId,
        receiverId,
        timestamp,
      });
      setMessages((prevMessages) => [...prevMessages, { senderId, message, receiverId, createdAt: timestamp },]);
      setMessageLength(message.length);
      setHasOfflineMessages(true);
      setHasOfflineMessages(true);
      // updateTotalLength(message.length);
    };

    newSocket.on("message", handleMessage);
    return () => {
      newSocket.disconnect();
    };
  }, [roomId, senderId]);

  useEffect(() => {
    if (!socket) return;
    const handleMessage = ({ message, timestamp }) => {
      console.log("Received message:", {
        message,
        senderId,
        receiverId,
        timestamp,
      });
      setMessages((prevMessages) => [...prevMessages, { senderId, message, receiverId, createdAt: timestamp },]);
    };
    socket.on("getMessages", handleMessage);
    socket.on("typing", () => {
      setIsTyping(true);
    });

    socket.on("stop typing", () => {
      setIsTyping(false);
    });

    return () => {
      socket.off("message", handleMessage);
      socket.off("typing");
      socket.off("stop typing");
    };
  }, [socket, messages]);

  const sendMessage = async () => {
    try {
      if (messageInput.trim() === "") {
        console.log("Empty message cannot be sent");
        return;
      }

      if (!senderId) {
        console.log("Sender ID is undefined. Cannot send message.");
        return;
      }

      console.log("Sending message with:", { message: messageInput, senderId, receiverId, roomId });

      await axios.post(apiUrl + "v3/chat/sendMessage", { message: messageInput, senderId, receiverId, roomId });

      // socket.emit("message", { roomId, message: messageInput, senderId, receiverId, timestamp: Date.now(), });
      setMessageInput("");
      console.log("Message sent successfully to the server");
    } catch (error) {
      console.log("Error sending a private message to the server:", error);
      setError(error.response.data.message)
      handleShowError()

    }
    if (!socket || !messageInput.trim()) return;
    socket.emit("stop typing");
  };

  const handleTyping = () => {
    if (!isTyping) {
      socket.emit("typing");
      setIsTyping(true);
    }
  };

  const formatTimestamp = (createdAt) => {
    if (!createdAt) {
      return "Invalid Date";
    }
    const messageDate = new Date(createdAt);
    return formatTime(messageDate);
  };

  const formatTime = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const amOrPm = hours >= 12 ? "PM" : "AM";
    const formattedHours = (hours % 12 || 12).toString().padStart(2, "0");
    return `${formattedHours}:${minutes} ${amOrPm}`;
  };

  const renderMessages = () => {
    const groupedMessages = groupMessagesByDate(messages);
    return Object.keys(groupedMessages).map((date) => {
      const messagesForDate = groupedMessages[date];
      console.log(messagesForDate.senderId,"messagesForDate")
      return (
        <React.Fragment key={date}>
          <div className="message-date">{date}</div>

          {messagesForDate.map((msg, index) => (
            <div key={index} className={`message ${msg.senderId ==senderId ? "user" : "receiver"}`}>
              <p className="chat-messages d-flex flex-wrap">{msg.message}</p>
              <p className="timestamp">{formatTimestamp(msg.createdAt)}</p>
            </div>
          ))}
        </React.Fragment>
      );
    });
  };


  const groupMessagesByDate = (messages) => {
    const groupedMessages = {};
    messages.forEach((msg) => {
      const messageDate = new Date(msg.createdAt);
      if (isToday(messageDate)) {
        const todayString = "Today";
        if (!groupedMessages[todayString]) {
          groupedMessages[todayString] = [];
        }
        groupedMessages[todayString].push(msg);
      } else if (isYesterday(messageDate)) {
        const yesterdayString = "Yesterday";
        if (!groupedMessages[yesterdayString]) {
          groupedMessages[yesterdayString] = [];
        }
        groupedMessages[yesterdayString].push(msg);
      } else {
        const dateString = messageDate.toLocaleDateString();
        if (!groupedMessages[dateString]) {
          groupedMessages[dateString] = [];
        }
        groupedMessages[dateString].push(msg);
      }
    });
    return groupedMessages;
  };

  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const isYesterday = (date) => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return (
      date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear()
    );
  };


  const handleThreeDotsClick = () => {
    setShowBlockButton(!showBlockButton);
  };

  const blockProfile = async () => {
    let action;
    try {
      let blockId = receiverId;
      action = 'block';
      if (myProfile.additionalDetails.blockedProfiles.some(profile => profile.id === receiverId)) {
        action = 'unblock';
      }
      await axios.patch(apiUrl + `v1/profile/${action}Profile/${userProfileId}`, { blockId });
      userProfile()
      if (action === 'unblock') {
        setShowBlockButton(false);
        setSuccess("The profile is unblocked");
      } else {
        setSuccess("The profile is blocked");
      }
      handleShowSuccess();
      if (action === 'block') {
        setShowBlockButton(false);
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data && err.response.data.message) {
        setError(err.response.data.message);
      }
      handleShowError();
      if (action === 'block') {
        setShowBlockButton(false);
      }
    }
  };
  useEffect(() => { userProfile() }, [])
  return (
    <div className="chat-main-contianer-width">
      <div className="back-button">
        <Link to="/chatpage">
          <IoArrowBackOutline className="ms-3 mt-4" style={{ color: "black" }} size={25} />
        </Link>
      </div>
      <nav className="navbar-chat navbar-chat-top bg-body-tertiary">
        <div className="nav-chat">
          <div>
            <img src={Receiver?.image[0] || receiver?.image[0]}
              alt="Image not found"
              style={{ width: "60px", height: "60px", borderRadius: "50%" }}
            />
          </div>
          <div className="connie">
            <p className="connie-para">
              {(Receiver?.secondName) || (receiver?.secondName)}
            </p>
            <p className="online-para">{checkstatus()}</p>
          </div>
        </div>
        <div className="chat-icons">
          <ThreeDotsVertical onClick={handleThreeDotsClick} />
          {showBlockButton && (
            <button className="chat-interested-btn" onClick={blockProfile}>
              {myProfile.additionalDetails.blockedProfiles.some(profile => profile.id === receiverId) ? 'Unblock' : 'Block'}
            </button>
          )}
        </div>
      </nav>
      <div className="container-chat" ref={chatContainerRef}>
        {renderMessages()}
      </div>
      <div className="bottom-chat sticky">
        <input
          type="text"
          className="input-text-message"
          placeholder="Type Here......"
          value={messageInput}
          onChange={(e) => setMessageInput(e.target.value)}
        />
        <img
          className="message-sent-image"
          src="../Images/message-sent-image.svg"
          alt="Image not found"
          onClick={sendMessage}
          onKeyDown={(e) => {
            console.log('KeyDown event:', e);
            handleTyping();
            console.log('After handleTyping');
            if (e.key === "Enter") {
              e.preventDefault();
              sendMessage();
            }
          }}
          tabIndex="0"
          role="button"
        />
      </div>
      {showSuccess && <SuccessPopUp message={success} onClose={handleCloseSuucess} />}
      {showError && <ErrorPopup message={error} onClose={handleCloseError} />}
    </div>
  );
};
export default Chat;