import React from "react";
import "../css/termsConditions.css";
import Footer from "../../../../src/components/Footer";
import Navbar from "../../../../src/components/Navbar";
import NavBar from "./DashBoard/NavBar";

const TermsConditions = () => {
  const isLoggedIn = localStorage.getItem("profileId");
  return (
    <>
      <div>
        {isLoggedIn ? (
          <div className="sticky-top">
            <NavBar />
          </div>
        ) : (
          <div className="sticky-top">
            <Navbar />
          </div>
        )}
      </div>
      <div className="container-fluid">
        <div className="container">
          <div className="terms-main-container">
            <h1 className="term-main-heading">
              Terms and Conditions for{" "}
              <span className="name-color">MAANGALYAM</span>{" "}
            </h1>
            <p className="term-content">Last Updated: 09-02-2024</p>

            <h2 className="term-heading"> Acceptance of Terms</h2>
            <p className="term-content">
              By using MAANGALYAM (the "Website"), you agree to comply with and
              be bound by these terms and conditions. If you do not agree with
              these terms, please do not use the Website.
            </p>

            <h2 className="term-heading"> Eligibility</h2>
            <p className="term-content">
              You must be at least 18 years old to use this Website. By using
              the Website, you represent and warrant that you are at least 18
              years old.
            </p>

            <h2 className="term-heading"> User Accounts</h2>
            <p className="term-content">
              <strong className="term-sub-heading">Registration:</strong> To
              access certain features of the Website, you may be required to
              register for an account. You agree to provide accurate, current,
              and complete information during the registration process.
            </p>
            <p className="term-content">
              <strong className="term-sub-heading">Account Security:</strong>{" "}
              You are responsible for maintaining the confidentiality of your
              account credentials and for all activities that occur under your
              account. Notify us immediately of any unauthorized use or security
              breach.
            </p>

            <h2 className="term-heading"> User Conduct</h2>
            <p className="term-content">
              <strong className="term-sub-heading">
                {" "}
                Prohibited Activities:
              </strong>{" "}
              Users must not engage in any illegal or unauthorized activities on
              the Website. This includes but is not limited to:
              <ul>
                <li>Violating applicable laws and regulations</li>
                <li>Harassing, threatening, or abusing other users</li>
                <li>Uploading malicious software or content</li>
              </ul>
            </p>
            <p className="term-content">
              <strong className="term-sub-heading">Content Guidelines:</strong>{" "}
              Users are responsible for the content they post on the Website.
              Content must not be offensive, misleading, or violate the rights
              of others.
            </p>

            <h2 className="term-heading"> Privacy</h2>
            <p className="term-content">
              Your use of the Website is also governed by our Privacy Policy.
              Please review the Privacy Policy for information on how we
              collect, use, and protect your personal information.
            </p>

            <h2 className="term-heading"> Intellectual Property</h2>
            <p className="term-content">
              <strong className="term-sub-heading">Ownership:</strong> The
              Website and its content, including text, graphics, logos, and
              images, are the property of MAANGALYAM and are protected by
              intellectual property laws.
            </p>
            <p className="term-content">
              <strong className="term-sub-heading">License:</strong> You are
              granted a limited, non-exclusive, revocable license to access and
              use the Website for personal use. You may not reproduce,
              distribute, or modify any part of the Website without our express
              consent.
            </p>

            <h2 className="term-heading"> Termination</h2>
            <p className="term-content">
              We reserve the right to terminate or suspend your account and
              access to the Website at our discretion, without prior notice, for
              any reason, including a breach of these terms and conditions.
            </p>

            <h2 className="term-heading"> Changes to Terms</h2>
            <p className="term-content">
              We may update these terms and conditions periodically. The date at
              the top of this document indicates the last update. Continued use
              of the Website after changes constitutes acceptance of the updated
              terms.
            </p>

            <h2 className="term-heading"> Contact Us</h2>
            <p className="term-content">
              If you have any questions or concerns regarding these terms and
              conditions, please contact us at.
            </p>

            <p className="term-content">
              Maangalaaym
              <br />
         
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <Footer />
      </div>
    </>
  );
};

export default TermsConditions;
