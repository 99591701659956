import React, { useContext, useEffect, useState } from "react";
import "../../css/chatRequest.css";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Chat from "./Chat";
import { IoArrowBackOutline } from "react-icons/io5";
import axios from "axios";
import apiUrl from "../../../../config";
import { useLocation } from "react-router-dom";
import { MyProfileContext } from "../../../../context/ProfileProvider";
import ProfilePopup from "../ProfilePopup";
import Popup from "reactjs-popup";
import { CloudDownload } from "react-bootstrap-icons";
import SuccessPopUp from "../../../../popups/SuccessPopUp";
import ErrorPopup from "../../../../popups/ErrorPopup";

const ChatRequest = (props) => {
  const [requests, setRequests] = useState([]);
  const location = useLocation();
  const { myProfile } = React.useContext(MyProfileContext);
  const mobileChatRequest = location.state;
  const chatRequest = props;
  const [isAccepted, setIsAccepted] = useState(false);
  const { userProfile } = useContext(MyProfileContext)
  const userProfileId = localStorage.getItem("profileId");
  const [error, setError] = useState()
  let navigate = useNavigate();

  const AcceptingRequest = () => {
    setRequests(chatRequest.data || mobileChatRequest);
  };
  const acceptChatRequest = async (id) => {
    const senderId = userProfileId;
    const receiverId = id;
    try {
      await axios.patch(apiUrl + `v3/chat/accept-request`, { senderId, receiverId });
      setSuccess("Chat request is accepted successfull")
      handleShowSuccess()
    } catch (error) {
      console.error("Error accepting chat request:", error);
      setError(error.response.data.message)
      handleShowError()
    }
  };

  const calculateAge = () => {
    const birthDate = new Date(requests.dateOfBirth);
    const currentDate = new Date();

    let age = currentDate.getFullYear() - birthDate.getFullYear();
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return {
      age,
    };
  };

  const [showError, setShowError] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [success, setSuccess] = useState("")

  const RejectRequest = async (id) => {
    const receiverId = id;
    const senderId = userProfileId;
    try {
      await axios.patch(apiUrl + `v3/chat/decline-request/${senderId}`, { receiverId, });
      setSuccess("Profile is rejected")
      handleShowSuccess()
      userProfile()
    } catch (error) {
      console.error("Error accepting chat request:", error);
      setError(error.response.data.message)
      handleShowError()
    }
  };

  const TwoFunction = (id) => {
    acceptChatRequest(id);
    setIsAccepted(!isAccepted);
  };

  const renderRequestCard = () => {
    return (
      <>
        <div className="back-button">
          <Link to="/chatpage">
            <IoArrowBackOutline className="ms-3" size={25} />
          </Link>
        </div>
        <div className="Request-card">
          <div className="Right-Request-card">
            <div className="card-data">
              <div className="card-data-left">
                <div className="matchesfeed-card-img-1">
                  <img
                    src={requests.image && requests.image[0]}
                    className="chatrequest-img"
                    style={{
                      filter:
                        myProfile.payment === false ? "blur(4px)" : "none",
                    }}
                  />
                </div>

                <div className="matchesfeed-card-body ">
                  <div className="chat-card-data-matches">
                    <div className="chatrequest-name-img">
                      <h4>
                        {requests.firstName}
                        {requests.secondName}
                      </h4>
                      {requests.payment ? (
                        <div className="Right-Request-card-badge">
                          <p className="Right-Request-card-badge-content">
                            Premium
                          </p>
                          <img
                            className="badge-image"
                            src="../Images/badge.svg"
                            alt="Image not found"
                          />
                        </div>
                      ) : null}
                    </div>

                    <hr />
                    <div className="matchesfeed-card-datamain">
                      <div className="shaadhi-card-datatext1">
                        <span>{calculateAge}yrs</span>
                        <span>{requests.height}</span>
                        <span>{requests.religion}</span>
                        <span>{requests.caste}</span>
                        <span>{requests.motherTounge}</span>
                        <span>{requests.maritalStatus}</span>
                        <span>{requests.state}</span>
                        <span>{requests.city}</span>
                        <span>{requests.occupation}</span>
                      </div>
                    </div>
                    <p className="matches-shaadhi-aboutMe">
                      {requests.aboutMe && requests.aboutMe.substring(0, 85)}...
                      <span>
                        <>
                          <Popup
                            modal
                            className="popup-modal"
                            trigger={
                              <button className="Userview-profile-btn">
                                View Profile
                              </button>
                            }
                            position="right top"
                          >
                            <div className="popup-container">
                              <ProfilePopup profileDetails={requests} />
                            </div>
                          </Popup>{" "}
                        </>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Request-card-bottom">
            <div className="Request-card-bottom-left">
              <p className="Request-bottom-content">She likes your profile!</p>
              <p className="Request-bottom-content-para">
                Accept to chat and explore the connection further.
              </p>
            </div>
            <div className="Request-card-bottom-right">
              <button
                className="Request-bottom-Acceptbtn"
                onClick={() => TwoFunction(requests.id)}
              >
                Accept
              </button>
              <button
                className="Request-bottom-Rejectbtn"
                onClick={() => RejectRequest(requests.id)}
              >
                Reject
              </button>{" "}
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleShowError = () => {
    setShowError(true);
  };

  const handleCloseError = () => {
    setShowError(false);
  };

  const handleShowSuccess = () => {
    setshowSuccess(true);
  };

  const handleCloseSuucess = () => {
    setshowSuccess(false);
  };

  useEffect(() => {
    AcceptingRequest();
  }, []);

  return (
    <div className="requestpage-bg-container">
      <div className="request-page-main-container">
        {isAccepted ? (
          <div className="chat-container">
            <Chat data={chatRequest.data} />
          </div>
        ) : (
          renderRequestCard()
        )}
      </div>
      {showSuccess && <SuccessPopUp message={success} onClose={handleCloseSuucess} />}
      {showError && <ErrorPopup message={error} onClose={handleCloseError} />}
    </div>
  );
};

export default ChatRequest;
