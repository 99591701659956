import React from "react";
import NavBar from "./DashBoard/NavBar";
import Navbar from "../../../components/Navbar";
import "../css/faq.css"
import Footer from "../../../components/Footer";

const Faq = () => {
  const isLoggedIn = localStorage.getItem("profileId");
  return (
    <>
      {" "}
      {isLoggedIn ? (
        <div className="sticky-top">
          <NavBar />
        </div>
      ) : (
        <div className="sticky-top">
          <Navbar />
        </div>
      )}
      <div className="container-fluid">
        <div className="container">
          <div className="cont-help">
            <div className="help-cont">
              <h2 className="help-heading">Frequently Asked Questions?</h2>
            </div>
            <div className="cont-2">
              <div class="accordion accordion-flush" id="accordionFlushExample">
                <div
                  class="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div className="help">
                    <h6 className="help-subheading">Browse Help Topics</h6>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        <img src="/images/helpimg.png" />
                        <div className="lorem"> How to Register </div>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        <p className="help-content">
                          Click on the "Login" or "Get Started" link to access
                          the login page. Below, you will find the "Create
                          Account" or "Register" link. Click on it to proceed to
                          a series of pages where you'll enter the details of
                          the person intending to get married. Once you submit
                          your information, your profile will be automatically
                          created, and a password will be generated for future
                          reference and login purposes.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        <img src="/images/helpimg.png" />
                        <div className="lorem"> How to Login </div>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        <p className="help-content">
                          Loged in from the Home page and enter your email-id or
                          Phone number and password into the respective boxes
                          and click Login button.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        <img src="/images/helpimg.png" />
                        <div className="lorem">
                          {" "}
                          How to create Premium Membership
                        </div>{" "}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        <p className="help-content">
                          Go to the "Plans" screen to access payment details.
                          Make the payment to acquire premium membership and
                          unlock full profile details.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFour"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        <img src="/images/helpimg.png" />
                        <div className="lorem">
                          {" "}
                          How do I modify my profile
                        </div>{" "}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFour"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        <p className="help-content">
                          Once you are logged in to your account, click on the
                          edit button on the right-side of the screen Profile
                          from the menu and make changes to your profile.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFive"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        <img src="/images/helpimg.png" />
                        <div className="lorem">How to I chat</div>{" "}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFive"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        <p className="help-content">
                          To engage in online chats with other members, you must
                          first become a premium member. After logging in,
                          utilize the "Who's Online" search form to discover
                          members currently active who match your specified
                          criteria. Upon finding a member of interest, visit
                          their profile and express interest by clicking the
                          "interested" button. If the member accepts your chat
                          request, a link to initiate the conversation will be
                          provided, allowing both parties to start chatting.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseSix"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        <img src="/images/helpimg.png" />
                        <div className="lorem">
                          How can I verified my profile
                        </div>{" "}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseSix"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        <p className="help-content">
                          You can confirm your profile details by providing an
                          identification document for verification purposes. The
                          submitted document will not be disclosed to other
                          members and will solely be used to authenticate your
                          profile details. Upon successful verification, your
                          profile will be labeled as "Verified."
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseSeven"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        <img src="/images/helpimg.png" />
                        <div className="lorem">
                          How can I update my password?
                        </div>{" "}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseSeven"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        <p className="help-content">
                          To modify your password, navigate to Profile Settings
                          and select "Change Password." Input your Current
                          Password, followed by your New Password and confirm
                          it. Finally, click on the "Change Password" button to
                          save the changes.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseEight"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        <img src="/images/helpimg.png" />
                        <div className="lorem">
                          How do I view/edit Blocked profiles?
                        </div>{" "}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseEight"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        <p className="help-content">
                          The Block Profile feature is exclusive to paid
                          members. To access the list of profiles you've
                          blocked, log in to your account, go to the My Home
                          page, and under Lists, click on the "members I have
                          blocked" link. To unblock profiles, select the desired
                          profile from the list and click on the "Unblock" link.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseNine"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        <img src="/images/helpimg.png" />
                        <div className="lorem">
                          What about the advance search?
                        </div>{" "}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseNine"
                      class="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        <p className="help-content">
                          This advanced search provides enhanced filtering
                          options for more precise results. You can specify
                          preferences such as mother tongue, status, in addition
                          to basic criteria like age, height, marital status,
                          regional sites, religion, caste/division, subcaste, ,
                          country of residence, education, and the option to
                          filter results based on when they were posted. You can
                          save up to three customized searches, which will
                          appear in the right-hand corner for easy access. To
                          access this feature, log in using your Matrimony or
                          email ID and password, then click on the "Advanced
                          Search" .
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <Footer />
      </div>
    </>
  );
};

export default Faq;
