import "../css/paymentintegration.css";
import { PiToggleLeftFill } from "react-icons/pi";
import { FaRupeeSign } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { TiTick } from "react-icons/ti";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../../components/Footer";
import Navbar from "../../../components/Navbar";
import axios from "axios";
import apiUrl from "../../../config";
import React, { useEffect, useState } from "react";
import { MyProfileContext } from "../../../context/ProfileProvider";
import NavBar from "./DashBoard/NavBar";

function PaymentIntegration() {
  const [isCardOneZoomed, setIsCardOneZoomed] = useState(false);
  const [isCardTwoZoomed, setIsCardTwoZoomed] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [dataid, setDataid] = useState(null); // Declare dataid state
  const { myProfile } = React.useContext(MyProfileContext);

  useEffect(() => {
    const storedDataid = localStorage.getItem("profileId");

    if (storedDataid) {
      setIsLoggedIn(true);
      setDataid(storedDataid);
    }
  }, []);

  const navigate = useNavigate();

  const handleCardOneClick = () => {
    setIsCardOneZoomed(!isCardOneZoomed);
    setIsCardTwoZoomed(false);
  };

  const handleCardTwoClick = () => {
    setIsCardTwoZoomed(!isCardTwoZoomed);
    setIsCardOneZoomed(false);
  };

  const handlePaymentVerification = async (amount, name, paymentResponse, paymentType) => {
    try {
      const verificationResponse = await axios.post(`${apiUrl}v2/payment/paymentverification/${parseInt(dataid)}`, { amount, name, paymentResponse, paymentType });
      console.log(verificationResponse, "verificationResponse")
    } catch (err) {
      console.error("Error during payment verification:", err);
    }
  };

  const checkoutHandler = async ({ name, amount }) => {
    if (isLoggedIn) {
      try {
        const response = await axios.post(`${apiUrl}v2/payment/checkout`, { name, amount, });
        if (response.data.success === true) {
          var options = {
            key: "rzp_live_0mcsZ5oVfXFfmj",
            amount: response.data.order.amount, // Convert amount to paise
            currency: "INR",
            name: "Acme Corp",
            description: "Test Transaction",
            image: "https://example.com/your_logo",
            order_id: response.data.order.id,
            prefill: {
              name: "Gaurav Kumar",
              email: "gaurav.kumar@example.com",
              contact: "9000090000",
            },
            notes: {
              address: "Razorpay Corporate Office",
            },
            theme: {
              color: "#3399cc",
            },
            handler: function (paymentResponse) {
              handlePaymentVerification(response.data.order.amount, name, paymentResponse, amount);
            },
          };
          var rzp1 = new window.Razorpay(options);
          rzp1.open();
        }
      } catch (err) {
        console.log(
          "Error during checkout:",
          err.response ? err.response.data : err.message
        );
      }
    } else {
      navigate("/login");
    }
  };

  return (
    <div>
      {isLoggedIn ? (
        <div className="sticky-top">
          <NavBar />
        </div>
      ) : (
        <div className="sticky-top">
          <Navbar />
        </div>
      )}
      <div className="container-fluid">
        <div className="container">
          <div className="container-paymentpage">
            <div className="">
              <h1 className="payment-heading">
                Powerful features for
                <br />{" "}
                <span className="clr-gradient">
                  powerful <span className="two-clr-gradient">creators</span>
                </span>
              </h1>
              <p className="payment-para">Choose a plan that’s right for you</p>
            </div>
          </div>
          <div
            className={`twocards ${isCardOneZoomed || isCardTwoZoomed ? "zoomed-out" : ""
              }`}
          >
            <div
              className={`card cardone ${isCardOneZoomed ? "zoomed-in" : ""}`}
              onClick={handleCardOneClick}
            >
              <div class="card-body card-size">
                <p class="card-text onecards-paras grey-clr">
                  Unlock essential features and connect with your audience.
                  Enjoy unlimited access to profiles, messages, and more.
                </p>
                <div className="rupee-twentyfive-month">
                  <p className="rupee-symbol-zero">
                    <FaRupeeSign className="rupee-symbol" />
                    999
                  </p>
                  <span className="onecards-paras  month grey-clr">
                    /3 Months
                  </span>
                </div>
                <button
                  className="cardone-btn"
                  onClick={() =>
                    checkoutHandler({ name: `${myProfile.firstName} ${myProfile.secondName}`, amount: 999, paymentType: "monthly", })
                  }
                >
                  Get Started Now
                </button>
                <div className="twocards-paras-div">
                  <p className="bold-letters  ">
                    <TiTick className="right-ticks " />
                    <span className="payment-paras">
                      Subscribe for 3 months get 3 months for free{" "}
                    </span>
                  </p>
                  <p className="bold-letters">
                    <TiTick className="right-ticks" />
                    <span className="payment-paras">
                      View upto 300 profiles{" "}
                    </span>
                  </p>
                  <p className="onecards-paras grey-clr ">
                    {" "}
                    <RxCross2 className="crossticks" />
                    <span className="payment-paras">
                      send Unlimited Messages
                    </span>
                  </p>
                  <p className="onecards-paras grey-clr ">
                    {" "}
                    <RxCross2 className="crossticks" />
                    <span className="payment-paras">Unlimited Sharing</span>
                  </p>
                  <p className="onecards-paras grey-clr ">
                    {" "}
                    <RxCross2 className="crossticks" />
                    <span className="payment-paras">
                      Upload graphics & video in up to 4k
                    </span>
                  </p>
                  <p className="onecards-paras grey-clr ">
                    {" "}
                    <RxCross2 className="crossticks" />
                    <span className="payment-paras"></span>Unlimited Projects
                  </p>
                </div>
              </div>
            </div>

            <div
              className={`card cardtwo ${isCardTwoZoomed ? "zoomed-in" : ""}`}
              onClick={handleCardTwoClick}
            >
              <div class="card-body card-size">
                <h5 class="card-title cardtwo-heading premium">Premium</h5>
                <p class="card-text twocards-paras">
                  Elevate your experience with premium access. Access a wealth
                  of profiles, send unlimited messages, and enjoy exclusive
                  features.
                </p>
                <div className="rupee-twentyfive-month">
                  <p className="rupee-symbol-zero">
                    <FaRupeeSign className="rupee-symbol" />
                    1999
                  </p>
                  <span className="twocards-paras month">/6 Months</span>
                </div>

                <button
                  className="cardone-btn"
                  onClick={() =>
                    checkoutHandler({
                      name: `${myProfile.firstName} ${myProfile.secondName}`,
                      amount: 1999,
                      paymentType: "yearly",
                    })
                  }
                >
                  Get Started Now
                </button>

                <div className="twocards-paras-div">
                  <p className="twocards-paras ">
                    <TiTick className="cardtwo-rightticks" />
                    <span className="payment-paras">
                      Subscribe for 6 months get 6 months for free
                    </span>
                  </p>
                  <p className="twocards-paras ">
                    {" "}
                    <TiTick className="cardtwo-rightticks" />
                    <span className="payment-paras">
                      View upto 600 profiles
                    </span>
                  </p>
                  <p className="twocards-paras ">
                    {" "}
                    <TiTick className="cardtwo-rightticks" />
                    <span className="payment-paras">
                      send Unlimited Messages
                    </span>
                  </p>
                  <p className="twocards-paras ">
                    {" "}
                    <TiTick className="cardtwo-rightticks" />
                    <span className="payment-paras">Unlimited Sharing</span>
                  </p>
                  <p className="twocards-paras ">
                    {" "}
                    <TiTick className="cardtwo-rightticks" />
                    <span className="payment-paras">
                      Upload graphics & video in up to 4k
                    </span>
                  </p>
                  <p className="twocards-paras ">
                    {" "}
                    <TiTick className="cardtwo-rightticks" />
                    <span className="payment-paras">Unlimited Projects</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default PaymentIntegration;
