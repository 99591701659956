import React, { useState, useEffect, useRef } from "react";
import apiUrl from "../../../../../config";
import axios from "axios";
import { MyProfileContext } from "../../../../../context/ProfileProvider";
import ProfileProgress from "./ProfileProgress";

export const PhotoAlbum = () => {
  const [clickedEdit, setClickedEdit] = useState(false);
  const fileInputRef = useRef(null);
  const { myProfile, userProfile } = React.useContext(MyProfileContext);
  const userProfileId = localStorage.getItem("profileId");
  const [imageError, setImageError] = useState("");

  const [age, setAge] = useState(0);
  const [errors, setErrors] = useState("");
  const [details, setDetails] = useState({
    image: "",
  });

  // const handleFileUpload = () => {
  //   const fileInput = fileInputRef.current;
  //   const file = fileInput.files[0];
  //   const allowedExtensions = ["jpeg", "jpg", "png", "svg"];
  //   const fileExtension = file.name.split(".").pop().toLowerCase();
  //   if (allowedExtensions.includes(fileExtension)) {
  //     setDetails((prevDetails) => ({ ...prevDetails, image: file }));
  //     setImageError("");
  //   } else {
  //     setDetails((prevDetails) => ({ ...prevDetails, image: null }));
  //     setImageError("Select a valid image file (jpeg, jpg, png, or svg)");
  //   }
  // };
  const handleFileUpload = () => {
    const fileInput = fileInputRef.current;
    const file = fileInput.files[0];
    const allowedExtensions = ["jpeg", "jpg", "png", "svg"];
    const fileExtension = file.name.split('.').pop().toLowerCase();
  
    if (myProfile.image.length >= 3) {
      setImageError("You've reached the limit of three photos. Please delete one before uploading a new one.")
    } else if (allowedExtensions.includes(fileExtension)) {
  
      setDetails((prevDetails) => ({ ...prevDetails, image: file }));
      setImageError("");
    } else {
      setDetails((prevDetails) => ({ ...prevDetails, image: null }));
      setImageError("Select a valid image file (jpeg, jpg, png, or svg)");
    }
  };
  

  const Updatedetali = async () => {
    try {
      if (!details.image) {
        setImageError("Select Image");
        return;
      }
      const form = new FormData();
      form.append("image", details.image);
      const res = await axios.patch(
        apiUrl + `v1/profile/updateProfile/${userProfileId}`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (res.status === 200) {
        userProfile();
        setDetails({ image: "" });
        setImageError("");
      }
    } catch (err) {
      console.error(err);
      setErrors(err.response.data.message);
    }
  };

  const commonFunction = () => {
    if (!details.image) {
      setImageError("Select Image");
      return;
    }

    setClickedEdit(false);
    Updatedetali();
  };

  const CancelFunction = () => {
    setDetails({ image: "" });
    setImageError("");
    setClickedEdit(false);
  };

  const handleRemoveImage = async (imageUrlToRemove) => {
    try {
      const updatedImages = myProfile.image.filter(
        (image) => image !== imageUrlToRemove
      );
      const res = await axios.patch(
        apiUrl + `v1/profile/updateProfile/${userProfileId}`,
        {
          image: updatedImages,
        }
      );
      if (res.status === 200) {
        userProfile();
      }
    } catch (error) {
      console.error("Error removing image:", error);
    }
  };

  const setAsProfile = async (index) => {
    try {
      const updatedImages = [...myProfile.image];
      const selectedImage = updatedImages.splice(index, 1)[0];
      updatedImages.unshift(selectedImage);

      const res = await axios.patch(
        apiUrl + `v1/profile/updateProfile/${userProfileId}`,
        { image: updatedImages }
      );
      if (res.status === 200) {
        userProfile();
      }
    } catch (error) {
      console.error("Error setting image as profile:", error);
     
    }
  };

  useEffect(() => {
    if (myProfile?.dateOfBirth) {
      const birthDate = new Date(myProfile.dateOfBirth);
      const currentDate = new Date();
      const ageDiff = currentDate.getFullYear() - birthDate.getFullYear();
      setAge(ageDiff);
    }
  }, [myProfile?.dateOfBirth, myProfile?.image, myProfile]);

  return (
    <>
      <div className="basic-card">
        <div className="profile-progress">
          <ProfileProgress />
        </div>
        <div className="photo-album-card">
          <div className="basic-details-edit-button">
            <h4 className="basic-details-heading">
              <b>Update Photo</b>
            </h4>
            <div className="edit-name-button">
              {clickedEdit ? (
                <>
                  <p
                    className="edit-name"
                    onClick={CancelFunction}
                    style={{ marginRight: "25px" }}
                  >
                    Cancel
                  </p>
                  <p className="edit-name" onClick={() => commonFunction()}>
                    Update
                  </p>
                </>
              ) : (
                <span
                  className="edit-name"
                  onClick={() => setClickedEdit((preState) => !preState)}
                >
                  Edit
                  <img className="edit-button" src="/images/edit.png" alt="" />
                </span>
              )}
            </div>
          </div>
          <div className="image-details-img">
            {clickedEdit ? (
              <div>
                <label htmlFor="fileInput">
                  {details.image ? (
                    <img
                      src={URL.createObjectURL(details.image)}
                      alt=""
                      style={{ width: "150px", height: "150px" }}
                    />
                  ) : (
                    <>
                      <img
                        src="../Images/verification.svg"
                        alt="no img"
                        style={{ cursor: "pointer" }}
                      />
                    </>
                  )}
                </label>
                {imageError && <p style={{ color: "red" }}>{imageError}</p>}
                <input
                  id="fileInput"
                  type="file"
                  accept=".jpeg, .jpg, .png, .svg"
                  ref={fileInputRef}
                  onChange={handleFileUpload}
                  style={{ display: "none" }}
                />
              </div>
            ) : (
              <>
                {Array.isArray(myProfile?.image) &&
                  myProfile?.image.length > 0 &&
                  myProfile.image.map((image, index) => (
                    <div className="" key={index}>
                      <div className="photos-div">
                        <img
                          className=""
                          src={image}
                          alt={`image-${index}`}
                          style={{
                            width: "180px",
                            height: "180px",
                            margin: "6px",
                          }}
                        />
                        <button
                          className="set-profile-button"
                          onClick={() => setAsProfile(index)}
                        >
                          Set as Profile
                        </button>
                        <div className="remove-btn-comtaner">
                          <button
                            className="remove-button"
                            onClick={() => handleRemoveImage(image)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
