import React, { useState, useEffect } from "react";
import './errorPopup.css'

const SuccessPopUp = ({ message, onClose }) => {
    const [canClose, setCanClose] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setCanClose(true);
            onClose();
        }, 2000);
        return () => {
            clearTimeout(timer);
        };
    }, [onClose]);

    const handleManualClose = () => {
        setCanClose(true);
        onClose();
    };

    return (
        <div className="Success-popup-container">
            <div className="Success-popup">
                <div className="Success-content">
                    <div className="Success-popup-img">
                        <img src="../Images/error-popup-img.png" />
                        <p className="Success-msg"style={{color:"green"}}>{message}</p>
                    </div>
                    {!canClose && (
                        <button className="manual-close-button" onClick={handleManualClose}>
                            Done
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SuccessPopUp;
