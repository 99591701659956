import React from 'react'
import { useSwiper } from 'swiper/react'
import SwiperPagination from './SwiperPagination';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';


const SwiperNavButton = () => {

  const swiper = useSwiper()
  const slidePrev = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };
  const slideNext = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };
  return (
    <>
      <div className="swiper-nav-btns d-flex justify-content-center " >
        <button className='left-nav-swiper-button' onClick={slidePrev}><ChevronLeft size={25} /></button>
        <SwiperPagination />
        <button className='right-nav-swiper-button' onClick={slideNext}><ChevronRight size={25} /></button>
      </div>
      <br/>

    </>

  )
}

export default SwiperNavButton