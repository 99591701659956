import React, { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import './Admin.css'

export const Admin = () => {

    let navigate = useNavigate()
    let [selectedItem, setSelectedItem] = useState('')

    const clickData = (nav) => {
        navigate(`${nav}`)
        setSelectedItem(nav)
    }

    const listItemStyle = (item) => {
        return {
            backgroundColor: (selectedItem === item) ? "#FFF7E6" : "white"
        };
    };

    const AdminLogout =()=>{
        localStorage.removeItem("adminId")
        navigate("/adminLogin/maangalyaam")
    }

    return (
        <>
            <div className='main-admin-container'>
                <div className='admin-container'>
                    <ul className="list-group list-admin-container">
                        <li className='list-user'>Menu</li>
                        <li className='list-user'><img src='/images/AdminImg/home.png' alt='logo' />   Dashboard</li>
                        <li className='list-user' style={listItemStyle('users/all')} onClick={() => clickData('users/all')}>  <img src='/images/AdminImg/chat.png' alt='logo' />  Users</li>
                        <li className='list-user' style={listItemStyle('users/feedback')} onClick={() => clickData('users/feedback')}><img src='/images/AdminImg/membership.png' alt='logo' />  Reports</li>
                        <li className='list-user'><img src='/images/AdminImg/membership.png' alt='logo' />  Membership</li>
                        <li className='list-user'><img src='/images/AdminImg/notify.png' alt='logo' />   Notification</li>
                        <li className="list-user"><img src='/images/AdminImg/settings.png' alt='logo' />  Settings</li>
                    </ul>
                    <div className='line-admin-main-container'>
                    <p className='line-admin'> Profile</p>
                    <p><img src='/images/AdminImg/proimage.png' alt='profile' className='' /> Admin</p>
                    <button className='admin-btn ' onClick={AdminLogout}><img src='/images/AdminImg/logout.png'className='pe-2' alt='btn' />Logout</button>
                    </div>
                    
                </div>
                <div className='admin-outlet'>
                    <Outlet />
                </div>
            </div>
        </>

    )
}
