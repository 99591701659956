import axios from "axios";
import React, { useState } from "react";
import apiUrl from "../../../../../config";
import { FaEye, FaLongArrowAltLeft, FaRegEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export const Password = () => {
  const navigate = useNavigate();
  const userProfileId = localStorage.getItem("profileId");
  const [errors, setErrors] = useState("");
  const [isFieldFocused, setIsFieldFocused] = useState(false);
  const [passwords, setPasswords] = useState({
    oldPassword: "",
    newPassword: "",
  });

  const back = () => {
    navigate("/settings");
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswords((ps) => ({ ...ps, [name]: value }));
  };

  const togglePasswordVisibility = () => {
    setIsFieldFocused(!isFieldFocused);
  };


  const changePassword = async () => {
    try {

      if (passwords.oldPassword === "" || passwords.newPassword === "") {
        setErrors("Please fill in both current and new password fields.");
        return;
      } else {
        const res = await axios.patch(
          apiUrl + `v1/profile/updatePassword/${userProfileId}`,
          passwords
        );
        if (res.data === true) {
          setErrors("");
        }
      }
    } catch (err) {
      console.log(err);
      setErrors(err.response.data.message);
    }
  };

  return (
    <>
      <div className="Email-Phone-Number-container">
        <button
          onClick={() => back()}
          className="Email-Phone-Number-container-icon"
        >
          <FaLongArrowAltLeft />
        </button>
        <h4 className="mobive-view-btns">Change Password</h4>
      </div>
      <div className="pass-container ">
        <div>
          <h2 className="head-name settings-heading-name">
            Update Your Password
          </h2>
          <div className="para-pass settings-sub-heading-name">
            Keep things secure! Change your password easily to add an extra
            layer of protection to your account on{" "}
            <span className="head-pass">MAANGALYAAM</span>. It's quick, simple,
            and smart.
          </div>
        </div>
        <form onSubmit={onFormSubmit}>
          <div className="left-content ">
            <div className="right-content">
              <label className="lab-pass">Current Password</label>
              <input
                className="inp-pass form-control"
                type={isFieldFocused ? "text" : "password"}
                placeholder="Enter Here"
                name="oldPassword"
                onChange={handleChange}
              />
              {isFieldFocused ? (
                <FaRegEyeSlash
                  onClick={togglePasswordVisibility}
                  className="current-pwd-eye-icon"
                />
              ) : (
                <FaEye
                  onClick={togglePasswordVisibility}
                  className="current-pwd-eye-icon"
                />
              )}
            </div>
            <div className="right-content">
              <label className="lab-pass">New Password</label>
              <input
                className="inp-pass form-control"
                type={isFieldFocused ? "text" : "password"}
                placeholder="Enter Here"
                name="newPassword"
                onChange={handleChange}
              />
              {isFieldFocused ? (
                <FaRegEyeSlash
                  onClick={togglePasswordVisibility}
                  className="current-pwd-eye-icon"
                />
              ) : (
                <FaEye
                  onClick={togglePasswordVisibility}
                  className="current-pwd-eye-icon"
                />
              )}
            </div>
            <div className="btn-pass-container">
              <button className="btn-pass" onClick={changePassword}>
                Save
              </button>
            </div>
          </div>
        </form>
        {errors && <p style={{ color: "red" }}>{errors}</p>}
      </div>
    </>
  );
};
