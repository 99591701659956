import React, { useContext, useState } from "react";
import "./css/loginpage.css";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import apiUrl from "../../config";
import axios from "axios";
import { FaRegEyeSlash, FaEye } from "react-icons/fa";

const LoginPage = ({ handleLogin }) => {
  const [flag, setFlag] = useState("phone");

  const [isFieldFocused, setIsFieldFocused] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [loginDetails, setloginDetails] = useState({
    emailOrMobile: "",
    password: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (flag === "email" && name === "emailOrMobile") {
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      setEmailError(isValidEmail ? "" : "Invalid email address");
    }
    setloginDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  const togglePasswordVisibility = () => {
    setIsFieldFocused(!isFieldFocused);
  };

  const Login = async () => {
    try {
      if (emailError) {
        return;
      }
      const response = await axios.post(apiUrl + "v1/profile/login", loginDetails);
      localStorage.setItem("profileId", parseInt(response.data.userProfile));
      await handleLogin();
      if (response.data.message === "Login successful") {
        navigate("/", { replace: true });
      }
    } catch (err) {
      console.log(err);
      setError(err.response?.data?.message || "An error occurred during login.");
    }
  };


  const handlePage = (option) => {
    setFlag(option);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="container">
          <div className="login-container">
            <div className="data-container">
              <div className="login-form">
                <div className="login-logo">
                  <img src="../images/logo.png" alt="" />
                </div>
                <div className="login-content">
                  <h3 className="login-header">Login</h3>
                  <p className="login-text">
                    Welcome back! Please enter your login details to access your
                    account.
                  </p>
                </div>
                <div className="toggle-buttons">
                  <button
                    className="phone-button"
                    style={{
                      backgroundColor: flag === "phone" ? "white" : "#fff7e6",
                    }}
                    onClick={() => handlePage("phone")}
                  >
                    Mobile Number
                  </button>
                  <button
                    className="email-button"
                    style={{
                      backgroundColor: flag === "email" ? "white" : "#fff7e6",
                    }}
                    onClick={() => handlePage("email")}
                  >
                    Email
                  </button>
                </div>
                {flag === "phone" ? (
                  <div className="login-page">
                    <form className="login-page-form">
                      <div className="login-page-input-label">
                        <label>Mobile Number</label>
                        <input
                          className="form-control"
                          maxLength={10}
                          minLength={10}
                          placeholder="Enter your Mobile number"
                          name="emailOrMobile"
                          onInput={(e) => {
                            const numericValue = e.target.value.replace(
                              /\D/g,
                              ""
                            );
                            handleChange({
                              target: {
                                name: "emailOrMobile",
                                value: numericValue,
                              },
                            });
                          }}
                          onKeyDown={(e) => {
                            if (
                              !(e.key === "Backspace" || e.key === "Delete") &&
                              !/[\d]/.test(e.key)
                            ) {
                              e.preventDefault();
                            }
                          }}
                          type="text"
                        />
                      </div>
                      <div className="login-page-input-label">
                        <label>Password</label>
                        <div className="password-input-container">
                          <input
                            type={isFieldFocused ? "text" : "password"}
                            placeholder="Enter here"
                            name="password"
                            value={loginDetails.password}
                            onChange={handleChange}
                          />
                          {isFieldFocused ? (
                            <FaRegEyeSlash
                              onClick={togglePasswordVisibility}
                              className="eye-icon"
                            />
                          ) : (
                            <FaEye
                              onClick={togglePasswordVisibility}
                              className="eye-icon"
                            />
                          )}
                        </div>
                        {error && <p style={{ color: "red" }}>{error}</p>}
                      </div>
                    </form>
                    <div>
                      <Link
                        to="/forgotpassword"
                        className="create-one-container"
                      >
                        Forgot Password
                      </Link>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="login-page">
                      <form onSubmit={onSubmit} className="login-page-form">
                        <div className="login-page-input-label">
                          <label>Email</label>
                          <input
                            type="email"
                            placeholder="Enter your mail"
                            onChange={handleChange}
                            name="emailOrMobile"
                          />
                          {emailError && (
                            <p style={{ color: "red" }}>{emailError}</p>
                          )}
                        </div>
                        <div className="login-page-input-label">
                          <label>Password</label>
                          <div className="password-input-container">
                            <input
                              type={isFieldFocused ? "text" : "password"}
                              placeholder="Enter here"
                              name="password"
                              value={loginDetails.password}
                              onChange={handleChange}
                            />
                            {isFieldFocused ? (
                              <FaRegEyeSlash
                                onClick={togglePasswordVisibility}
                                className="eye-icon"
                              />
                            ) : (
                              <FaEye
                                onClick={togglePasswordVisibility}
                                className="eye-icon"
                              />
                            )}
                          </div>
                          {error && <p style={{ color: "red" }}>{error}</p>}
                        </div>
                      </form>
                      <div className="">
                        <Link to="/forgotpassword" className="create-one-container">
                          Forgot Password
                        </Link>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="login-button">
                {" "}
                <button onClick={Login}>Login</button>
              </div>
              <div className="registration-page">
                <p>Don't Have an Account yet ? </p>
                <Link to="/registration" className="create-one-container">
                  {" "}
                  Create one
                </Link>
              </div>
            </div>
            <div className="login-image">
              <img src="../images/image1.png" alt="" />
              <input type="text" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
