import { useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import "../../../../Home/css/Edit.css";
import { PhotoAlbum } from "./PhotoAlbum";
import { PersonalDetails } from "./PersonalDetails";
import { ProfessionalDetails } from "./ProfessionalDetails";
import { BasicDetails } from "./BasicDetails";
import Navbar from "../../DashBoard/NavBar";
import BottomNavbar from "../../../../../components/BottomNavbar";
import Footer from "../../../../../components/Footer";

export const MyprofileNav = () => {
  let [selectedItem, setSelectedItem] = useState("basicdetails");
  const clickData = (nav) => {
    navigate(`${nav}`);
    setSelectedItem(nav);
  };

  const listItemStyle = (item) => {
    return {
      backgroundColor: selectedItem === item ? "#FFF7E6" : "white",
    };
  };

  let navigate = useNavigate();
  
  return (
    <>
      <div className="sticky-top ">
        <Navbar />
      </div>
      <div className="container-fluid">
        <div className="container">
          <div className="profile-edit-main-card">
            <div className="card-profilenav">
              <div className="profilecard-head">
                <p className="my-profile sections-Headings">My Profile</p>
              </div>
              <div>
                <ul className=" list-profile">
                  <li
                    className="list sections-Headings"
                    style={listItemStyle("basicdetails")}
                    onClick={() => clickData("basicdetails")}
                  >
                    Basic Details
                  </li>
                  <li
                    className="list sections-Headings"
                    style={listItemStyle("photoalbum")}
                    onClick={() => clickData("photoalbum")}
                  >
                    Photo Album
                  </li>
                  <li
                    className="list sections-Headings"
                    style={listItemStyle("personaldetails")}
                    onClick={() => clickData("personaldetails")}
                  >
                    Personal Details
                  </li>
                  <li
                    className="list sections-Headings"
                    style={listItemStyle("professionaldetails")}
                    onClick={() => clickData("professionaldetails")}
                  >
                    Professional Details
                  </li>
                </ul>
              </div>
            </div>
            <div className="outlet-content">
              <Outlet />
            </div>
          </div>
          <div className="edit-container-in-mobile-view">
            <BasicDetails />
            <PhotoAlbum />
            <PersonalDetails />
            <ProfessionalDetails />
          </div>
        </div>
      </div>
      <div className="mt-5">
        <Footer />
      </div>
      <div className=" sticky-bottom">
        <BottomNavbar />
      </div>
    </>
  );
};
