import React from "react";
import './footer.css'
import { Link, useNavigate } from "react-router-dom";
import logo from '.././logo/maangalya.png'
export default function Footer() {
  const navigate = useNavigate()
  const link = () => {
    navigate("/")
  }
  return (
    <div className="container-fluid footer">
      <div className="container">
        <div className="footer-container  ">
          <div className="footer-main row">
            <div className="foooter-logo-div  col-md-6 col-lg-3 col-sm-12 ">
              <img src={logo} height="50px" width="200px" />
              <p className="footer-logo-para">
                Welcome to Maangalyaam, where we connect hearts with love and commitment. 
                Your journey to finding a life partner starts here. 
                {/* Explore our platform for a seamless and enjoyable experience in discovering meaningful connections. */}
              </p>
            </div>
            <div className="footer-company-div  col-md-6 col-lg-3 col-sm-12 ">
              <ul className="footer-ul">
                <li className="company">Help & Support</li>
                <li className="list-item" onClick={link}>24x7 Live help</li>
                <li className="list-item" onClick={link}></li>
                <li className="list-item" ><Link to="/" className="list-item" style={{textDecoration:"none"} }>Feedback</Link></li>
                <li className="list-item" > <Link to="/" className="list-item" style={{textDecoration:"none"} }>FAQs</Link></li>
              </ul>
            </div>
            <div className="footer-help-div col-md-6 col-lg-3 col-sm-12 ">
              <ul className="footer-ul">
                <li className="footer-help">Information</li>
                <li className="list-item">About Us</li>
                <li className="list-item">Success stories</li>
                <li className="list-item">Careers</li>
                <li className="list-item">Affiliates</li>
                <li className="list-item">Advertise with us</li>
                <li className="list-item"><Link to="/" className="footer-links-links list-item">Terms & Conditions</Link></li>
                <li className="list-item"><Link to="/" className="footer-links-links list-item">Privacy Policy</Link></li>
              </ul>
            </div>
            <div className="footer-help-div col-md-6 col-lg-3 col-sm-12 ">
              <ul className="footer-ul">
                <li className="footer-help">Related Matrimony Services(Futures)</li>
                <li className="list-item">HappyMarriages.com</li>
                <li className="list-item">Safe Matrimony</li>
                <li className="list-item">Matrimonial Tools</li>
                <li className="list-item">Matrimonial Sites</li>
                <li className="list-item">Telugu Matrimonials</li>
                <li className="list-item">Matrimonial Websites</li>
                <li className="list-item"> Telugu Matrimony Branches</li>
                <li className="list-item">Function Halls</li>
                <li className="list-item">Banquet Halls</li>
                <li className="list-item">Muhurtham Dates</li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="footer-line" />
        <div className="footer-terms ">
          <p className="para-copyright">
            © Copyright 2024, All Rights Reserved by maangalyaam |
            <span className="copyrights">
              Designed & Developed by Abilioit Solutions{" "}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}