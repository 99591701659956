import React, { useEffect, useRef, useState } from 'react';
import { useSwiper } from 'swiper/react';

const SwiperPagination = () => {

  const swiperRef = useRef(null);
  const swiper = useSwiper(swiperRef);
  const [activeIndex, setActiveIndex] = useState(0);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = screenWidth <= 767; // Define your own condition for mobile
  const ipad = screenWidth >= 768 && screenWidth <= 1219

  useEffect(() => {
    const handleSlideChange = () => {
      setActiveIndex(swiper.realIndex); // Update activeIndex when the slide changes
    };
    if (swiper) {
      swiper.on('slideChange', handleSlideChange); // Listen for slide change events
    }
    return () => {
      if (swiper) {
        swiper.off('slideChange', handleSlideChange); // Remove the event listener when the component unmounts
      }
    };
  }, [swiper]);

  const goToSlide = (index) => {
    if (swiper) {
      swiper.slideTo(index); // Go to the specified slide
    }
  };

  const renderPaginationButtons = () => {
    // const numSlides = isMobile ? (swiper.slides.length) : (swiper.slides.length) - 3;
    let numSlides;
    if (isMobile) {
      numSlides = (swiper.slides.length)
    } else if (ipad) {
      numSlides = (swiper.slides.length) - 1
    } else {
      numSlides = (swiper.slides.length) - 1
    }

    return Array.from({ length: numSlides }, (_, index) => (
      <button
        key={index}
        onClick={() => goToSlide(index)}
        className={index === activeIndex ? 'progressbar-nav-item active' : 'progressbar progressbar-nav-item'}
      >
      </button>
    ));
  };

  return (
    <div className="custom-pagination">
      {renderPaginationButtons()}
    </div>
  );
};

export default SwiperPagination;