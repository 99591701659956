import React, { useEffect, useState } from "react";
import apiUrl from "../../../../../config";
import axios from "axios";
import { MyProfileContext } from "../../../../../context/ProfileProvider";
import ProfileProgress from "./ProfileProgress";
import ErrorPopup from "../../../../../popups/ErrorPopup";
import SuccessPopUp from "../../../../../popups/SuccessPopUp";
import DetailsData from "../../../../../data/State.json";

export const ProfessionalDetails = () => {
  const [clickedEdit, setClickedEdit] = useState(false);
  const [clickUpdate, setClickUpdate] = useState(false);
  const [age, setAge] = useState(0);
  const { myProfile, userProfile } = React.useContext(MyProfileContext)
  const [errors, setErrors] = useState("")
  const [showError, setShowError] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [success, setSuccess] = useState("")

  const [updateProfile, setUpdateProfile] = useState({
    higherEducation: myProfile.higherEducation,
    courseName: myProfile.courseName,
    collegeName: myProfile.collegeName,
    occupation: myProfile.occupation,
    annualIncome: myProfile.annualIncome,
    aboutMe: myProfile.aboutMe,
  });

  const userProfileId = localStorage.getItem("profileId");

  const Updatedetali = async () => {
    try {
      const res = await axios.patch(apiUrl + `v1/profile/updateProfile/${userProfileId}`, updateProfile)
      if (res.status == 200) {
        userProfile();
        setSuccess("Profile Updated successfully")
        handleShowSuccess()  
      }
    } catch (err) {
      console.log(err);
      setErrors(err.response.data.message)
      handleShowError()
    }
  };

  const handleShowError = () => {
    setShowError(true);
  };

  const handleCloseError = () => {
    setShowError(false);
  };

  const handleShowSuccess = () => {
    setshowSuccess(true);
  };

  const handleCloseSuucess = () => {
    setshowSuccess(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateProfile((prevProfile) => ({ ...prevProfile, [name]: value }));
    if (name === "courseName" || name === "collegeName") {
      const trimmedValue = value.replace(/^\s+/g, '');
      const nonNumericValue = trimmedValue.replace(/\d/g, '');
      setUpdateProfile((prevProfile) => ({ ...prevProfile, [name]: nonNumericValue }));
    }
  };


  const commonFunction = () => {
    setClickedEdit(false);
    Updatedetali();
  };

  useEffect(() => {
    if (myProfile?.dateOfBirth) {
      const birthDate = new Date(myProfile.dateOfBirth);
      const currentDate = new Date();
      const ageDiff = currentDate.getFullYear() - birthDate.getFullYear();
      setAge(ageDiff);
    }
  }, [myProfile?.dateOfBirth]);
  return (
    <div className="basic-card">
      <div className="profile-progress">
        <ProfileProgress />
      </div>
      <div>
        <div className="basic-details-edit">
          <div className="basic-details-edit-button">
            <h4 className="basic-details-heading">
              <b>Professional Details</b>
            </h4>
            <div className="edit-name-button">
              {clickedEdit ? (
                <p className="edit-name" onClick={() => commonFunction()}>
                  Update
                </p>
              ) : (
                <span
                  className="edit-name"
                  onClick={() => setClickedEdit((preState) => !preState)}
                >
                  Edit
                  <img className="edit-button" src="/images/edit.png" alt="" />
                </span>
              )}
            </div>
          </div>

          {clickedEdit && (
            <>
              <div className="input-details">
                <div className="first-label">
                  <div className="label-input">
                    <div className="label-edit">
                      <div className="label-input-fields">
                        {" "}
                        <label htmlFor="" className="label-content">
                          Highest Education
                        </label>
                        <select
                          className="input-edit-data"
                          type="text"
                          placeholder="Highest Education"
                          value={updateProfile.higherEducation}
                          onChange={handleInputChange}
                          name="higherEducation"
                        >
                          <option>Select</option>
                          {DetailsData.HighestEducation.map((education) => {
                            return (
                              <option key={education} value={education}>
                                {education}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="label-input-fields d-flex">
                        {" "}
                        <label htmlFor="" className="label-content">
                          Course Name
                        </label>
                        <input
                          className="input-edit-data"
                          type="text"
                          placeholder="Course Name"
                          value={updateProfile.courseName}
                          onChange={handleInputChange}
                          name="courseName"
                        />
                      </div>
                      <div className="label-input-fields">
                        <label htmlFor="" className="label-content">
                          College Name
                        </label>
                        <input
                          className="input-edit-data"
                          type="text"
                          placeholder="College Name"
                          value={updateProfile.collegeName}
                          onChange={handleInputChange}
                          name="collegeName"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="label-input-change">
                    <div className="label-edit">
                      <div className="label-input-fields">
                        {" "}
                        <label htmlFor="" className="label-content">
                          Occupation
                        </label>
                        <select
                          className="input-edit-data"
                          type="text"
                          placeholder=" Occupation"
                          value={updateProfile.occupation}
                          onChange={handleInputChange}
                          name="occupation"
                        >
                          <option value="">Select</option>
                          {DetailsData.Occupation.map((occupation) => (
                            <option key={occupation} value={occupation}>
                              {occupation}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="label-input-fields">
                        <label htmlFor="" className="label-content">
                          Annual Income
                        </label>
                        <select
                          className="input-edit-data"
                          type="text"
                          name="annualIncome"
                          placeholder="Annual Income"
                          value={updateProfile.annualIncome}
                          onChange={handleInputChange}
                         
                        >
                          <option value="">Select</option>
                          {DetailsData.Income.map((income) => (
                            <option key={income} value={income}>
                              {income}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {!clickedEdit && (
            <>
              <div className="input-details">
                <div className="first-label">
                  <div className="label-input">
                    <div className="label">
                      <div className="label-input-fields">
                        {" "}
                        <label htmlFor="" className="label-content">
                          Highest Education
                        </label>{" "}
                        <p className="input-data-basic-details">
                          {myProfile?.higherEducation}
                        </p>
                      </div>
                      <div className="label-input-fields">
                        {" "}
                        <label htmlFor="" className="label-content">
                          Course Name
                        </label>{" "}
                        <p className="input-data-basic-details">
                          {myProfile?.courseName}
                        </p>
                      </div>
                      <div className="label-input-fields">
                        <label htmlFor="" className="label-content">
                          College Name
                        </label>
                        <p className="input-data-basic-details">
                          {myProfile?.collegeName}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="label-input-change-data">
                    <div className="label">
                      <div className="label-input-fields">
                        {" "}
                        <label htmlFor="" className="label-content">
                          Occupation
                        </label>{" "}
                        <p className="input-data-basic-details">
                          {myProfile?.occupation}
                        </p>
                      </div>
                      <div className="label-input-fields">
                        {" "}
                        <label htmlFor="" className="label-content">
                          Annual Income
                        </label>
                        <p className="input-data-basic-details">
                          {myProfile?.annualIncome}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {showSuccess && <SuccessPopUp message={success} onClose={handleCloseSuucess} />}
      {showError && <ErrorPopup message={errors} onClose={handleCloseError} />}

    </div>
  );
};
