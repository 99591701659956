import React, { useEffect, useState } from "react";
import "../../../../../../src/pages/Home/css/Edit.css";
import { MyProfileContext } from "../../../../../context/ProfileProvider";
import { Link } from "react-router-dom";
import apiUrl from "../../../../../config";
import axios from "axios";

const ProfileProgress = () => {
  const { myProfile, userProfile } = React.useContext(MyProfileContext);
  const [age, setAge] = useState(0);
  const [errors, setErrors] = useState("");
  const [clickedEdit, setClickedEdit] = useState(false);
  const [clickUpdate, setClickUpdate] = useState(false);

  const userProfileId = localStorage.getItem("profileId");
  const [editedProfile, setEditedProfile] = useState({
    profile: myProfile.profile,
    firstName: myProfile.firstName,
    secondName: myProfile.secondName,
    gender: myProfile.gender,
    mobile: myProfile.mobile,
    email: myProfile.email,
    country: myProfile.country,
    motherTounge: myProfile.motherTounge,
    city: myProfile.city,
    state: myProfile.state,
    religion: myProfile.religion,
    caste: myProfile.caste,
    subCaste: myProfile.subCaste,
    aboutMe: myProfile.aboutMe,
  });

  const [profileCompletion, setProfileCompletion] = useState(0);

  const calculateProfileCompletion = () => {
    const selectedFields = [
      "profile",
      "gender",
      "mobile",
      "email",
      "dateOfBirth",
      "firstName",
      "secondName",
      "motherTounge",
      "country",
      "state",
      "city",
      "religion",
      "caste",
      "subCaste",
      "maritalStatus",
      "familyType",
      "familyStatus",
      "disabilities",
      "higherEducation",
      "courseName",
      "collegeName",
      "occupation",
      "annualIncome",
      "verificationProof",
      "image",
    ];
    const filledFields = selectedFields.filter(
      (field) =>
        myProfile[field] !== null &&
        myProfile[field] !== undefined &&
        myProfile[field] !== ""
    ).length;
    const totalFields = selectedFields.length;
    const completionPercentage = (filledFields / totalFields) * 100;
    setProfileCompletion(completionPercentage.toFixed(2));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedProfile((prevProfile) => ({ ...prevProfile, [name]: value }));
  };

  const Updatedetali = async () => {
    try {
      const res = await axios.patch(
        apiUrl + `v1/profile/updateProfile/${userProfileId}`,
        editedProfile
      );
      if (res.status == 200) {
        userProfile();
      }
    } catch (err) {
      console.log(err);
      setErrors(err.response.data.message);
    }
  };
  const commonUpdate = () => {
    setClickUpdate();
    Updatedetali();
  };
  const commonFunction = () => {
    setClickedEdit();
    Updatedetali();
  };

  useEffect(() => {
    if (myProfile?.dateOfBirth) {
      const birthDate = new Date(myProfile.dateOfBirth);
      const currentDate = new Date();
      const ageDiff = currentDate.getFullYear() - birthDate.getFullYear();
      setAge(ageDiff);
    }
    userProfile();
    calculateProfileCompletion();
  }, [myProfile?.dateOfBirth]);
  return (
    <div className="">
      <div className="score-card mb-2">
        <div>
          <button className="profile-completion-score">
            {profileCompletion}%
          </button>
        </div>
        <div className="profile-completion-data">
          <h5 className="profile-completion-heading">
            Profile Progress: {profileCompletion}% Complete
          </h5>
          <p className="profile-completion-para">
            Take it to 100% for More Features and an Elevated Platform
            Experience!
          </p>
        </div>
      </div>
      <div className="profile-details ">
        <div className="image-details-button">
          <div className="image-details">
            <div className="image-details-img">
              {myProfile && myProfile.image && myProfile.image[0] && (
                <img
                  className=""
                  src={myProfile.image[0]}
                  alt="imagenotloaded"
                  style={{ width: "180px", height: "180px" }}
                />
              )}
            </div>
            <div className="profile-data">
              <div className="profile-data-primum">
                <h4 className="profile-Name">
                  <b>
                    {myProfile?.firstName} {myProfile?.secondName}
                  </b>
                </h4>
                <div className="try-mobile-tab">
                  <Link to="/payments">
                    {" "}
                    <button className="premium-button">Try Premium</button>
                  </Link>
                </div>
              </div>
              <div className="profile-details-data">
                <span className="basic-details-p">{age} years</span>
                <span className="basic-details-p">{myProfile?.height}</span>
                <span className="basic-details-p">{myProfile?.religion}</span>
                <span className="basic-details-p">{myProfile?.caste}</span>
                <span className="basic-details-p">{myProfile?.motherTounge} </span>
                <span className="basic-details-p"> {myProfile?.higherEducation} </span>
                <span className="basic-details-p">{myProfile?.occupation}</span>
                <span className="basic-details-p">{myProfile?.city}</span>
                <span className="basic-details-p">{myProfile?.state}</span>
                <span className="basic-details-p">{myProfile?.country}</span>
              </div>
              <div>
                <p className="basic-details-p">
                  {myProfile?.mobile}
                  <span className="verified"> Verified</span>
                </p>
                <p className="basic-details-p">
                  {myProfile?.email} <span className="verified"> Verified</span>
                </p>
              </div>
            </div>
          </div>
          <div className="premium-button-card">
            <Link to="/payments">
              {" "}
              <button className="premium-button">Try Premium</button>
            </Link>
          </div>
        </div>
        <div className="about-card">
          <div className="about-edit">
            <h4 className="about-me-heading">
              <b>About Me</b>
            </h4>
            <div className="edit-name-button">
              {clickUpdate ? (
                <p className="edit-name" onClick={() => commonUpdate()}>
                  Update
                </p>
              ) : (
                <span
                  className="edit-name"
                  onClick={() => setClickUpdate((preState) => !preState)}
                >
                  Edit
                  <img className="edit-button" src="/images/edit.png" alt="" />
                </span>
              )}
            </div>
          </div>
          {clickUpdate ? (
            <div>
              <textarea
                className="about-edit-data"
                placeholder="Enter here..."
                name="aboutMe"
                onChange={handleInputChange}
                value={editedProfile?.aboutMe}
              />
              {errors && <p style={{ color: "red" }}>{errors}</p>}
            </div>
          ) : (
            <p className="about-para">{myProfile?.aboutMe}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileProgress;
