import React from 'react'
import { MyProfileContext } from '../../../../context/ProfileProvider';
import { useLocation } from 'react-router-dom';

const ViewAll = () => {

  const { myProfile } = React.useContext(MyProfileContext)
  const Location = useLocation()
  const profile = Location.state
  return (
    <>
      <h2 className='mt-2 ms-2'>All Profiles</h2>
      <div className="head-dataone d-flex justify-content-between flex-wrap mt-3">
        {
          profile.map((val) => {
            return (
              <div className="head-data">
                <div>
                  <img
                    src={val.image}
                    style={{
                      width: "135px",
                      height: "120px",
                      filter: myProfile.payment === false ? "blur(4px)" : "none",
                    }}
                  />
                </div>
                <div className="divdata">
                  <h4 className="colour">
                    {val.firstName}
                    {val.secondName}
                  </h4>
                  <p className="fav-para-1">
                    {val.age} yrs
                    <span className="homecente-cards-verticalline">|</span>
                    {val.height}
                  </p>
                  <p className="fav-para-1">{val.caste}</p>
                  <p className="fav-para-1">{val.occupation}</p>
                </div>
              </div>
            )
          })
        }
      </div>
    </>
  )
}

export default ViewAll