import React, { useEffect, useRef, useState } from "react";
import { Link, Outlet, useNavigate, useResolvedPath } from "react-router-dom";
import "../../../Home/css/Settings.css";
import "./NavBar";
import { FaLongArrowAltLeft } from "react-icons/fa";
import NavBar from "./NavBar";
import BottomNavbar from "../../../../components/BottomNavbar";
import Footer from "../../../../components/Footer";

export const Settings = ({ handleLogOut }) => {
  const { pathname } = useResolvedPath();

  let navigate = useNavigate();
  let [selectedItem, setSelectedItem] = useState("mail");

  const clickData = (nav) => {
    navigate(`${nav}`);
    setSelectedItem(nav);
  };

  const listItemStyle = (item) => {
    return { backgroundColor: selectedItem === item ? "#E2E1DE" : "white" };
  };

  const Logut = async () => {
    localStorage.removeItem("profileId");
    await handleLogOut()
    navigate("/");
  };


  const back = () => {
    navigate("/profile");
  };

  return (
    <>
      <div className="sticky-top">
        <NavBar />
      </div>
      <div className="container-fluid settings-fluid">
        <div className="container settings-container">
          <div className="main-container settings-main">
            <div className="card-container-1">
              <div className="card card-container">
                <div className="card-header head-container ">
                  Profile Settings
                </div>
                <ul className="list-group list-group-flush list-container">
                  <li
                    className="list-content"
                    style={listItemStyle("mail")}
                    onClick={() => clickData("mail")}
                  >
                    Email & Phone Number
                  </li>

                  <li
                    className="list-content"
                    style={listItemStyle("password")}
                    onClick={() => clickData("password")}
                  >
                    Change Password
                  </li>
                  <li
                    className="list-content"
                    style={listItemStyle("delete")}
                    onClick={() => clickData("delete")}
                  >
                    Delete Profile
                  </li>
                  <li
                    className="list-content"
                    style={listItemStyle("blocked")}
                    onClick={() => clickData("blocked")}
                  >
                    Blocked Profiles
                  </li>
                  <li className="log-list" onClick={Logut}>
                    Logout
                  </li>
                </ul>
              </div>
            </div>

            <div className="out-let-links">
              <Outlet />
            </div>
          </div>
          <div>
            <div className="card-mobile-view  ">
              <div
                style={{ display: pathname != "/settings" ? "none" : "inline" }}
              >
                <div className="Email-Phone-Number-container">
                  <button
                    onClick={() => back()}
                    className="Email-Phone-Number-container-icon"
                  >
                    <FaLongArrowAltLeft />
                  </button>
                  <h4 className="mobive-view-btns">Profile settings</h4>
                </div>
                <div className="me-auto">
                  <div className="settigns-page-mobile-view-container">
                    <button
                      onClick={() => navigate("mail")}
                      className="settigns-page-mobile-view"
                    >
                      <div className=" ">
                        <p className="list-content"> Email & Phone Number </p>
                      </div>
                      <div>
                        <img className="" src="/images/Arrow.png" />
                      </div>
                    </button>
                    <button
                      onClick={() => navigate("password")}
                      className="settigns-page-mobile-view"
                    >
                      <div className=" ">
                        <p className="list-content"> Change Password </p>
                      </div>
                      <div>
                        <img className="" src="/images/Arrow.png" />
                      </div>
                    </button>
                    <button
                      onClick={() => navigate("delete")}
                      className="settigns-page-mobile-view"
                    >
                      <div className=" ">
                        <p className="list-content"> Delete Profile </p>
                      </div>
                      <div>
                        <img className="" src="/images/Arrow.png" />
                      </div>
                    </button>
                    {/* <button
                      onClick={() => navigate("/settings/ignored")}
                      className="settigns-page-mobile-view"
                    >
                      <div className=" ">
                        <p className="list-content"> Ignored Profiles</p>
                      </div>
                      <div>
                        <img className="" src="/images/Arrow.png" />
                      </div>
                    </button> */}
                    <button
                      onClick={() => navigate("blocked")}
                      className="settigns-page-mobile-view"
                    >
                      <div className=" ">
                        <p className="list-content"> Blocked Profiles</p>
                      </div>
                      <div>
                        <img className="" src="/images/Arrow.png" />
                      </div>
                    </button>
                  </div>
                  <div className="settigns-page-mobile-view-container">
                    <button onClick={Logut} className="log-list">
                      Logout
                    </button>
                  </div>
                </div>
              </div>
              <div className="">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <Footer />
      </div>
      <div className=" sticky-bottom">
        <BottomNavbar />
      </div>
    </>
  );
};
