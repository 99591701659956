import React from "react";
import { useNavigate } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
let profile = {
  profiles: [
    {
      status: "married",
      Profile: 1,
      Gender: "Male",
      FirstName: "John",
      SecondName: "Doe",
      "Phone number": "123-456-7890",
      Email: "john.doe@example.com",
      Country: "Cityville",
      State: "Techland",
      City: "Codeburg",
      Caste: "Tech Enthusiast",
      subcaste: "Coding Experts",
      Religion: "Techology",
      "Marital status": "Single",
      Height: "5'10\"",
      "Family type": "Nuclear",
      "Family status": "Upper Middle Class",
      "Any Disabilities": "None",
      "Highest Education": "Master's in Computer Science",
      CourseName: "Computer Science",
      clgName: "Tech University",
      Occupation: "Software Engineer",
      "Annual Income": "80000",
      photos:
        "https://th.bing.com/th/id/OIP.YnWyCbj36otw6eG6mznpqwHaJ3?pid=ImgDet&w=207&h=275&c=7",
      payment: "True",
      age: 22,
    },
    {
      status: "married",

      age: 26,
      Profile: 2,
      Gender: "Female",
      FirstName: "Jane",
      SecondName: "Smith",
      "Phone number": "987-654-3210",
      Email: "jane.smith@example.com",
      Country: "Townsville",
      State: "Artsland",
      City: "Artsville",
      Caste: "Creative Souls",
      subcaste: "Artistic Minds",
      Religion: "Artism",
      "Marital status": "Single",
      Height: "5'6\"",
      "Family type": "Joint",
      "Family status": "Middle Class",
      "Any Disabilities": "None",
      "Highest Education": "Bachelor's in Fine Arts",
      CourseName: "Fine Arts",
      clgName: "Artistic College",
      Occupation: "Graphic Designer",
      "Annual Income": "60000",
      photos:
        "https://th.bing.com/th?id=OIP.ktc8E_6H3vyU70d3CUI2_gHaLH&w=204&h=306&c=8&rs=1&qlt=90&o=6&pid=3.1&rm=2",
      payment: "False",
    },
    {
      status: "married",

      age: 32,
      Profile: 3,
      Gender: "Male",
      FirstName: "Alex",
      SecondName: "Miller",
      "Phone number": "555-123-4567",
      Email: "alex.miller@example.com",
      Country: "Suburbia",
      State: "Marketingland",
      City: "Marketville",
      Caste: "Innovators",
      subcaste: "Marketing Gurus",
      Religion: "Marketeerism",
      "Marital status": "Single",
      Height: "5'8\"",
      "Family type": "Nuclear",
      "Family status": "Upper Middle Class",
      "Any Disabilities": "None",
      "Highest Education": "MBA in Marketing",
      CourseName: "Marketing",
      clgName: "Business School",
      Occupation: "Marketing Specialist",
      "Annual Income": "75000",
      photos:
        "https://i.pinimg.com/originals/62/40/b0/6240b07247e9a233efe1bc3196cf8b90.jpg",
      payment: "True",
    },
    {
      status: "married",

      age: 21,
      Profile: 4,
      Gender: "Male",
      FirstName: "David",
      SecondName: "Johnson",
      "Phone number": "111-222-3333",
      Email: "david.johnson@example.com",
      Country: "Suburbia",
      State: "Businessland",
      City: "Commerce City",
      Caste: "Entrepreneurs",
      subcaste: "Startup Enthusiasts",
      Religion: "Businessism",
      "Marital status": "Single",
      Height: "6'0\"",
      "Family type": "Nuclear",
      "Family status": "Upper Class",
      "Any Disabilities": "None",
      "Highest Education": "Bachelor's in Business Administration",
      CourseName: "Business Administration",
      clgName: "Business College",
      Occupation: "Entrepreneur",
      "Annual Income": "100000",
      photos:
        "https://th.bing.com/th/id/OIP.8i19rPdnBG94MyKgiDR3DQHaLH?pid=ImgDet&w=204&h=306&c=7",
      payment: "False",
    },
    {
      status: "married",

      age: 25,
      Profile: 5,
      Gender: "Male",
      FirstName: "Green",
      SecondName: "Williams",
      "Phone number": "555-987-6543",
      Email: "emily.williams@example.com",
      Country: "Villageland",
      State: "Educationland",
      City: "Knowledgeville",
      Caste: "Educators",
      subcaste: "Teachers",
      Religion: "Educationism",
      "Marital status": "Single",
      Height: "5'5\"",
      "Family type": "Joint",
      "Family status": "Middle Class",
      "Any Disabilities": "None",
      "Highest Education": "Master's in Education",
      CourseName: "Education",
      clgName: "Education University",
      Occupation: "School Teacher",
      "Annual Income": "50,000",
      photos:
        "https://th.bing.com/th/id/OIP.GEKNKeRhNFUvYOa2FawblQHaIu?pid=ImgDet&w=207&h=243&c=7",
      payment: "True",
    },
    {
      status: "single",
      age: 28,
      Profile: 6,
      Gender: "Male",
      FirstName: "Michael",
      SecondName: "Brown",
      "Phone number": "777-888-9999",
      Email: "michael.brown@example.com",
      Country: "Townsville",
      State: "Medicalland",
      City: "Healthville",
      Caste: "Healthcare Professionals",
      subcaste: "Doctors",
      Religion: "Healthism",
      "Marital status": "Single",
      Height: "5'11\"",
      "Family type": "Nuclear",
      "Family status": "Upper Middle Class",
      "Any Disabilities": "None",
      "Highest Education": "Doctor of Medicine",
      CourseName: "Medicine",
      clgName: "Medical School",
      Occupation: "Medical Doctor",
      "Annual Income": "120000",
      photos:
        "https://th.bing.com/th/id/OIP.iQPD6iAFYw0n73g_K4yZ5gHaLG?pid=ImgDet&w=204&h=306&c=7",
      payment: "False",
    },
    {
      status: "single",

      age: 30,
      Profile: 7,
      Gender: "Male",
      FirstName: "Bolt",
      SecondName: "Davis",
      "Phone number": "444-555-6666",
      Email: "olivia.davis@example.com",
      Country: "Cityville",
      State: "Artsland",
      City: "Artstown",
      Caste: "Artists",
      subcaste: "Painters",
      Religion: "Artism",
      "Marital status": "Single",
      Height: "5'7\"",
      "Family type": "Joint",
      "Family status": "Middle Class",
      "Any Disabilities": "None",
      "Highest Education": "Bachelor's in Fine Arts",
      CourseName: "Fine Arts",
      clgName: "Artistic College",
      Occupation: "Freelance Artist",
      "Annual Income": "45000",
      photos:
        "https://th.bing.com/th/id/OIP.TvRtpjnBNNb4ZlXgkN3lsgAAAA?pid=ImgDet&w=207&h=207&c=7",
      payment: "False",
    },
    {
      status: "single",

      age: 31,
      Profile: 8,
      Gender: "Male",
      FirstName: "Jordan",
      SecondName: "Taylor",
      "Phone number": "333-444-5555",
      Email: "jordan.taylor@example.com",
      Country: "Suburbia",
      State: "Techland",
      City: "Innovate City",
      Caste: "Innovators",
      subcaste: "Tech Innovators",
      Religion: "Techology",
      "Marital status": "Single",
      Height: "5'9\"",
      "Family type": "Nuclear",
      "Family status": "Upper Middle Class",
      "Any Disabilities": "None",
      "Highest Education": "Ph.D. in Computer Science",
      CourseName: "Computer Science",
      clgName: "Tech Institute",
      Occupation: "Tech Innovator",
      "Annual Income": "90000",
      photos:
        "https://th.bing.com/th/id/OIP.u1DV_CDf9VC9uO6Xw5xVuwHaJQ?pid=ImgDet&w=207&h=258&c=7",
      payment: "True",
    },
    {
      age: 25,
      Profile: 9,
      Gender: "Male",
      status: "single",

      FirstName: "Daniel",
      SecondName: "Jones",
      "Phone number": "888-999-0000",
      Email: "daniel.jones@example.com",
      Country: "Townsville",
      State: "Sportland",
      City: "Sportsville",
      Caste: "Sports Enthusiasts",
      subcaste: "Athletes",
      Religion: "Sportism",
      "Marital status": "Single",
      Height: "6'2\"",
      "Family type": "Nuclear",
      "Family status": "Upper Class",
      "Any Disabilities": "None",
      "Highest Education": "Bachelor's in Sports Science",
      CourseName: "Sports Science",
      clgName: "Sports University",
      Occupation: "Professional Athlete",
      "Annual Income": "150000",
      photos:
        "https://th.bing.com/th/id/OIP.6JwRot7qacQI1ZClZSs0vAHaLH?pid=ImgDet&w=204&h=306&c=7",
      payment: "True",
    },
    {
      age: 32,
      status: "single",

      Profile: 10,
      Gender: "Female",
      FirstName: "Sophia",
      SecondName: "Miller",
      "Phone number": "111-222-3333",
      Email: "sophia.miller@example.com",
      Country: "Suburbia",
      State: "Fashionland",
      City: "Style City",
      Caste: "Fashionistas",
      subcaste: "Fashion Designers",
      Religion: "Fashionism",
      "Marital status": "Single",
      Height: "5'8\"",
      "Family type": "Nuclear",
      "Family status": "Upper Middle Class",
      "Any Disabilities": "None",
      "Highest Education": "Bachelor's in Fashion Design",
      CourseName: "Fashion Design",
      clgName: "Fashion School",
      Occupation: "Fashion Designer",
      "Annual Income": "70000",
      photos:
        "https://th.bing.com/th/id/OIP.UiYV_-p0CWt2VeLpBdvpWwHaKV?w=178&h=249&c=7&r=0&o=5&pid=1.7",
      payment: "False",
    },
    {
      age: 30,
      status: "single",

      Profile: 11,
      Gender: "Female",
      FirstName: "Christopher",
      SecondName: "Anderson",
      "Phone number": "555-777-8888",
      Email: "chris.anderson@example.com",
      Country: "Villageland",
      State: "Natureland",
      City: "Green Valley",
      Caste: "Nature Lovers",
      subcaste: "Environmentalists",
      Religion: "Ecoism",
      "Marital status": "Single",
      Height: "5'9\"",
      "Family type": "Joint",
      "Family status": "Middle Class",
      "Any Disabilities": "None",
      "Highest Education": "Bachelor's in Environmental Science",
      CourseName: "Environmental Science",
      clgName: "Nature University",
      Occupation: "Environmental Activist",
      "Annual Income": "55000",
      photos:
        "https://th.bing.com/th/id/OIP.Yc9pzAyDoa9Vfv03fUycJAHaLH?w=178&h=267&c=7&r=0&o=5&pid=1.7",
      payment: "True",
    },
    {
      age: 30,
      status: "single",

      Profile: 12,
      Gender: "Female",
      FirstName: "William",
      SecondName: "Taylor",
      "Phone number": "777-888-9999",
      Email: "william.taylor@example.com",
      Country: "Cityville",
      State: "Techland",
      City: "Innovation City",
      Caste: "Tech Enthusiasts",
      subcaste: "Innovators",
      Religion: "Techology",
      "Marital status": "Single",
      Height: "6'0\"",
      "Family type": "Nuclear",
      "Family status": "Upper Middle Class",
      "Any Disabilities": "None",
      "Highest Education": "Ph.D. in Computer Science",
      CourseName: "Computer Science",
      clgName: "Tech Institute",
      Occupation: "AI Researcher",
      "Annual Income": "120000",
      photos:
        "https://th.bing.com/th/id/OIP.GsOu40yLfea394kATg4ClwAAAA?w=178&h=249&c=7&r=0&o=5&pid=1.7",
      payment: "False",
    },
    {
      age: 30,
      status: "single",

      Profile: 13,
      Gender: "Female",
      FirstName: "Ethan",
      SecondName: "Miller",
      "Phone number": "111-222-3333",
      Email: "ethan.miller@example.com",
      Country: "Suburbia",
      State: "Sportsland",
      City: "Active City",
      Caste: "Sports Enthusiasts",
      subcaste: "Fitness Trainers",
      Religion: "Sportism",
      "Marital status": "Single",
      Height: "6'2\"",
      "Family type": "Nuclear",
      "Family status": "Upper Class",
      "Any Disabilities": "None",
      "Highest Education": "Bachelor's in Sports Science",
      CourseName: "Sports Science",
      clgName: "Sports University",
      Occupation: "Personal Trainer",
      "Annual Income": "80000",
      photos:
        "https://th.bing.com/th/id/OIP.MoAft8HxQly0BqyNUDXOPQHaKo?w=178&h=256&c=7&r=0&o=5&pid=1.7",
      payment: "True",
    },
    {
      age: 23,
      status: "single",

      Profile: 14,
      Gender: "Female",
      FirstName: "Benjamin",
      SecondName: "Harris",
      "Phone number": "333-444-5555",
      Email: "benjamin.harris@example.com",
      Country: "Townsville",
      State: "Medicalland",
      City: "Healthy Town",
      Caste: "Healthcare Professionals",
      subcaste: "Nurses",
      Religion: "Healthism",
      "Marital status": "Single",
      Height: "5'11\"",
      "Family type": "Nuclear",
      "Family status": "Upper Middle Class",
      "Any Disabilities": "None",
      "Highest Education": "Bachelor's in Nursing",
      CourseName: "Nursing",
      clgName: "Medical College",
      Occupation: "Registered Nurse",
      "Annual Income": "65000",
      photos:
        "https://th.bing.com/th/id/OIP.Ds-IRJb8WE5SHRCJJPNgPgHaJf?w=178&h=228&c=7&r=0&o=5&pid=1.7",
      payment: "False",
    },
    {
      age: 23,
      status: "single",

      Profile: 15,
      Gender: "Female",
      FirstName: "Henry",
      SecondName: "Collins",
      "Phone number": "444-555-6666",
      Email: "henry.collins@example.com",
      Country: "Villageland",
      State: "Educationland",
      City: "Knowledge City",
      Caste: "Educators",
      subcaste: "Professors",
      Religion: "Educationism",
      "Marital status": "Single",
      Height: "6'1\"",
      "Family type": "Joint",
      "Family status": "Middle Class",
      "Any Disabilities": "None",
      "Highest Education": "Ph.D. in Physics",
      CourseName: "Physics",
      clgName: "Science University",
      Occupation: "Physics Professor",
      "Annual Income": "90000",
      photos:
        "https://th.bing.com/th/id/OIP.rkOg1Zl_ANqCezYtxzkWZAHaJI?w=178&h=219&c=7&r=0&o=5&pid=1.7",
      payment: "False",
    },
    {
      age: 23,
      status: "widow",

      Profile: 16,
      Gender: "Female",
      FirstName: "Aiden",
      SecondName: "Jones",
      "Phone number": "888-999-0000",
      Email: "aiden.jones@example.com",
      Country: "Suburbia",
      State: "Businessland",
      City: "Business City",
      Caste: "Entrepreneurs",
      subcaste: "Business Owners",
      Religion: "Businessism",
      "Marital status": "Single",
      Height: "5'10\"",
      "Family type": "Nuclear",
      "Family status": "Upper Class",
      "Any Disabilities": "None",
      "Highest Education": "MBA in Business Administration",
      CourseName: "Business Administration",
      clgName: "Business School",
      Occupation: "Entrepreneur",
      "Annual Income": "130000",
      photos:
        "https://th.bing.com/th/id/OIP.BqSBoml59qxIYFOPUlmXzwHaJQ?w=178&h=223&c=7&r=0&o=5&pid=1.7",
      payment: "True",
    },
    {
      age: 27,
      status: "widow",

      Profile: 17,
      Gender: "Female",
      FirstName: "Liam",
      SecondName: "Baker",
      "Phone number": "555-987-6543",
      Email: "liam.baker@example.com",
      Country: "Cityville",
      State: "Artsland",
      City: "Artistic City",
      Caste: "Artists",
      subcaste: "Musicians",
      Religion: "Artism",
      "Marital status": "Single",
      Height: "5'8\"",
      "Family type": "Joint",
      "Family status": "Middle Class",
      "Any Disabilities": "None",
      "Highest Education": "Bachelor's in Music",
      CourseName: "Music",
      clgName: "Music College",
      Occupation: "Musician",
      "Annual Income": "70000",
      photos:
        "https://th.bing.com/th/id/OIP.dMBXIVYZk9ert0RBQ25aWAHaLH?w=178&h=267&c=7&r=0&o=5&pid=1.7",
      payment: "True",
    },
    {
      age: 27,
      status: "widow",

      Profile: 18,
      Gender: "Female",
      FirstName: "Logan",
      SecondName: "Smith",
      "Phone number": "222-333-4444",
      Email: "logan.smith@example.com",
      Country: "Suburbia",
      State: "Techland",
      City: "Innovation Town",
      Caste: "Innovators",
      subcaste: "Tech Geeks",
      Religion: "Techology",
      "Marital status": "Single",
      Height: "6'0\"",
      "Family type": "Nuclear",
      "Family status": "Upper Middle Class",
      "Any Disabilities": "None",
      "Highest Education": "Bachelor's in Computer Science",
      CourseName: "Computer Science",
      clgName: "Tech Institute",
      Occupation: "Software Developer",
      "Annual Income": "85000",
      photos:
        "https://th.bing.com/th/id/OIP.4T0MOB4Nuw8QaHC0ca6-XgHaFj?w=178&h=134&c=7&r=0&o=5&pid=1.7",
      payment: "False",
    },
    {
      age: 27,
      status: "widow",

      Profile: 19,
      Gender: "Female",
      FirstName: "Mason",
      SecondName: "Turner",
      "Phone number": "999-111-2222",
      Email: "mason.turner@example.com",
      Country: "Townsville",
      State: "Sportsland",
      City: "Sports City",
      Caste: "Sports Enthusiasts",
      subcaste: "Athletes",
      Religion: "Sportism",
      "Marital status": "Single",
      Height: "6'2\"",
      "Family type": "Nuclear",
      "Family status": "Upper Class",
      "Any Disabilities": "None",
      "Highest Education": "Bachelor's in Sports Management",
      CourseName: "Sports Management",
      clgName: "Sports University",
      Occupation: "Sports Manager",
      "Annual Income": "110000",
      photos:
        "https://th.bing.com/th/id/OIP.U9hgLIy4ijQ-BLdawlWg_AHaLK?w=178&h=268&c=7&r=0&o=5&pid=1.7",
      payment: "True",
    },
    {
      age: 23,
      status: "widow",

      Profile: 20,
      Gender: "Female",
      FirstName: "Sebastian",
      SecondName: "Wright",
      "Phone number": "444-555-6666",
      Email: "sebastian.wright@example.com",
      Country: "Suburbia",
      State: "Fashionland",
      City: "Fashion Town",
      Caste: "Fashionistas",
      subcaste: "Fashion Designers",
      Religion: "Fashionism",
      "Marital status": "Single",
      Height: "5'11\"",
      "Family type": "Nuclear",
      "Family status": "Upper Middle Class",
      "Any Disabilities": "None",
      "Highest Education": "Bachelor's in Fashion Design",
      CourseName: "Fashion Design",
      clgName: "Fashion School",
      Occupation: "Fashion Designer",
      "Annual Income": "75000",
      photos:
        "https://th.bing.com/th/id/OIP.uolhVlIL_djG9rIlV-HlwwAAAA?w=178&h=267&c=7&r=0&o=5&pid=1.7",
      payment: "False",
    },
  ],
};

let filterData = profile.profiles.slice(8, 12);

export const Ignored = () => {
  const navigate = useNavigate();
  const back = () => {
    navigate("/settings");
  };
  return (
    <>
      <div className="Email-Phone-Number-container">
        <button
          onClick={() => back()}
          className="Email-Phone-Number-container-icon"
        >
          <FaLongArrowAltLeft />
        </button>
        <h4 className="mobive-view-btns">Ignored Profilese</h4>
      </div>
      <div className="ignore-head-conatienr">
        <h6 className="head-ignore">Ignored Profiles</h6>
        <div className="ign-heading">
          {filterData.map((val) => {
            return (
              <div className="mainign-container">
                <div className="ignore-container">
                  <div className="first-content">
                    <div>
                      <img
                        className="ign-img"
                        src={val.photos}
                        alt="No image"
                      ></img>
                    </div>
                    <div className="second-content">
                      <h6 className="ingnnore-proffile-content-name">
                        {val.FirstName}
                      </h6>
                      <p className="ingnnore-proffile-content">
                        {val.age} yrs{" "}
                        <span className="ingnnore-vetical-line">|</span>{" "}
                        {val.Height} ft{" "}
                      </p>
                      <p className="ingnnore-proffile-content">
                        Religion{" "}
                        <span className="ingnnore-vetical-line">|</span> Caste{" "}
                        <span className="ingnnore-vetical-line">|</span> Mother
                        Tongue
                      </p>
                      <p className="ingnnore-proffile-content">
                        Study <span className="ingnnore-vetical-line">|</span>{" "}
                        Occupation
                      </p>
                      <a href="" className="anchor-all">
                        View Profile
                      </a>
                    </div>
                    <div className="third-content">
                      <div className="third-content-img-head">
                      <p className="ingnnore-proffile-content-name">
                        Premium
                        
                      </p>
                      <img src="/Images/badge.png" className="priminmum-img" />
                      </div>
                     

                      <button className="btnone-ign">Intrested</button>

                      <button className="btntwo-ign">Skip</button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
