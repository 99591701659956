import React, { useEffect, useState } from "react";
import "../../../../pages/Home/css/advancesearch.css";
import { FiEdit } from "react-icons/fi";
import { FaChevronDown } from "react-icons/fa6";
import { IoIosArrowUp } from "react-icons/io";
import { MyProfileContext } from "../../../../context/ProfileProvider";
import DetailsData from "../../../../data/State.json";
import Religion from "../../../../data/Religion.json";
const AdvanceSearch = ({ onFilterChange }) => {
  const [viewContent, setViewContent] = useState(false);
  const [showMore, setShowMore] = useState(true);
  const [religion, setReligion] = useState("");
  const [caste, setCaste] = useState("");
  const [subCaste, setSubCaste] = useState("");
  const [occupation, setOccupation] = useState("");
  const [income, setIncome] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [motherTounge, setMotherTounge] = useState("");
  const [heightFeet, setHeightFeet] = useState("");
  const [selectedAgeRange, setSelectedAgeRange] = useState("");
  const { allProfiles } = React.useContext(MyProfileContext);
  const [minAge, setMinAge] = useState("");
  const [maxAge, setMaxAge] = useState("");
  const [minHeight, setMinHeight] = useState("");
  const [maxHeight, setMaxHeight] = useState("");
  const ages = [
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
  ];
  const heightOptions = ["4", "5", "6", "7"];

  const calculateAge = (dateOfBirth) => {
    const birthDate = new Date(dateOfBirth);
    const currentDate = new Date();
    const age = currentDate.getFullYear() - birthDate.getFullYear();
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      return age - 1;
    }
    return age;
  };

  const religionOptions = Object.keys(Religion);

  function getOption(val) {
    if (caste) {
      const subCaste = val.filter((val) => val.name === caste);
      return subCaste[0]?.subcaste;
    }
  }
  const parseHeight = (height) => {
    const [feetPart] = height.split("-").map((part) => parseInt(part, 10) || 0);
    return feetPart; // Only consider the feet part
  };

  const isProfileWithinHeightRange = (profile, minHeight, maxHeight) => {
    const profileHeightFeet = parseHeight(profile.height);
    const minHeightFeet = minHeight !== "" ? parseHeight(minHeight) : 0;
    const maxHeightFeet = maxHeight !== "" ? parseHeight(maxHeight) : Infinity;

    return (
      profileHeightFeet >= minHeightFeet && profileHeightFeet <= maxHeightFeet
    );
  };

  const applyFilters = () => {
    const originalProfiles = [...allProfiles];

    const filtersApplied =
      religion ||
      caste ||
      subCaste ||
      occupation ||
      income ||
      maritalStatus ||
      (minAge && maxAge) ||
      (minHeight && maxHeight) ||
      motherTounge;

    if (filtersApplied) {
      const filteredProfiles = originalProfiles.filter((profile) => {
        const religionFilter = !religion || profile.religion === religion;
        const casteFilter = !caste || profile.caste === caste;
        const subCasteFilter = !subCaste || profile.subCaste === subCaste;
        const occupationFilter =
          !occupation || profile.occupation === occupation;
        const incomeFilter = !income || profile.annualIncome === income;
        const motherToungeFilter =
          !motherTounge || profile.motherTounge === motherTounge;
        const maritalStatusFilter =
          !maritalStatus || profile.maritalStatus === maritalStatus;
        const ageFilter =
          (!minAge && !maxAge) ||
          isProfileWithinAgeRange(profile, `${minAge}-${maxAge}`);
        const heightFilter =
          (!minHeight && !maxHeight) ||
          isProfileWithinHeightRange(profile, minHeight, maxHeight);

        return (
          religionFilter &&
          casteFilter &&
          subCasteFilter &&
          occupationFilter &&
          incomeFilter &&
          maritalStatusFilter &&
          ageFilter &&
          heightFilter &&
          motherToungeFilter
        );
      });
      onFilterChange(filteredProfiles);
    }
  };

  const isProfileWithinAgeRange = (profile, ageRange) => {
    const [minAge, maxAge] = ageRange.split("-").map(Number);
    const age = calculateAge(profile.dateOfBirth);
    return (!minAge || age >= minAge) && (!maxAge || age <= maxAge);
  };

  const resetFiltersHandler = () => {
    setReligion("");
    setCaste("");
    setSubCaste("");
    setOccupation("");
    setIncome("");
    setMaritalStatus("");
    setHeightFeet("");
    setSelectedAgeRange("");
    setMaxAge("");
    setMinAge("");
    setMaxHeight("");
    setMinHeight("");
    setMotherTounge("");
    applyFilters();
  };

  useEffect(() => {
    applyFilters();
  }, [
    religion,
    caste,
    subCaste,
    occupation,
    income,
    maritalStatus,
    selectedAgeRange,
    allProfiles,
    minAge,
    maxAge,
    minHeight,
    maxHeight,
  ]);

  return (
    <div className="">
      <div className="">
        <div className="Advance-container">
          <div className="">
            <div className="advance-search-data">
              <h4 className="advance-search-heading section-header">
                Advance Search
              </h4>
            </div>
            <div className="advance-body">
              <div className="advance-data">
                <div className="body-detail-data-1">
                  <div className="basic">
                    <h4 className="heading">Basic Details</h4>
                  </div>
                  <div className="basic-detail">
                    <div className="age-dt">
                      <h5 className="age">Age</h5>
                      <div className="age-between">
                        <select
                          id="minAge"
                          className="ages form-select"
                          onChange={(e) => setMinAge(e.target.value)}
                          value={minAge}
                        >
                          <option value="">From</option>
                          {ages.map((val) => (
                            <option key={val} value={parseInt(val, 10)}>
                              {val}
                            </option>
                          ))}
                        </select>
                        <select
                          id="maxAge"
                          className="ages form-select"
                          onChange={(e) => setMaxAge(e.target.value)}
                          value={maxAge}
                        >
                          <option value="">To</option>
                          {ages
                            .filter((age) => parseInt(age, 10) > minAge)
                            .map((val) => (
                              <option key={val} value={parseInt(val, 10)}>
                                {val}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="height">
                      <h5 className="height-heading">Height</h5>
                      <div className="age-between">
                        <select
                          id="height"
                          className="height-input form-select"
                          onChange={(e) => setMinHeight(e.target.value)}
                          value={minHeight}
                        >
                          <option value="">From</option>
                          {heightOptions.map((option) => (
                            <option key={option} value={`${option}ft`}>
                              {`${option}ft`}
                            </option>
                          ))}
                        </select>
                        <select
                          id="height"
                          className="height-input form-select"
                          onChange={(e) => setMaxHeight(e.target.value)}
                          value={maxHeight}
                        >
                          <option value="">To</option>
                      
                          {heightOptions
                            .filter(
                              (height) =>
                                parseInt(height, 10) > parseInt(minHeight, 10)
                            )
                            .map((option) => (
                              <option key={option} value={`${option}ft`}>
                                {`${option}ft`}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="status">
                      <div className="status-field-data">
                        <h5 className="status-field-data-1">Mother Tongue</h5>
                        <select
                          defaultValue={""}
                          className="form-select"
                          aria-label="Default select example"
                          onChange={(e) => {
                            setMotherTounge(e.target.value);
                          }}
                          value={motherTounge}
                        >
                          <option value="">Select MotherTounge</option>
                          {DetailsData.languages.map((val) => (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="status-field-data">
                        <h5 className="status-field-data-1">Marital Status</h5>
                        <select
                          defaultValue={""}
                          className="form-select"
                          aria-label="Default select example"
                          onChange={(e) => {
                            setMaritalStatus(e.target.value);
                          }}
                          value={maritalStatus}
                        >
                          <option value="">Select Marital Status</option>
                          {DetailsData.maritualStatus.map((val) => (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="body-detail-data-2">
                  <div className="professional-details">
                    <h4 className="heading">Caste & Religion Details</h4>
                  </div>
                  <div className="status">
                    <div className="status-field-data">
                      <h5 className="status-field-data-1">Religion</h5>
                      <select
                        defaultValue={""}
                        className="form-select"
                        aria-label="Default select example"
                        onChange={(e) => {
                          setReligion(e.target.value);
                        }}
                        value={religion}
                      >
                        <option value="">Select Religion</option>
                        {religionOptions.map((religion) => (
                          <option key={religion} value={religion}>
                            {religion}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="status-field-data">
                      <h5 className="status-field-data-1">Caste</h5>
                      <select
                        defaultValue={""}
                        className="form-select"
                        aria-label="Default select example"
                        onChange={(e) => {
                          setCaste(e.target.value);
                        }}
                        value={caste}
                      >
                        <option value="">Select Caste</option>
                        {Religion[religion]?.map((caste) => (
                          <option key={caste.name} value={caste.name}>
                            {caste.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="status-field-data">
                      <h5 className="status-field-data-1">SubCaste</h5>
                      <select
                        defaultValue={""}
                        className="form-select"
                        aria-label="Default select example"
                        onChange={(e) => {
                          setSubCaste(e.target.value);
                        }}
                      >
                        <option value="">Select SubCaste</option>

                        {getOption(Religion[religion])?.map((val) => {
                          return (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                {viewContent && (
                  <div className="hide-content">
                    <div className="professional-details">
                      <h4 className="heading">Professional Details</h4>
                    </div>
                    <div className="status">
                      <div className="status-field-data">
                        <h5 className="status-field-data-1">Occupation</h5>
                        <select
                          defaultValue={""}
                          className="form-select"
                          aria-label="Default select example"
                          onChange={(e) => {
                            setOccupation(e.target.value);
                          }}
                          value={occupation}
                        >
                          <option value="">Select Occupation</option>
                          {DetailsData.Occupation.map((val) => (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="status-field-data">
                        <h5 className="status-field-data-1">Income</h5>
                        <select
                          defaultValue={""}
                          className="form-select"
                          aria-label="Default select example"
                          onChange={(e) => {
                            setIncome(e.target.value);
                          }}
                          value={income}
                        >
                          <option value="">Select Income</option>
                          {DetailsData.Income.map((val) => (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                )}
                <div className="view">
                  <div className="vw-1 d-flex">
                    <button
                      className="btn-more-less"
                      onClick={() => {
                        setViewContent(!viewContent);
                        setShowMore(!showMore);
                      }}
                    >
                      {viewContent ? (
                        <span>
                          View Less <IoIosArrowUp className="icon" />
                        </span>
                      ) : (
                        <span>
                          View More <FaChevronDown className="icon" />
                        </span>
                      )}
                    </button>
                    <button
                      className="btn-reset-filters"
                      onClick={resetFiltersHandler}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvanceSearch;
