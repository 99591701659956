import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./pages/Home/components/Home";
import PaymentIntegration from "../src/pages/Home/components/PaymentIntegration";
import Help from "./pages/Help/Help";
import Chat from "./pages/Home/components/chat/Chat";
import Chatpage from "./pages/Home/components/chat/Chatpage";
import ChatRequest from "./pages/Home/components/chat/ChatRequest";
import { Registration } from "./components/Forms/Registration";
import MatchesFeed from "./pages/Home/components/matches/MatchesFeed";
import MailVerification from "./components/Forms/MailVerification";
import MainHome from "./pages/Home/components/DashBoard/MainHome";
import { Mail } from "./pages/Home/components/compnent-home/settingsPages/Mail";
import { Delete } from "./pages/Home/components/compnent-home/settingsPages/Delete";
import { Ignored } from "./pages/Home/components/compnent-home/settingsPages/Ignored";
import { Blocked } from "./pages/Home/components/compnent-home/settingsPages/Blocked";
import { Password } from "./pages/Home/components/compnent-home/settingsPages/Password";
import { MyprofileNav } from "./pages/Home/components/compnent-home/Edit-Pages/MyprofileNav";
import { BasicDetails } from "./pages/Home/components/compnent-home/Edit-Pages/BasicDetails";
import { PhotoAlbum } from "./pages/Home/components/compnent-home/Edit-Pages/PhotoAlbum";
import { PersonalDetails } from "./pages/Home/components/compnent-home/Edit-Pages/PersonalDetails";
import { ProfessionalDetails } from "./pages/Home/components/compnent-home/Edit-Pages/ProfessionalDetails";
import LoginPage from "./components/Forms/LoginPage";
import SetPassword from "./components/Forms/SetPassword";
import { Settings } from "./pages/Home/components/DashBoard/Settings";
import NavBar from "./pages/Home/components/DashBoard/NavBar";
import { Profile } from "./pages/Home/components/DashBoard/Profile";
import ViewAll from "./pages/Home/components/DashBoard/ViewAll";
import { Admin } from "./pages/Admin/Admin";
import { Allusers } from "./pages/Admin/AdminPages.js/UserPages/Allusers";
import { Membership } from "./pages/Admin/AdminPages.js/UserPages/Membership";
import User from "./pages/Admin/AdminPages.js/User";
import { useEffect, useState } from "react";
import { ProfileVerification } from "./pages/Home/components/DashBoard/ProfileVerification";
import ContactUs from "./pages/Home/components/ContactUs";
import FeedBacks from "./pages/Admin/AdminPages.js/UserPages/FeedBacks";
import ResetPassword from "./components/Forms/ResetPassword";
import ForgotPassword from "./components/Forms/ForgotPassword";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap'
import PrivacyPolicy from "./pages/Home/components/PrivacyPolicy";
import TermsConditions from "./pages/Home/components/TermsConditions";
import FeedbackForm from "./components/Forms/FeedbackForm";
import Faq from "./pages/Home/components/Faq";
import AdminLogin from "./pages/Admin/AdminLogin";

function App() {
  const [screenWidth, setScreenWidth] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkLoggedIn = async () => {
      const userProfileId = localStorage.getItem("profileId");
      setIsLoggedIn(!!userProfileId);
      setLoading(false);
    };
    checkLoggedIn();
  }, []);

  const ProtectedRoute = ({ element }) => {
    useEffect(() => {
      if (!isLoggedIn) {
        navigate("/");
      }
    }, [navigate, isLoggedIn]);
    return isLoggedIn ? element : null;
  };

  const MyComponent = () => {
    const isMobile = window.innerWidth;
    setScreenWidth(isMobile);
  };

  useEffect(() => {
    MyComponent();
  }, []);

  const handleLogin = async () => {
    const userProfileId = localStorage.getItem("profileId");
    setIsLoggedIn(!!userProfileId);
  };

  const handleLogOut = async () => {
    localStorage.removeItem("profileId");
    setIsLoggedIn(false);
  };

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (loading) {
    return null;
  }


  const ProtectedAdminRoute = ({ element }) => {
    const adminId = localStorage.getItem('adminId');
    useEffect(() => {
      if (!adminId) {
        navigate("/adminLogin/maangalyaam");
      }
    }, [navigate, adminId]);
    return adminId ? element : null;
  };


  return (
    <Routes>
      <Route path="/" element={isLoggedIn ? (<ProtectedRoute element={<MainHome />} />) : (<Home />)} />
      <Route path="/matches" element={<ProtectedRoute element={<MatchesFeed />} />} />
      <Route path="/profile" element={<ProtectedRoute element={<Profile />} />} />
      <Route path="/dashboard-navbar" element={<NavBar />} />
      <Route path="/Payments" element={<PaymentIntegration />} />
      <Route path="/faq" element={<Faq/>} />
      <Route path="/mailverification" element={<MailVerification />} />
      <Route path="/login" element={<LoginPage handleLogin={handleLogin} />} />
      <Route path="/registration" element={<Registration />} />
      <Route path="/mailverification" element={<MailVerification />} />
      <Route path="/setpassword" element={<SetPassword />} />
      <Route path="/verification" element={<ProfileVerification />} />
      <Route path="/contactus" element={<ContactUs />} />
      <Route path="/resetpassword/:id/:token" element={< ResetPassword />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
      <Route path="/terms" element={<TermsConditions />} />
      <Route path="/feedback" element={<FeedbackForm />} />

      {/* ------------------edit pages links------------------ */}

      <Route path="/edit" element={<MyprofileNav />}>
        <Route path="basicdetails" element={<BasicDetails />} />
        <Route path="photoalbum" element={<PhotoAlbum />} />
        <Route path="personaldetails" element={<PersonalDetails />} />
        <Route path="professionaldetails" element={<ProfessionalDetails />} />
      </Route>

      {/* ------------------Settings pages links------------- */}

      <Route path="/settings" element={<Settings handleLogOut={handleLogOut} />}>
        {screenWidth > 766 ? (<Route path="mail" element={<Mail />} />) : (<Route path="mail&password" element={<Mail />} />)}
        <Route path="password" element={<Password />} />
        <Route path="delete" element={<Delete />} />
        <Route path="ignored" element={<Ignored />} />
        <Route path="blocked" element={<Blocked />} />
      </Route>

      {/* ------------------chat pages links--------------- */}

      <Route path="/chat" element={<ProtectedRoute element={<Chat />} />} />
      <Route path="/chatpage" element={<ProtectedRoute element={<Chatpage />} />} />
      <Route path="/chatrequest" element={<ProtectedRoute element={<ChatRequest />} />} />
      <Route path="/viewall" element={<ViewAll />} />

      {/* ------------------admin pages links------------------- */}
      <Route path="/adminLogin/maangalyaam" element={<AdminLogin />} />

      <Route path="/admin" element={<ProtectedAdminRoute element={<Admin />} />}>
        <Route path="feedback" element={<FeedBacks />} />
        <Route path="users" element={<User />}>
          <Route path="feedback" element={<FeedBacks />} />
          <Route path="all" element={<Allusers />} />
          <Route path="membership" element={<Membership />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;