import React from "react";
import { NavLink } from "react-router-dom";
import "../../src/components/bottomNavbar.css";

const BottomNavbar = () => {
  return (
    <div>
      <div className="">
        <div className="">
          <div className="mobile-navbar d-flex justify-content-around">
            <NavLink
              to="/mainhome"
              className="mobile-navbar-links nav-link"
              activeClassName="active"
            >
              <div className="mobile-navbar-icons">
                <img src="../Images/Home.svg" alt="Home Icon" />
                <p>Home</p>
              </div>
            </NavLink>
            <NavLink
              to="/matches"
              className="mobile-navbar-links nav-link"
              activeClassName="active"
            >
              <div className="mobile-navbar-icons ">
                <img src="../Images/matches.svg" alt="Matches Icon" />
                <p>Matches</p>
              </div>
            </NavLink>
            <NavLink
              to="/chatpage"
              className="mobile-navbar-links nav-link"
              activeClassName="active"
            >
              <div className="mobile-navbar-icons">
                <img src="../Images/chat.svg" alt="Chat Icon" />
                <p>Chat</p>
              </div>
            </NavLink>
            <NavLink
              to="/profile"
              className="mobile-navbar-links nav-link"
              activeClassName="active"
            >
              <div className="mobile-navbar-icons">
                <img src="../Images/profile.svg" alt="Profile Icon" />
                <p>Profile</p>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomNavbar;
