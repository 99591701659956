import React, { useState, useEffect } from "react";
import "./errorPopup.css";

const ErrorPopup = ({ message, onClose }) => {
  const [canClose, setCanClose] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCanClose(true);
      onClose();
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, [onClose]);

  const handleManualClose = () => {
    setCanClose(true);
    onClose();
  };

  return (
    <div className="error-popup-container">
      <div className="error-popup">
        <div className="error-content">
          <div className="error-popup-img">
            <img src="../Images/error-popup-img.png" />
            <p className="error-msg" style={{ color: "red" }}>{message}</p>
          </div>
          {!canClose && (
            <button className="manual-close-button" onClick={handleManualClose}>
              Done
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ErrorPopup;
