import React, { useEffect, useState, useContext } from "react";
import { IoChatboxEllipsesOutline, IoArrowBackOutline } from "react-icons/io5";
import { HiMiniAdjustmentsHorizontal } from "react-icons/hi2";
import Popup from "reactjs-popup";
import ProfilePopup from "../ProfilePopup";
import "../../css/matchesFeed.css";
import axios from "axios";
import apiUrl from "../../../../config";
import { Link, useNavigate } from "react-router-dom";
import { MyProfileContext } from "../../../../context/ProfileProvider";
import NavBar from "../DashBoard/NavBar";
import BottomNavbar from "../../../../components/BottomNavbar";
import AdvanceSearch from "../DashBoard/AdvanceSearch";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import ErrorPopup from "../../../../popups/ErrorPopup";
import SuccessPopUp from "../../../../popups/SuccessPopUp";
import Footer from "../../../../components/Footer";

const tabs = [
  { id: 1, name: "All" },
  { id: 2, name: "Favorites" },
  { id: 3, name: "Interests" },
];

const MatchesFeed = () => {
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const [showFilter, setShowFilter] = useState(false);
  const { myProfile, allProfiles, userProfile } = useContext(MyProfileContext);
  const [premiumProfiles, setPremiumProfiles] = useState([]);
  const [favouriteProfiles, setfavouriteProfiles] = useState([]);
  const [interestedProfiles, setInterestedProfiles] = useState([]);
  const [errors, setErrors] = useState("");
  const userProfileId = localStorage.getItem("profileId");
  const navigate = useNavigate();
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [favoritePage, setFavouritePage] = useState(1);
  const [interestPage, setInterestPage] = useState(1);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [showError, setShowError] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [success, setSuccess] = useState("");
  const itemsPerPage = 5;
  const totalPages = Math.ceil(premiumProfiles?.length / itemsPerPage);
  const favoritePages = Math.ceil(favouriteProfiles?.length / itemsPerPage);
  const IntersetPages = Math.ceil(interestedProfiles?.length / itemsPerPage);
  const FilteredPages = Math.ceil(filteredProfiles?.length / itemsPerPage);
  const AllProfilesPages = Math.ceil(allProfiles?.length / itemsPerPage);
  const AllProfilesNotFiltered = Math.ceil(allProfiles?.length / itemsPerPage);
  const [filteredCurrentPage, setFilteredCurrentPage] = useState(1);
  const [allCurrentPage, setAllCurrentPage] = useState(1);

  const [allProfilesPage, setAllProfilesPage] = useState(1);
  const startIndex = (filteredCurrentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const profilesToShowFilter = filteredProfiles.slice(startIndex, endIndex);
  const profileStartIndex = (allCurrentPage - 1) * itemsPerPage;
  const profileEndIndex = profileStartIndex + itemsPerPage;
  const AllprofilesToShow = allProfiles.slice(
    profileStartIndex,
    profileEndIndex
  );
  const AllprofileStartIndex = (allProfilesPage - 1) * itemsPerPage;
  const AllprofileEndIndex = Math.min(
    AllprofileStartIndex + itemsPerPage,
    allProfiles.length
  );
  const AllprofilesTo = allProfiles.slice(
    AllprofileStartIndex,
    AllprofileEndIndex
  );
  const handleFilteredPageChange = (newPage) => {
    setFilteredCurrentPage(newPage);
  };
  const handleAllPageChange = (newPage) => {
    setAllCurrentPage(newPage);
  };
  const handleAllProfilesPageChange = (newPage) => {
    setAllProfilesPage(newPage);
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handlePageChangeFavourite = (newPage) => {
    setFavouritePage(newPage);
  };
  const handlePageChangeInterest = (newPage) => {
    setInterestPage(newPage);
  };
  const handleFilterChange = (filteredProfiles) => {
    setFilteredProfiles(filteredProfiles);
  };
  const handleMobileFilterChange = (filteredProfiles) => {
    setFilteredProfiles(filteredProfiles);
  };

  const premiumProfile = () => {
    const premiumProfiles = allProfiles.filter(
      (profile) => profile.payment === true
    );
    setPremiumProfiles(premiumProfiles);
  };

  const InterestedProfiles = () => {
    setInterestedProfiles(myProfile?.additionalDetails?.chatInterest);
  };

  const favorite = () => {
    setfavouriteProfiles(myProfile?.additionalDetails?.favouriteProfiles);
  };

  const sendChatRequest = async (id) => {
    if (myProfile.payment) {
      const senderId = userProfileId;
      const receiverId = id;
      try {
        await axios.post(apiUrl + "v3/chat/send-request", {
          senderId,
          receiverId,
        });
        userProfile();
        setErrors("chat request sent successfully");
        handleShowError();
      } catch (err) {
        console.log(err);
        setErrors(err.response.data.message);
        handleShowError();
      }
    } else {
      navigate("/payments");
    }
  };

  const handleShowError = () => {
    setShowError(true);
  };

  const handleCloseError = () => {
    setShowError(false);
  };

  const handleShowSuccess = () => {
    setshowSuccess(true);
  };

  const handleCloseSuucess = () => {
    setshowSuccess(false);
  };

  const addFavourite = async (id) => {
    const favouriteId = id;
    try {
      await axios.patch(apiUrl + `v1/profile/favourite/${userProfileId}`, {
        favouriteId: favouriteId,
      });
      userProfile();
      setSuccess("Added Successfull to favourites");
      handleShowSuccess();
    } catch (err) {
      console.log(err);
      setErrors(err.response.data.message);
      handleShowError();
    }
  };

  const removeFavourite = async (id) => {
    const favouriteId = id;
    try {
      await axios.patch(apiUrl + `v1/profile/unfavourite/${userProfileId}`, {
        favouriteId: favouriteId,
      });
      userProfile();
      setSuccess("Removed from favourites");
      handleShowSuccess();
      setfavouriteProfiles((prevFavourites) =>
        prevFavourites.filter((fav) => fav.id !== id)
      );
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        setErrors(err.response.data.message);
      }
      console.error(err);
      handleShowError();
    }
  };

  const blockProfile = async (id) => {
    const blockid = id;
    try {
      await axios.patch(apiUrl + `v1/profile/blockProfile/${userProfileId}`, {
        blockId: blockid,
      });
      userProfile();
      setSuccess("The profile is blocked");
      handleShowSuccess();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        setErrors(err.response.data.message);
      }
      console.error(err);
      handleShowError();
    }
  };

  const calculateAge = (dateOfBirth) => {
    const birthDate = new Date(dateOfBirth);
    const currentDate = new Date();
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return {
      age,
    };
  };

  const ProfileCard = ({ eachProfile, myProfile }) => {
    const { age } = calculateAge(eachProfile.dateOfBirth);
    const verified = eachProfile.isVerified;
    return (
      <div className="matchesfeed-card" key={eachProfile.Profile}>
        <div className="matchesfeed-card-img">
          <Swiper
            spaceBetween={50}
            pagination={{ clickable: true }}
            modules={[Pagination]}
            className="mySwiper"
            style={{ height: "200px", width: "200px" }}
          >
            {Array.isArray(eachProfile.image) &&
              eachProfile.image.map((image, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={image}
                    className="shaadhi-img"
                    style={{
                      filter:
                        myProfile.payment === false ? "blur(4px)" : "none",
                    }}
                  />
                </SwiperSlide>
              ))}
          </Swiper>
          {verified && (
            <img
              className="verified-tag"
              src="./Images/varified-tag.svg"
              alt="Verified Tag"
            />
          )}
        </div>
        <div className="matchesfeed-card-body ">
          <div className="shaadhi-card-data-matches">
            <div className="matched-profiled-premium ">
              <h4 className="section-header">
                {eachProfile.firstName}{" "} {eachProfile.secondName}
              </h4>
              {eachProfile.payment ? (
                <div className="premium-badge-eachprofile">
                  <h1 className="premium-head">Premium</h1>{" "}
                  <span>
                    <img src="./Images/badge.svg" />
                  </span>
                </div>
              ) : null}
            </div>
            <hr />
            <div className="matchesfeed-card-datamain">
              <div className="shaadhi-card-datatext1">
                <span>{age}yrs</span>
                <span>{eachProfile.height}</span>
                <span>{eachProfile.religion}</span>
                <span>{eachProfile.caste}</span>
                <span>{eachProfile.motherTounge}</span>
                <span>{eachProfile.maritalStatus}</span>
                <span>{eachProfile.state}</span>
                <span>{eachProfile.city}</span>
                <span>{eachProfile.occupation}</span>
              </div>
            </div>
            <p className="matches-shaadhi-aboutMe">
              {eachProfile.aboutMe.substring(0, 85)}...
              <span>
                <>
                  <Popup
                    modal
                    className="popup-modal"
                    trigger={
                      <button className="Userview-profile-btn">
                        {" "}
                        View Profile{" "}
                      </button>
                    }
                    position="right top"
                  >
                    <div className="popup-container">
                      <ProfilePopup profileDetails={eachProfile} />
                    </div>
                  </Popup>{" "}
                </>
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderAllTabs = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const profilesToShow = premiumProfiles.slice(startIndex, endIndex);
    return (
      <div className="verified-cards-container">
        <h1 className="section-header">Verified</h1>
        <div className="verified-main-cards-container">
          {profilesToShow?.map((eachProfile) => {
            const [payment] = [eachProfile.payment !== "False"];
            const { age } = calculateAge(eachProfile.dateOfBirth);
            const verified = eachProfile.isVerified;
            return (
              <div className="matchesfeed-card" key={eachProfile.Profile}>
                <div className="matchesfeed-card-img">
                  <Swiper
                    spaceBetween={50}
                    pagination={{ clickable: true }}
                    modules={[Pagination]}
                    className="mySwiper"
                    style={{ height: "200px", width: "200px" }}
                  >
                    {Array.isArray(eachProfile.image) &&
                      eachProfile.image.map((image, index) => (
                        <SwiperSlide key={index}>
                          <img
                            src={image}
                            className="matchesfeed-img"
                            style={{
                              filter:
                                myProfile.payment === false
                                  ? "blur(4px)"
                                  : "none",
                            }}
                          />
                        </SwiperSlide>
                      ))}
                  </Swiper>
                  {verified && (
                    <img
                      className="verified-tag"
                      src="./Images/varified-tag.svg"
                    />
                  )}
                </div>
                <div className="matchesfeed-card-body ">
                  <div className="shaadhi-card-data-matches">
                    <div className="d-flex me-auto">
                      <h4 className="section-header">
                        {eachProfile.firstName} {eachProfile.secondName}{" "}
                      </h4>
                      <div className="premium-badge-home-mobile">
                        <img src="../Images/badge.svg" />
                      </div>
                    </div>
                    <hr />
                    <div className="matchesfeed-card-datamain">
                      <div className="matchesfeed-card-datatext1">
                        <span>{age}yrs</span>
                        <span>{eachProfile.height}</span>
                        <span>{eachProfile.religion}</span>
                        <span>{eachProfile.caste}</span>
                        <span>{eachProfile.motherTounge}</span>
                        <span>{eachProfile.maritalStatus}</span>
                        <span>{eachProfile.state}</span>
                        <span>{eachProfile.city}</span>
                        <span>{eachProfile.occupation}</span>
                      </div>
                    </div>
                    <p className="shaadhi-aboutMe">
                      {eachProfile.aboutMe.substring(0, 85)}...
                      <span>
                        <>
                          <Popup
                            modal
                            className="popup-modal"
                            trigger={
                              <button className="Userview-profile-btn">
                                {" "}
                                View Profile{" "}
                              </button>
                            }
                            position="right top"
                          >
                            <div className="popup-container">
                              <ProfilePopup profileDetails={eachProfile} />
                            </div>
                          </Popup>{" "}
                        </>
                      </span>
                    </p>
                  </div>
                  <div className="premium-badge-home-buttons">
                    <div className="profile-buttons-container">
                      {payment ? (
                        <div className="premium-badge">
                          <h1 className="premium-head">Premium</h1>{" "}
                          <span>
                            <img src="./Images/badge.svg" />
                          </span>
                        </div>
                      ) : null}
                      {payment ? (
                        <div className="profile-buttons">
                          <button
                            className="interested-btn"
                            onClick={() => sendChatRequest(eachProfile.id)}
                          >
                            Interested{" "}
                          </button>
                          <button
                            className="favorite-btn"
                            onClick={() => {
                              addFavourite(eachProfile.id);
                              renderFavoriteTabs();
                            }}
                          >
                            Favorite
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="pagination-container">
          {currentPage !== 1 && (
            <button
              className="pagenation-button me-3"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </button>
          )}
          <span className="page-Number me-3"> {currentPage}</span>
          {currentPage !== totalPages && (
            <button
              className="pagenation-button"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next{" "}
            </button>
          )}
        </div>
      </div>
    );
  };

  const renderFavoriteTabs = () => {
    const startIndex = (favoritePage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const profilesToShow = favouriteProfiles.slice(startIndex, endIndex);
    return (
      <div className="verified-cards-container">
        <h1 className="section-header">Your Favorites</h1>
        <div className="verified-main-cards-container">
          {profilesToShow?.map((eachProfile) => {
            const [payment] = [eachProfile.payment !== "False"];
            const { age } = calculateAge(eachProfile.dateOfBirth);
            const verified = eachProfile.isVerified;
            return (
              <div className="matchesfeed-card" key={eachProfile.Profile}>
                <div className="matchesfeed-card-img">
                  <Swiper
                    spaceBetween={50}
                    pagination={{
                      clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                    style={{ height: "200px", width: "200px" }}
                  >
                    {Array.isArray(eachProfile.image) &&
                      eachProfile.image.map((image, index) => (
                        <SwiperSlide key={index}>
                          <img
                            src={image}
                            className="matchesfeed-img"
                            style={{
                              filter:
                                myProfile.payment === false
                                  ? "blur(4px)"
                                  : "none",
                            }}
                          />
                        </SwiperSlide>
                      ))}
                  </Swiper>
                  {verified && (
                    <img
                      className="verified-tag"
                      src="./Images/varified-tag.svg"
                    />
                  )}
                </div>
                <div className="matchesfeed-card-body ">
                  <div className="shaadhi-card-data-matches">
                    <div className="d-flex me-auto">
                      <h4 className="section-header">
                        {eachProfile.firstName}
                        {eachProfile.secondName}{" "}
                      </h4>
                      <div className="premium-badge-home-mobile">
                        <img src="../Images/badge.svg" />
                      </div>
                    </div>
                    <hr />
                    <div className="matchesfeed-card-datamain">
                      <div className="matchesfeed-card-datatext1">
                        <span>{age}yrs</span>
                        <span>{eachProfile.height}</span>
                        <span>{eachProfile.religion}</span>
                        <span>{eachProfile.caste}</span>
                        <span>{eachProfile.motherTounge}</span>
                        <span>{eachProfile.maritalStatus}</span>
                        <span>{eachProfile.state}</span>
                        <span>{eachProfile.city}</span>
                        <span>{eachProfile.occupation}</span>
                      </div>
                    </div>
                    <p className="shaadhi-aboutMe">
                      {eachProfile.aboutMe.substring(0, 85)}...
                      <span>
                        <>
                          <Popup
                            modal
                            className="popup-modal"
                            trigger={
                              <button className="Userview-profile-btn">
                                {" "}
                                View Profile
                              </button>
                            }
                            position="right top"
                          >
                            <div className="popup-container">
                              <ProfilePopup profileDetails={eachProfile} />
                            </div>
                          </Popup>{" "}
                        </>
                      </span>
                    </p>
                  </div>
                  <div className="premium-badge-home-buttons">
                    <div className="profile-buttons-container">
                      {payment ? (
                        <div className="premium-badge">
                          <h1 className="premium-head">Premium</h1>
                          <span>
                            {" "}
                            <img src="./Images/badge.png" />{" "}
                          </span>
                        </div>
                      ) : null}
                      {payment ? (
                        <div className="profile-buttons">
                          <button
                            className="interested-btn"
                            onClick={() => sendChatRequest(eachProfile.id)}
                          >
                            Interested
                          </button>
                          <button
                            className="un-favorite-btn"
                            onClick={() => removeFavourite(eachProfile.id)}
                          >
                            {" "}
                            Un-Favorite
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="pagination-container">
          {favouriteProfiles.length > 0 ? (
            <>
              {favoritePage !== 1 && (
                <button
                  className="pagenation-button me-3"
                  onClick={() => handlePageChangeFavourite(favoritePage - 1)}
                >
                  Previous
                </button>
              )}
              <span className="page-Number me-3">{favoritePage}</span>
              {favoritePage !== favoritePages && (
                <button
                  className="pagenation-button"
                  onClick={() => handlePageChangeFavourite(favoritePage + 1)}
                >
                  Next
                </button>
              )}
            </>
          ) : null}
        </div>
      </div>
    );
  };

  const renderInterestTabs = () => {
    const startIndex = (interestPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const profilesToShow = interestedProfiles.slice(startIndex, endIndex);

    return (
      <div className="verified-cards-container">
        <h1 className="section-header">Your Interests</h1>
        <div className="verified-main-cards-container">
          {profilesToShow?.map((eachProfile) => {
            const { age } = calculateAge(eachProfile.dateOfBirth);
            const [payment] = [eachProfile.payment !== "False"];
            const verified = eachProfile.isVerified;
            return (
              <div className="matchesfeed-card" key={eachProfile.Profile}>
                <div className="matchesfeed-card-img">
                  <Swiper
                    spaceBetween={50}
                    pagination={{
                      clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                    style={{ height: "200px", width: "200px" }}
                  >
                    {Array.isArray(eachProfile.image) &&
                      eachProfile.image.map((image, index) => (
                        <SwiperSlide key={index}>
                          <img
                            src={image}
                            className="matchesfeed-img"
                            style={{
                              filter:
                                myProfile.payment === false
                                  ? "blur(4px)"
                                  : "none",
                            }}
                          />
                        </SwiperSlide>
                      ))}
                  </Swiper>
                  {verified && (
                    <img
                      className="verified-tag"
                      src="./Images/varified-tag.svg"
                    />
                  )}
                </div>
                <div className="matchesfeed-card-body ">
                  <div className="shaadhi-card-data-matches">
                    <div className="d-flex me-auto">
                      <h4 className="section-header">
                        {eachProfile.firstName}
                        {eachProfile.secondName}{" "}
                      </h4>
                      <div className="premium-badge-home-mobile">
                        <img src="../Images/badge.svg" />
                      </div>
                    </div>
                    <hr />
                    <div className="matchesfeed-card-datamain">
                      <div className="matchesfeed-card-datatext1">
                        <span>{age}yrs</span>
                        <span>{eachProfile.height}</span>
                        <span>{eachProfile.religion}</span>
                        <span>{eachProfile.caste}</span>
                        <span>{eachProfile.motherTounge}</span>
                        <span>{eachProfile.maritalStatus}</span>
                        <span>{eachProfile.state}</span>
                        <span>{eachProfile.city}</span>
                        <span>{eachProfile.occupation}</span>
                      </div>
                    </div>
                    <p className="shaadhi-aboutMe">
                      {eachProfile.aboutMe.substring(0, 85)}...
                      <span>
                        <>
                          <Popup
                            modal
                            className="popup-modal"
                            trigger={
                              <button className="Userview-profile-btn">
                                {" "}
                                View Profile{" "}
                              </button>
                            }
                            position="right top"
                          >
                            <div className="popup-container">
                              <ProfilePopup profileDetails={eachProfile} />
                            </div>
                          </Popup>{" "}
                        </>
                      </span>
                    </p>
                  </div>
                  <div className="premium-badge-home-buttons">
                    <div className="profile-buttons-container">
                      {payment ? (
                        <div className="premium-badge">
                          <h1 className="premium-head">Premium</h1>
                          <span>
                            <img src="./Images/badge.png" />{" "}
                          </span>
                        </div>
                      ) : null}
                      {payment ? (
                        <div className="matches-chat-notintersets-btn">
                          <Link to="/chatpage">
                            {" "}
                            <button
                              className="chat-interested-btn"
                              style={{
                                color: "#ffffff",
                                textDecoration: "none",
                              }}
                            >
                              Chat{" "}
                              <IoChatboxEllipsesOutline className="chat-icon" />
                            </button>
                          </Link>
                          <button
                            className="blocked-btn"
                            onClick={() => blockProfile(eachProfile.id)}
                          >
                            Block
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="pagination-container">
          {interestedProfiles.length > 0 ? (
            <>
              {interestPage !== 1 && (
                <button
                  className="pagenation-button me-3"
                  onClick={() => handlePageChangeInterest(interestPage - 1)}
                >
                  Previous
                </button>
              )}
              <span className="page-Number me-3">{interestPage}</span>
              {interestPage !== IntersetPages && (
                <button
                  className="pagenation-button"
                  onClick={() => handlePageChangeInterest(interestPage + 1)}
                >
                  Next
                </button>
              )}
            </>
          ) : null}
        </div>
      </div>
    );
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 1:
        return renderAllTabs();
      case 2:
        return renderFavoriteTabs();
      case 3:
        return renderInterestTabs();
      default:
        return null;
    }
  };

  const filterContainerLabel = () => {
    return (
      <button
        className="filter-container-label"
        onClick={() => setShowFilter(true)}
      >
        {" "}
        Filter <HiMiniAdjustmentsHorizontal />
      </button>
    );
  };

  const viewMoreAndShowFilter = () => {
    setShowFilter(false);
  };

  const applyFilters = () => {
    setShowFilter(false);
  };

  useEffect(() => {
    premiumProfile();
    favorite();
    InterestedProfiles();
    userProfile();
  }, [activeTab, allProfiles]);

  return (
    <div>
      <div className="sticky-top">
        {" "}
        <NavBar />
      </div>
      <div className="container-fluid">
        <div className="container">
          {showFilter ? (
            <div className="responsive-filter-menu">
              <div className="filter-main-header">
                <button onClick={viewMoreAndShowFilter}>
                  <IoArrowBackOutline />
                </button>
                <h1>Filters</h1>{" "}
              </div>
              <div className="filters-main-content ">
                {" "}
                {<div className="">
                  <AdvanceSearch onFilterChange={handleMobileFilterChange} />
                  </div>}
              </div>
              <div className="responsive-filter-buttons">
                <button
                  onClick={() => {
                    applyFilters();
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          ) : (
            <>
              <div className="matches-page">
                <div className="adv-search ">
                  {" "}
                  {<AdvanceSearch onFilterChange={handleFilterChange} />}
                </div>
                <div className="matches-main-container">
                  <div className="header-container">
                    <p>
                      {" "}
                      Matches/{" "}
                      <span className="section-header">
                        {myProfile.firstName} {myProfile.secondName}
                      </span>{" "}
                    </p>
                    <h1 className="section-header">
                      {filteredProfiles
                        ? `${filteredProfiles.length} Matches based on your preference`
                        : "0 Matches based on your preference"}
                    </h1>
                  </div>
                  <div className="matches-tabs-container">
                    <ul>
                      {tabs.map((eachTab) => (
                        <li
                          key={eachTab.id}
                          className={
                            activeTab === eachTab.id
                              ? "matches-active-tab"
                              : "matches-inactive-tab"
                          }
                          onClick={() => setActiveTab(eachTab.id)}
                        >
                          {eachTab.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                  {filterContainerLabel()}
                  <div className="verified-cards-container">
                    {" "}
                    {renderTabContent()}{" "}
                  </div>
                  <div className="cards-container">
                    <h1 className="section-header">Based on your preference</h1>
                    <div className="main-cards-container">
                      {filteredProfiles && filteredProfiles.length > 0
                        ? profilesToShowFilter.map((eachProfile) => {
                            return (
                              <ProfileCard
                                key={eachProfile.Profile}
                                eachProfile={eachProfile}
                                myProfile={myProfile}
                              />
                            );
                          })
                        : AllprofilesToShow.map((eachProfile) => {
                            return (
                              <ProfileCard
                                key={eachProfile.Profile}
                                eachProfile={eachProfile}
                                myProfile={myProfile}
                              />
                            );
                          })}
                    </div>
                    <div className="pagination-container">
                      {filteredProfiles && filteredProfiles.length > 0 && (
                        <>
                          {filteredCurrentPage !== 1 && (
                            <button
                              className="pagenation-button me-3"
                              onClick={() =>
                                handleFilteredPageChange(
                                  filteredCurrentPage - 1
                                )
                              }
                            >
                              Previous
                            </button>
                          )}
                          <span className="page-number me-3">
                            {filteredCurrentPage}
                          </span>
                          {filteredCurrentPage !== FilteredPages && (
                            <button
                              className="pagenation-button"
                              onClick={() =>
                                handleFilteredPageChange(
                                  filteredCurrentPage + 1
                                )
                              }
                            >
                              Next
                            </button>
                          )}
                        </>
                      )}
                    </div>
                    <div className="pagination-container">
                      {!filteredProfiles ||
                        (filteredProfiles.length === 0 && (
                          <>
                            {allCurrentPage !== 1 && (
                              <button
                                className="pagenation-button me-3"
                                onClick={() =>
                                  handleAllPageChange(allCurrentPage - 1)
                                }
                              >
                                Previous
                              </button>
                            )}
                            <span className="page-Number me-3">
                              {allCurrentPage}
                            </span>
                            {allCurrentPage !== AllProfilesPages && (
                              <button
                                className="pagenation-button"
                                onClick={() =>
                                  handleAllPageChange(allCurrentPage + 1)
                                }
                              >
                                Next
                              </button>
                            )}
                          </>
                        ))}
                    </div>
                  </div>
                  <div className="cards-container">
                    <h1 className="section-header">All Matches</h1>
                    <div className="main-cards-container">
                      {AllprofilesTo.map((eachProfile) => {
                        return (
                          <ProfileCard
                            key={eachProfile.Profile}
                            eachProfile={eachProfile}
                            myProfile={myProfile}
                          />
                        );
                      })}
                    </div>
                  </div>
                  <div className="pagination-container">
                    <>
                      {allProfilesPage !== 1 && (
                        <button
                          className="pagenation-button me-3"
                          onClick={() =>
                            handleAllProfilesPageChange(allProfilesPage - 1)
                          }
                        >
                          Previous
                        </button>
                      )}
                      <span className="page-Number me-3">
                        {allProfilesPage}
                      </span>
                      {allProfilesPage !== AllProfilesNotFiltered && (
                        <button
                          className="pagenation-button"
                          onClick={() =>
                            handleAllProfilesPageChange(allProfilesPage + 1)
                          }
                        >
                          Next
                        </button>
                      )}
                    </>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {showSuccess && (
        <SuccessPopUp message={success} onClose={handleCloseSuucess} />
      )}
      {showError && <ErrorPopup message={errors} onClose={handleCloseError} />}
      <div className=" sticky-bottom">
        <BottomNavbar />
      </div>
      <div className="mt-5">
        <Footer />
      </div>
    </div>
  );
};
export default MatchesFeed;
