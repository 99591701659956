import React from "react";
import { NavLink } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import "../../../Home/css/Navbar.css";

const NavBar = () => {
  return (
    <div className="container-fluid dashboard-navbar">
      <div className="container">
        <div className="navbar-mainhome">
          <div className="">
            <div className="login-navbar-mobile">
            <img
              src="../images/maangalya.png"
              height="50px"
              width="200px"
              alt="Logo"
            />
            </div>
            
          </div>
          <div className="user-login-navbar">
          <nav className="navbar navbar-expand-lg">
            <div>
              <NavLink exact to="/" className="navbar-brand">
                <img
                  src="../images/maangalya.png"
                  height="50px"
                  width="200px"
                  alt="Logo"
                />
              </NavLink>
            </div>
            <button
              className="navbar-toggler ms-auto"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="">
                <svg
                  width="40px"
                  height="40px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 6H20M4 12H20M4 18H20"
                    stroke="#000000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </button>
            {/* <div className="navvbar-inputfield">
              <button className="navbar-btn">{<CiSearch />}</button>
              <input type="search" placeholder="Type to search" />
            </div> */}
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-lg-0">
                <li className="nav-item">
                  <NavLink exact to="/" className="nav-link">
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/matches" className="nav-link">
                    Matches
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/chatpage" className="nav-link">
                    Chats
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/Payments" className="nav-link">
                    Plans
                  </NavLink>
                </li>
              </ul>
            </div>
          </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
