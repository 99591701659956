import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { CiSearch } from 'react-icons/ci';
import './Allusers.css';
import { MyProfileContext } from '../../../../context/ProfileProvider';
import PopUp from '../PopUp';

export const Allusers = () => {
    const [search, setSearch] = useState('');
    const [dateFilter, setDateFilter] = useState('');
    const { allProfiles } = React.useContext(MyProfileContext);

    const calculateAge = (dateOfBirth) => {
        const birthDate = new Date(dateOfBirth);
        const currentDate = new Date();
        let age = currentDate.getFullYear() - birthDate.getFullYear();
        if (
            currentDate.getMonth() < birthDate.getMonth() ||
            (currentDate.getMonth() === birthDate.getMonth() &&
                currentDate.getDate() < birthDate.getDate())
        ) {
            age--;
        }
        return {
            age,
        };
    };

    const handleDateChange = (e) => {
        setDateFilter(e.target.value);
    };

    const currentDate = new Date().toISOString().split('T')[0];
    const filteredProfiles = allProfiles.filter((val) => {
        const fullName = `${val.firstName} ${val.secondName}`.toLowerCase();
        const email = val.email ? val.email.toLowerCase() : '';
        const mobile = val.mobile ? val.mobile.toString().toLowerCase() : '';
        const createdAt = new Date(val.createdAt).toLocaleString();

        return (
            fullName.includes(search.toLowerCase()) ||
            email.includes(search.toLowerCase()) ||
            mobile.includes(search.toLowerCase()) ||
            (dateFilter && createdAt.includes(dateFilter))
        );
    });
console.log(filteredProfiles,"profiel")

    return (
        <>
            <div className="user-inputfield">
                <button className="navbar-btn">{<CiSearch />}</button>
                <input
                    type='text'
                    placeholder='Search by name, email, or mobile'
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
                <input
                    type='date'
                    placeholder='Filter by date'
                    value={dateFilter}
                    onChange={handleDateChange}
                    max={currentDate} // Set the max attribute to today's date
                />
            </div>
            <div className='d-flex flex-wrap'>
                {filteredProfiles.map((val) => {
                    const { age } = calculateAge(val.dateOfBirth);
                    const showRedDot = val.verificationProof && val.verificationProof.length > 0 && !val.isVerified;
                    return (
                        <div className='main-admincard-container' key={val.id}>
                            <div className='alluser-container'>
                                <div className='first-content-all d-flex'>
                                    <div>
                                        <img className='ign-img' src={val.image[0]} alt='No image'></img>
                                    </div>
                                    <div className='second-content'>
                                        <h6>
                                            {val.firstName}
                                            <br />
                                            {val.secondName}
                                        </h6>
                                        <p>
                                            {age} years | {val.height}
                                        </p>
                                        <p>{val.caste} </p>
                                        <p> {val.occupation}</p>
                                        <Popup
                                            modal
                                            className='popup-modal'
                                            trigger={<button className='view-profile-btn'>View Profile</button>}
                                            position='right top'
                                        >
                                            <div className='popup-container'>
                                                <PopUp profileDetails={val} />
                                            </div>
                                        </Popup>
                                    </div>
                                    {showRedDot && <div className='red-dot'></div>}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};
