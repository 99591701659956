import axios from "axios";
import React, { useState } from "react";
import apiUrl from "../../../../../config";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useNavigate, useResolvedPath } from "react-router-dom";

export const Mail = () => {
  const userProfileId = localStorage.getItem("profileId");
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [errors, setErrors] = useState("");

  const [email, setEmail] = useState({
    oldEmail: "",
    newEmail: "",
  });

  const [mobile, setMobile] = useState({
    oldMobile: "",
    newMobile: "",
  });

  const onFormSubmit = (e) => {
    e.preventDefault();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmail((prevEmail) => ({ ...prevEmail, [name]: value }));
    const isValidEmail = value && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) && /@gmail\.com$/.test(value);
    if (isValidEmail) {
      setEmailError("");
    }
  };

  const handleChangeMobile = (e) => {
    const { name, value } = e.target;
    setMobile((prevMobile) => ({ ...prevMobile, [name]: value }));
    const isValidMobile = value.length === 10 && /^\d+$/.test(value) ;
    if (isValidMobile) {
      setMobileError("");
    }
  };

  const changeEmail = async () => {
    try {
      if (email.oldEmail === "" || email.newEmail === "") {
        setEmailError("Please fill in both current and new email fields.");
        return;
      } else {
        const res = await axios.patch(
          apiUrl + `v1/profile/updateProfile/${userProfileId}`,
          email
        );
        if ((res.data = true)) {
          setEmail({ oldEmail: "", newEmail: "" });
          setErrors("");
        }
      }
    } catch (err) {
      console.log(err);
      setErrors(
        err.response?.data?.message || "An error occurred while updating email."
      );
    }
  };

  const changeMobile = async () => {
    try {
      if (mobile.oldMobile === "" || mobile.newMobile === "") {
        setMobileError("Please fill in both current and new mobile number fields.");
        return;
      }else{
      const res = await axios.patch(
        apiUrl + `v1/profile/updateProfile/${userProfileId}`,
        mobile
      );
      if ((res.data = true)) {
        setMobile({ oldMobile: "", newMobile: "" });
        setErrors("");
      }
    }
    } catch (err) {
      console.log(err);
      setErrors(
        err.response?.data?.message ||
          "An error occurred while updating mobile number."
      );
    }
  };
  const { pathname } = useResolvedPath();
  const back = () => {
    navigate("/settings");
  };

  return (
    <>
      <div className="Email-Phone-Number-container">
        <button onClick={back} className="Email-Phone-Number-container-icon">
          <FaLongArrowAltLeft />
        </button>
        <h4 className="mobive-view-btns">Email & Phone Number</h4>
      </div>
      <div className="mail-container">
        <div className="mail-container-head">
          <h2 className="head-name settings-heading-name">
            Update Your Contact Information
          </h2>
          <p className="mail-container-content settings-sub-heading-name">
            Change your email or phone number hassle-free! Just a few clicks,
            and you're all set. Keep your info current and stay connected easily
            on <span className="head-pass">MAANGALYAAM</span>.
          </p>
        </div>
        <form onSubmit={onFormSubmit} className="mb-2">
          <div className="mail-contentone">
            <div className="mail-formone">
              <label className="lab-mail">Current Email</label>
              <input
               type="email"
                className="inp-mail form-control"
                placeholder="Enter Here"
                name="oldEmail"
                onChange={handleChange}
                value={email.oldEmail}
              />
            </div>
            <div className="mail-formone">
              <label className="lab-mail">New Email</label>
              <input
              type="email"
                className="inp-mail form-control"
                placeholder="Enter Here"
                name="newEmail"
                onChange={handleChange}
                value={email.newEmail}
              />
            </div>
            <div className="mail-varify-btn">
              <button className="btn-mail" onClick={changeEmail}>
                Save
              </button>
            </div>
          </div>
          {errors &&
            (errors === "Incorrect Email" ||
              errors === "Emails can't be the same" ||
              errors === "This New Email is already exist") && (
              <p style={{ color: "red", margin: "0px" }}>{errors}</p>
            )}
          {emailError && (
            <p style={{ color: "red", margin: "0px" }}>{emailError}</p>
          )}
        </form>
        <form onSubmit={onFormSubmit} className="mt-2 ">
          <div className="mail-contentone">
            <div className="mail-formone">
              <label className="lab-mail">Current Mobile Number</label>
              <input
              type="number"
                className="inp-mail form-control"
                value={mobile.oldMobile}
                placeholder="Enter Here"
                name="oldMobile"
                onInput={(e) => {
                  const numericValue = e.target.value
                    .replace(/\D/g, "")
                    .slice(0, 10);
                  handleChangeMobile({
                    target: { name: "oldMobile", value: numericValue },
                  });
                }}
                onKeyDown={(e) => {
                  if (
                    !(e.key === "Backspace" || e.key === "Delete") &&
                    !/[\d]/.test(e.key)
                  ) {
                    e.preventDefault();
                  }
                }}
             
              />
            </div>
            <div className="mail-formone">
              <label className="lab-mail">New Mobile Number</label>
              <input
                type="number"
                className="inp-mail form-control"
                value={mobile.newMobile}
                placeholder="Enter Here"
                name="newMobile"
                onInput={(e) => {
                  const numericValue = e.target.value
                    .replace(/\D/g, "")
                    .slice(0, 10);
                  handleChangeMobile({
                    target: { name: "newMobile", value: numericValue },
                  });
                }}
                onKeyDown={(e) => {
                  if (
                    !(e.key === "Backspace" || e.key === "Delete") &&
                    !/[\d]/.test(e.key)
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
            <div className="mail-varify-btn">
              <button className="btn-mail" onClick={changeMobile}>
                Save
              </button>
            </div>
          </div>
          {errors &&
            (errors === "Incorrect Mobile Number" ||
              errors === "Mobile Numbers can't be the same" ||
              errors === "This New Mobile Number is already exist") && (
              <p style={{ color: "red", margin: "0px" }}>{errors}</p>
            )}
          {mobileError && (
            <p style={{ color: "red", margin: "0px" }}>{mobileError}</p>
          )}
        </form>
      </div>
    </>
  );
};
