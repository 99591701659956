import axios from "axios";
import React, { useState } from "react";
import apiUrl from "../../config";
import "../../pages/Home/css/contactus.css";
import Footer from "../Footer";
import NavBar from "../../pages/Home/components/DashBoard/NavBar";
import Navbar from "../Navbar";

const FeedbackForm = () => {
  const [details, setDetails] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const isLoggedIn=localStorage.getItem("profileId")
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      const lettersOnly = value.replace(/[^A-Za-z]/g, "");
      setDetails((prevDetails) => ({ ...prevDetails, [name]: lettersOnly }));
    } else {
      setDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
    }
  };

  const handleSubmit = async () => {
    try {
      let formErrors = {};

      if (!details.name) {
        formErrors.name = "Please enter your name.";
      }

      if (!details.email) {
        formErrors.email = "Please enter your email.";
      }

      if (!details.subject) {
        formErrors.subject = "Please select a subject.";
      }

      if (!details.message) {
        formErrors.message = "Please enter your message.";
      }

      if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
        return;
      }

      const response = await axios.post(apiUrl + "v4/feedback/feedback", details);
      if (response.data === true) {
        setDetails({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
        setErrors({});
      }
    } catch (err) {
      console.error("API Error:", err);
      if (err.response) {
        setErrors({ api: err.response.data.message });
      }
    }
  };
console.log(isLoggedIn,"dfghjkl")
  return (
    <>
      {isLoggedIn ? (
        <div className="sticky-top">
          <NavBar />
        </div>
      ) : (
        <div className="sticky-top">
          <Navbar />
        </div>
      )}
      <div className="container-fluid">
        <div className="container feedback-container">
          <div className="contactus-form-container">
            <h1 className="feedback-form">Feedback Form</h1>
            <p className="feedback-form-content">
              If you prefer, you can also use the form below to send us your
              feedback or queries.
            </p>

            <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
              <div className="form-container">
                <div className="form-div-container">
                  <label className="form-label">Name</label>
                  <input
                    name="name"
                    type="text"
                    value={details.name}
                    onChange={handleChange}
                    className="contact-form-input form-control"
                    placeholder="Enter Name"
                  />
                  {errors.name && <p style={{ color: "red" }}>{errors.name}</p>}
                </div>

                <div className="form-div-container">
                  <label className="form-label">Email</label>
                  <input
                    name="email"
                    value={details.email}
                    onChange={handleChange}
                    type="email"
                    className="contact-form-input form-control"
                    placeholder="Enter Email"
                  />
                  {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
                </div>
              </div>

              <div className="form-div-container w-50">
                <label className="form-label">Subject</label>
                <select
                  name="subject"
                  value={details.subject}
                  onChange={handleChange}
                  className="contact-form-input form-control"
                >
                  <option value="">Select a name</option>
                  <option value="General Inquiry">General Inquiry</option>
                  <option value="Technical Support">Technical Support</option>
                  <option value="Assistance Customer">Assistance Customer</option>
                  <option value="Support Request">Support Request</option>
                  <option value="Business Partnership">Business Partnership</option>
                  <option value="Inquiry Feedback or Suggestions">Inquiry Feedback or Suggestions</option>
                  <option value="Report a Problem">Report a Problem</option>
                  <option value="Advertising Inquiry">Advertising Inquiry</option>
                  <option value="Membership/Subscription Inquiry">Membership/Subscription Inquiry</option>
                  <option value="Media/Press Inquiry">Media/Press Inquiry</option>
                  <option value="Other Inquiry">Other Inquiry</option>
                </select>
                {errors.subject && <p style={{ color: "red" }}>{errors.subject}</p>}
              </div>

              <div className="form-div-container">
                <label className="form-label">Message</label>
                <textarea
                  name="message"
                  value={details.message}
                  onChange={handleChange}
                  className="contact-form-input form-control"
                  placeholder="Enter Message"
                ></textarea>
                {errors.message && <p style={{ color: "red" }}>{errors.message}</p>}
              </div>
              <div className="contactu-submit-div">
                <button
                  type="submit"
                  className="contactu-submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <Footer />
      </div>
    </>
  );
};

export default FeedbackForm;
