import React from "react";
import "../css/termsConditions.css";
import Footer from "../../../../src/components/Footer"
import NavBar from "./DashBoard/NavBar";
import Navbar from "../../../components/Navbar";
const PrivacyPolicy = () => {
  const isLoggedIn = localStorage.getItem("profileId");
  console.log(isLoggedIn,"sdfghjkl;")
  return (
    <>
      <div>
        {isLoggedIn ? (
          <div className="sticky-top">
            <NavBar />
          </div>
        ) : (
          <div className="sticky-top">
            <Navbar />
          </div>
        )}
      </div>
      <div className="container-fluid">
        <div className="container">
          <div className="terms-main-container">
            <h1 className="term-main-heading">
              Privacy Policy for <span className="name-color">MAANGALYAM</span>
            </h1>
            <p className="term-content">Last Updated: 09-02-2024</p>

            <h2 className="term-heading"> Information We Collect</h2>
            <p className="term-content">
              <strong className="term-sub-heading">
                {" "}
                Personal Information:
              </strong>{" "}
              We may collect personal information such as your name, email
              address, location, and other details you provide when you register
              for an account or use our services.
            </p>
            <p className="term-content">
              <strong className="term-sub-heading">Profile Information:</strong>{" "}
              Users may choose to provide additional information in their
              profiles, including photos, interests, and other details.
            </p>
            <p className="term-content">
              <strong className="term-sub-heading"> Communication Data:</strong>{" "}
              We collect information exchanged between users through our
              communication features, including messages and other content.
            </p>

            <h2 className="term-heading"> How We Use Your Information</h2>
            <p className="term-content">
              <strong className="term-sub-heading">
                {" "}
                Matching and Connecting:
              </strong>{" "}
              We use your information to match and connect users based on their
              preferences and interests.
            </p>
            <p className="term-content">
              <strong className="term-sub-heading">Communication:</strong> Your
              information may be used to facilitate communication between users
              on the platform.
            </p>
            <p className="term-content">
              <strong className="term-sub-heading">
                Improvement of Services:
              </strong>{" "}
              We use data to analyze and improve our services, including user
              experience and features.
            </p>

            <h2 className="term-heading"> Sharing Your Information</h2>
            <p className="term-content">
              <strong className="term-sub-heading">With Your Consent:</strong>{" "}
              We may share your information with other users based on your
              consent.
            </p>
            <p className="term-content">
              <strong className="term-sub-heading">Service Providers:</strong>{" "}
              We may share information with third-party service providers who
              assist us in providing and improving our services.
            </p>
            <p className="term-content">
              <strong className="term-sub-heading"> Legal Compliance:</strong>{" "}
              We may disclose your information if required by law or to protect
              our rights and the rights of other users.
            </p>

            <h2 className="term-heading"> Security</h2>
            <p className="term-content">
              <strong className="term-sub-heading">Data Security:</strong> We
              take reasonable measures to protect your information from
              unauthorized access, disclosure, alteration, or destruction.
            </p>

            <h2 className="term-heading"> Your Choices</h2>
            <p className="term-content">
              <strong className="term-sub-heading">Profile Settings:</strong>{" "}
              You can manage and update your profile settings to control the
              information shared on the platform.
            </p>
            <p className="term-content">
              <strong className="term-sub-heading">
                {" "}
                Communication Preferences:
              </strong>{" "}
              You can choose your communication preferences and opt-out of
              certain communications.
            </p>

            <h2 className="term-heading"> Changes to this Privacy Policy</h2>
            <p className="term-content">
              <strong className="term-sub-heading">Updates:</strong> We may
              update this Privacy Policy periodically. The date at the top of
              this policy indicates the last update.
            </p>

            <h2 className="term-heading"> Contact Us</h2>
            <p className="term-content">
              <strong className="term-sub-heading"> Questions:</strong> If you
              have any questions or concerns regarding this Privacy Policy,
              please contact us at .
            </p>

            <p className="term-content">
              By using our services, you acknowledge that you have read and
              understood this Privacy Policy.
            </p>

            <p className="term-content">
              Maangalaaym
              <br />
        
            </p>
          </div>
        </div>
      </div> 
      <div className="mt-5">
        <Footer />
      </div>
    </>
  );
};

export default PrivacyPolicy;
