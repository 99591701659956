import React, { useEffect, useState } from "react";
import AdvanceSearch from "./AdvanceSearch";
import HomeCenter from "./HomeCenter";
import { Profile } from "../DashBoard/Profile";
import "../../../../../src/pages/Home/css/advancesearch.css";
import NavBar from "./NavBar";
import BottomNavbar from "../../../../components/BottomNavbar";
import Footer from "../../../../components/Footer";

const MainHome = () => {
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const handleFilterChange = (filteredProfiles) => {
    setFilteredProfiles(filteredProfiles);
  };

  useEffect(() => {
    setScreenWidth(window.innerWidth);
  }, []);

  return (
    <>
      <div className="sticky-top ">
        <NavBar />
      </div>
      <div className="container-fluid ">
        <div className="container main-home-container">
          <div className="Mainheader-container">
            <div className="mainhome-advancesearch-box" >
              <div className="">
              {screenWidth > 767 && <AdvanceSearch onFilterChange={handleFilterChange} />}
              </div>
             
            </div>
            <div>
              <HomeCenter filteredProfiles={filteredProfiles} />
            </div>
       
            <div className="profile-component ">
              <div className="sticky">
              <Profile />
              </div>
          
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <Footer/>
      </div>
      <div className=" sticky-bottom">
        <BottomNavbar />
      </div>
    </>
  );
};

export default MainHome;