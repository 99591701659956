import axios from "axios";
import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import apiUrl from "../../../../../config";
import { MyProfileContext } from "../../../../../context/ProfileProvider";
import ProfilePopup from "../../ProfilePopup";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import SuccessPopUp from "../../../../../popups/SuccessPopUp";
import ErrorPopup from "../../../../../popups/ErrorPopup";

export const Blocked = () => {
  const navigate = useNavigate();
  const { myProfile, userProfile } = React.useContext(MyProfileContext);
  const userProfileId = localStorage.getItem("profileId");
  const [blockedProfiles, setBlockedProfiles] = useState([]);
  const [showSuccess, setshowSuccess] = useState(false);
  const [success, setSuccess] = useState("");
  const [errors, setErrors] = useState("");
  const [showError, setShowError] = useState(false);
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.max(1, Math.ceil(blockedProfiles?.length / itemsPerPage));
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const allBlockedProfiles = async () => {
    setBlockedProfiles(myProfile?.additionalDetails?.blockedProfiles || []);
  };

  useEffect(() => {
    allBlockedProfiles();
  }, [myProfile]);

  const handleShowError = () => {
    setShowError(true);
  };

  const handleCloseError = () => {
    setShowError(false);
  };


  const handleShowSuccess = () => {
    setshowSuccess(true);
  };

  const handleCloseSuucess = () => {
    setshowSuccess(false);
  };

  const unblockProfile = async (id) => {
    try {
      const blockId = id;
      await axios.patch(apiUrl + `v1/profile/unblockProfile/${userProfileId}`, {blockId,});
      setBlockedProfiles((prevBlockedProfiles) => prevBlockedProfiles.filter((profile) => profile.id !== id));
      setSuccess("The profile is unblocked");
      handleShowSuccess();
    } catch (err) {
      console.error(err);
      setErrors(
        err.response?.data?.message ||
        "An error occurred while unblocking the profile"
      );
      handleShowError();
    }
  };

  const calculateAge = (dateOfBirth) => {
    const birthDate = new Date(dateOfBirth);
    const currentDate = new Date();
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  return (
    <>
      <div className="Email-Phone-Number-container">
        <button
          onClick={() => navigate("/settings")}
          className="Email-Phone-Number-container-icon"
        >
          <FaLongArrowAltLeft />
        </button>
        <h4 className="mobive-view-btns">Blocked Profiles</h4>
      </div>
      <div className="blocked-container">
        <h6 className="head-block">Blocked Profiles</h6>
        <div className="blocked-cards">
          {blockedProfiles.map((data) => {
            const verified = data.isVerified;
            const age = calculateAge(data.dateOfBirth);
            return (
              <div className="shaadhi-card" key={data.id}>
                <div className="shaadhi-card-body ">
                  <div className="shaadhi-card-img">
                    <Swiper
                      spaceBetween={50}
                      pagination={{ clickable: true }}
                      modules={[Pagination]}
                      className="mySwiper"
                      style={{ height: "200px", width: "200px" }}
                    >
                      {data.image &&
                        data.image?.map((image, index) => (
                          <SwiperSlide key={index}>
                            <img
                              src={image}
                              className="shaadhi-img"
                              style={{
                                filter:
                                  myProfile.payment === false
                                    ? "blur(4px)"
                                    : "none",
                              }}
                              alt={`Image ${index + 1}`}
                            />
                          </SwiperSlide>
                        ))}
                    </Swiper>
                    {verified && (
                      <img
                        className="verified-tag"
                        src="../Images/varified-tag.svg"
                        alt="Verified Tag"
                      />
                    )}
                  </div>
                  <div className="shaadhi-card-data">
                    <div className="premium-badge-home-container">
                      <h4 className="section-header">
                        {data.firstName}
                        {data.secondName}
                      </h4>
                      {data.payment && (
                        <div className="premium-badge-home">
                          <img src="../Images/badge.svg" />
                        </div>
                      )}
                    </div>
                    <hr />
                    <div className="shaadhi-card-datamain">
                      <div className="shaadhi-card-datatext1">
                        <span>{age} years</span>
                        <span>{data.height}</span>
                        <span>{data.religion}</span>
                        <span>{data.caste}</span>
                        <span>{data.motherTounge}</span>
                        <span>{data.maritalStatus}</span>
                        <span>{data.state}</span>
                        <span>{data.city}</span>
                        <span>{data.occupation}</span>
                      </div>
                    </div>
                    <p className="homecenter-popup-aboutme">
                      {data.aboutMe.substring(0, 70)}...
                      <span>
                        <>
                          <Popup
                            modal
                            className="popup-modal"
                            trigger={
                              <button className="Userview-profile-btn">
                                View Profile
                              </button>
                            }
                            position="right top"
                          >
                            <div className="popup-container">
                              <ProfilePopup profileDetails={data} />
                            </div>
                          </Popup>{" "}
                        </>
                      </span>
                    </p>
                    <div className="blocked-profile-btn">
                      <button
                        className="btn-block"
                        onClick={() => unblockProfile(data.id)}
                      >
                        Unblock
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="pagination-container">
          {currentPage !== 1 && (
            <button
              className="pagenation-button me-3"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </button>
          )}
          <span className="page-Number-blocked me-3"> {currentPage}</span>
          {currentPage !== totalPages && (
            <button
              className="pagenation-button"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next{" "}
            </button>
          )}
        </div>
        {showError && (
          <ErrorPopup message={errors} onClose={handleCloseError} />
        )}
        {showSuccess && (
          <SuccessPopUp message={success} onClose={handleCloseSuucess} />
        )}
      </div>
    </>
  );
};
