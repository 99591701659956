import React, { useState, useRef, useEffect } from "react";
import "./css/registration.css";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import apiUrl from "../../config";
import DetailsData from "../../data/State.json";
import Religion from "../../data/Religion.json";

export const Registration = () => {
  let [step, setStep] = useState(1);
  let [selectedItem, setSelectedItem] = useState("");
  const [selectButton, SetSelectButton] = useState(null);
  const fileInputRef = useRef(null);
  const [selectedState, setSelectedState] = useState("");
  const [dateOfBirthError, setDateOfBirthError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [errors, setErrors] = useState("");
  const location = useLocation();
  const data = location.state;
  console.log(data, "dta")
  const [details, setDetails] = useState({
    profile: "" || data?.profile,
    gender: "" || data?.gender,
    firstName: "" || data?.firstName,
    secondName: "" || data?.secondName,
    dateOfBirth: "",
    mobile: "" || data?.mobile,
    email: "",
    motherTounge: "",
    country: "",
    state: "",
    city: "",
    caste: "",
    subCaste: "",
    religion: "",
    maritalStatus: "",
    height: "",
    familyType: "",
    familyStatus: "",
    disabilities: "",
    higherEducation: "",
    courseName: "",
    collegeName: "",
    occupation: "",
    annualIncome: "",
    aboutMe: "",
    payment: false,
    isOtherSelected: false,
    verificationProof: "",
    myPayments: "",
    isVerified: false,
    image: "",
    favouriteProfile: "",
    blockedProfile: "",
    chatStatus: "none",
    currentChatReceiverId: "",
    acceptedRequest: false,
  });
  console.log(details, "details")
  const today = new Date();
  const minAge = details.gender === "male" ? 21 : 18;
  const maxAge = 60;
  const minAgeDate = new Date(today.getFullYear() - maxAge, today.getMonth(), today.getDate());
  const maxAgeDate = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate());

  const religions = Object.keys(Religion)
  const navigate = useNavigate();
  const colorChange = (index, e) => { setSelectedItem(index); handleChange(e); };
  const buttonChange = (index, e) => { SetSelectButton(index); handleChange(e); };
  const listItemStyle = (index) => {
    return {
      backgroundColor: selectedItem === index ? "#FFB000" : "",
      border: selectedItem === index ? "none" : "",
      color: selectedItem === index ? "white" : "",
    };
  };

  const buttonChangeColor = (index) => {
    return {
      backgroundColor: selectButton === index ? "#FFB000" : "",
      color: selectButton === index ? "white" : "",
    };
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
    try {
      if (
        (name === "email" && value.includes("@") && value.endsWith(".com")) ||
        (name === "mobile" && value.length === 10)) {
        const isValidEmail = name === "email" && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
        const isValidMobile = name === "mobile" && value.length === 10;
        if (isValidEmail || isValidMobile) {
          const response = await axios.post(apiUrl + "v1/profile/profile", { ...details, [name]: value, });
          setErrors("")
        }
      }
    } catch (err) {
      console.error("API Error:", err);
      if (err.response.data.message === "Email or mobile already exists") {
        setErrors(err.response.data.message);
      } else {
        setErrors("")
      }
    }
    if (name === "firstName" || name === "secondName") {
      const trimmedValue = value.replace(/^\s+/g, "");
      const nonNumericValue = trimmedValue.replace(/\d/g, "");
      setDetails((prevDetails) => ({ ...prevDetails, [name]: nonNumericValue }));
    } else if (name === "state") {
      setSelectedState(value); setDetails((prevDetails) => ({ ...prevDetails, [name]: value, city: "" }));
    } else if (name === "caste") {
      setDetails((prevDetails) => ({ ...prevDetails, [name]: value, subCaste: "" }));
    } else if (name === "feet" || name === "inches") {
      setDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
      setDetails((prevDetails) => ({ ...prevDetails, height: `${prevDetails.feet}-${prevDetails.inches}` }));
    } else if (name === "dateOfBirth") {
      const today = new Date();
      const birthDate = new Date(value);
      const age = Math.floor((today - birthDate) / (365.25 * 24 * 60 * 60 * 1000));
      const minAge = details.gender === "male" ? 21 : 18;
      const maxAge = 60;
      if (age < minAge) {
        setDateOfBirthError(`Must be at least ${minAge} years old.`);
      } else if (age > maxAge) {
        setDateOfBirthError(`Must be ${maxAge} years old or younger.`);
      } else {
        setDateOfBirthError(null); setDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
      }
    } else if (name === "profile") {
      let gender = details.gender;
      switch (value.toLowerCase()) {
        case "son":
        case "brother":
          gender = "Male";
          break;
        case "daughter":
        case "sister":
          gender = "Female";
          break;
        default:
          break;
      }
      setDetails((prevDetails) => ({ ...prevDetails, [name]: value, gender }));
    } else if (name === "courseName" || name === "collegeName") {
      const trimmedValue = value.replace(/^\s+/g, "");
      const nonNumericValue = trimmedValue.replace(/\d/g, "");
      setDetails((prevDetails) => ({ ...prevDetails, [name]: nonNumericValue }));
    }
    else if (name === "aboutMe") {
      const trimmedValue = value.replace(/^\s+/g, "");
      const nonNumericValue = trimmedValue.replace(/\d/g, "");
      setDetails((prevDetails) => ({ ...prevDetails, [name]: nonNumericValue }));
    }
    else if (name === "occupation") {
      setDetails((prevDetails) => ({ ...prevDetails, occupation: value, isOtherSelected: value === "Other", }));
    } else if (name === "otherOccupation") {
      setDetails((prevDetails) => ({ ...prevDetails, occupation: value }));
    }
     else {
      setDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
    }
  };

  const handleFileUpload = () => {
    const fileInput = fileInputRef.current;
    const file = fileInput.files[0];
    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (["jpg", "jpeg", "png", "svg"].includes(fileExtension)) {
        const addingImage = {};
        addingImage.target = { name: "image", value: file };
        handleChange(addingImage);
        setErrors("");
      } else {
        setErrors("Invalid file type. Please select a jpg, jpeg, png, or svg file.");
      }
    }
  };

  const onSubmit = (e) => { e.preventDefault(); };

  const onFormSubmit = async () => {
    try {
      let requiredFields = [];
      let isValidMobile = true;
      let isValidEmail = true;
      switch (step) {
        case 1:
          requiredFields = ["profile", "gender", "mobile", "email", "dateOfBirth", "firstName", "secondName",];
          isValidMobile = details.mobile && details.mobile.length === 10;
          isValidEmail =  details.email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(details.email) &&   /@gmail\.com$/.test(details.email);
          if (isValidMobile) {
            setMobileError("");
          }
         
          if (isValidMobile) {
            setDetails((prevDetails) => ({ ...prevDetails, mobile: details.mobile }));
          }
          if (isValidEmail) {
            setDetails((prevDetails) => ({ ...prevDetails, email: details.email }));
          }
          break;
        case 2:
          requiredFields = ["motherTounge", "country", "state", "city"];
          break;
        case 3:
          requiredFields = ["religion", "caste"];
          break;
        case 4:
          requiredFields = ["maritalStatus", "height", "familyType", "familyStatus", "disabilities",];
          break;
        case 5:
          requiredFields = ["higherEducation", "courseName", "collegeName", "occupation", "annualIncome",];
          break;
        default:
          break;
      }

      if (errors) {
        return;
      }
      if (dateOfBirthError) {
        return;
      }

      if (step < 6) {
        const missingFields = requiredFields.filter((field) => !details[field]);
        if (details.mobile && !isValidMobile) {
          setMobileError("Invalid mobile");
        }
        if (details.email && !isValidEmail) {
          setEmailError("Invalid Email");
        }
        requiredFields.forEach((field) => {
          const inputElement = document.getElementsByName(field)[0];
          if (inputElement && !details[field]) {
            inputElement.classList.add("missing-field");
            setTimeout(() => {
              inputElement.classList.remove("missing-field");
            }, 500);
          }
        });
        if (missingFields.length > 0 || (!isValidMobile && details.mobile) || (!isValidEmail && details.email)) {
          return;
        }
        setStep((nextstep) => nextstep + 1);
      } else {
        if (!isValidMobile || !isValidEmail) {
          return;
        }
        const form = new FormData();
        for (const key in details) {
          form.append(key, details[key]);
        }
        const formObject = {};
        form.forEach((value, key) => {
          formObject[key] = value;
        });
        const response = await axios.post(
          apiUrl + "v1/profile/sendOtp" 
        );
        if ((response.data = true)) {
          navigate("/mailverification", { state: formObject });
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  function getOption(caste) {
   
    if (details.caste) {
      const subCaste = caste?.filter((val) => val.name === details.caste);
      return subCaste[0]?.subcaste
    }
  }

  const previous = () => {
    if (step > 1) {
      setStep((back) => back - 1);
    } else if (step < 2) {
      navigate("/");
    }
  };

  useEffect(() => {

  }, [details.gender]);
  const formData = () => {
    switch (step) {
      case 1:
        return (
          <>
            <div className=" registrationpage-basic-details  ">
              <div className="left-div-1 ">
                <img className="mangalyan-icon" src="../Images/logo.png" alt="" />
              </div>
              <div className="details-header-container">
                <h4 className="details-header">Basic Details</h4>
                <p className="details-header-content">
                  Welcome to our registration page! Please fill in the following details to create your profile and start your journey.
                </p>
              </div>
              <div className="d-flex flex-column form-div">
                <div className="d-flex gap-2">
                  <div className="profile-selection  w-50">
                    <label className="registration-name">Profile</label>
                    <select
                      className="form-control select"
                      name="profile"
                      defaultValue="entername"
                      value={details.profile}
                      onChange={handleChange}
                    >
                      <option value="">Select</option>
                      {DetailsData.profile.map((profile) => (
                        <option key={profile} value={profile}>
                          {profile}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className=" profile-selection w-50">
                    <label className="registration-name">Gender</label>
                    <select
                      className="form-control select"
                      name="gender"
                      value={details.gender}
                      onChange={handleChange}
                    >
                      <option value="" className="select-option">
                        Select
                      </option>
                      <option value="Male" className="select-option">
                        {" "}
                        Male
                      </option>
                      <option value="Female" className="select-option">
                        Female
                      </option>
                    </select>
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <div className=" profile-selection w-50">
                    <label className="registration-name">Surname</label>
                    <input
                      className="form-control select"
                      placeholder="Enter your Surname"
                      name="firstName"
                      onChange={handleChange}
                      value={details.firstName}
                    />
                  </div>
                  <div className=" profile-selection w-50">
                    <label className="registration-name">Name</label>
                    <input
                      className="form-control select"
                      placeholder="Enter your Name"
                      name="secondName"
                      onChange={handleChange}
                      value={details.secondName}
                    />
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <div className=" profile-selection w-50">
                    <label className="registration-name">Date Of Birth</label>
                    <input
                      className="form-control select"
                      type="date"
                      placeholder="Enter your Date of Birth"
                      name="dateOfBirth"
                      onChange={handleChange}
                      value={details.dateOfBirth}
                      min={minAgeDate.toISOString().split("T")[0]}
                      max={maxAgeDate.toISOString().split("T")[0]}
                    />
                    {dateOfBirthError && (
                      <p className="error-message">{dateOfBirthError}</p>
                    )}
                  </div>
                  <div className=" profile-selection w-50">
                    <label className="registration-name">Mobile Number</label>
                    <input
                      className="form-control"
                      value={details.mobile}
                      placeholder="Enter your Mobile Number"
                      name="mobile"
                      onInput={(e) => {
                        const numericValue = e.target.value
                          .replace(/\D/g, "")
                          .slice(0, 10);
                        handleChange({
                          target: { name: "mobile", value: numericValue },
                        });
                      }}
                      onKeyDown={(e) => {
                        if (
                          !(e.key === "Backspace" || e.key === "Delete") &&
                          !/[\d]/.test(e.key)
                        ) {
                          e.preventDefault();
                        }
                      }}
                      type="text"
                    />
                    {mobileError && (
                      <p className="error-message" style={{ color: "red" }}>
                        {mobileError}
                      </p>
                    )}
                  </div>
                </div>
                <label className="registration-name">E-mail</label>
                <input
                  className="form-control select"
                  placeholder="Enter your E-Mail"
                  type="email"
                  name="email"
                  onChange={handleChange}
                  value={details.email}
                />
              </div>
              {emailError && (
                <p
                  className="error-message"
                  style={{ color: "red", margin: "0px" }}
                >
                  {" "}
                  {emailError}
                </p>
              )}
              {errors && (
                <p style={{ color: "red", margin: "0px" }}>{errors}</p>
              )}
            </div>
            <div className="right-div-1 ">
              <img src="../Images/image1.png" className="side-image" alt="" />
              <div className="right-div-1-cotations">
                <p className="cotations-registration">
                  Love begins with a single click
                </p>
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="d-flex flex-row container">
              <div className="left-div">
                <img className="mangalyan-icon" src="../Images/logo.png" alt="" />
                <div className="details-header-container">
                  <h4 className="details-header">Address</h4>
                  <p className="details-header-content">
                    {" "}
                    Welcome to the Address section. Please provide your current location details below.{" "}
                  </p>
                </div>
                <div className=" form-div">
                  <label className="registration-name">Country</label>
                  <select
                    className="form-control select"
                    name="country"
                    value={details.country}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    {DetailsData.country.map((country) => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                  <label className="registration-name">State</label>
                  <select
                    className="form-control select"
                    name="state"
                    value={details.state}
                    onChange={handleChange}
                  >
                    <option value="">Select State</option>
                    {DetailsData.states.map((state) => (
                      <option key={state.name} value={state.name}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                  <label className="registration-name">City</label>
                  <select
                    className="form-control select"
                    name="city"
                    value={details.city}
                    onChange={handleChange}
                  >
                    <option value="">Select City</option>
                    {selectedState &&
                      DetailsData.states
                        .find((s) => s.name === selectedState)
                        ?.cities.map((city) => (
                          <option key={city} value={city}>
                            {city}
                          </option>
                        ))}
                  </select>
                  <label className="registration-name">Mother Tounge</label>
                  <select
                    className="form-control select"
                    name="motherTounge"
                    onChange={handleChange}
                    value={details.motherTounge}
                  >
                    <option value="">Select</option>
                    {DetailsData.languages.map((languages) => (
                      <option key={languages} value={languages}>
                        {languages}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="right-div">
                <img src="../Images/image1.png" className="side-image" alt="" />
                <div>
                  <p className="cotations-registration">
                    Join us, where hearts meet and relationships begin
                  </p>
                </div>
              </div>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className=" d-flex flex-row container">
              <div className="left-div">
                <img className="mangalyan-icon" src="../Images/logo.png" alt="" />
                <div className="details-header-container">
                  <h4 className="details-header">Caste & Religion Details</h4>
                  <p className="details-header-content">
                    {" "}
                    Let's personalize your matrimony brand by providing
                    important Caste and Religion details. 
                    {/* This information helps
                    us match you with the most compatible profiles. Discover a
                    world of possibilities as you register. */}
                  </p>
                </div>
                <div className="form-div">
                  <label className="registration-name">Religion</label>
                  <select
                    className="form-control select"
                    name="religion"
                    onChange={handleChange}
                    value={details.religion}
                  >
                    <option value="">Select</option>
                    {religions.map((religion) => (
                      <option key={religion} value={religion}>
                        {religion}
                      </option>
                    ))}
                  </select>
                  <>
                    <label className="registration-name">Caste</label>
                    <select
                      className="form-control select"
                      name="caste"
                      value={details.caste}
                      onChange={handleChange}
                    >
                      <option value="">Select Caste</option>
                      {Religion[`${details.religion}`]?.map((caste) => (
                        <option key={caste.name} value={caste.name}>
                          {caste.name}
                        </option>
                      ))}
                    </select>
                  </>
                  <>
                    <label className="registration-name">Sub Caste</label>
                    <select
                      className="form-control select"
                      name="subCaste"
                      value={details.subCaste}
                      onChange={handleChange}
                    >
                      <option value="">Select Sub Caste</option>
                      {console.log(getOption(Religion[details.religion]),"GETOPTION")}
                      {getOption(Religion[details.religion])?.map((val) => {
                        return (
                          <option key={val} value={val}>
                            {val}
                          </option>
                        );
                      })}
                    </select>
                  </>
                </div>
              </div>
              <div className="right-div">
                <img src="../Images/image1.png" className="side-image" alt="" />
                <div className="right-div-1-cotations">
                  <p className="cotations-registration">
                    Discover a world of possibilities as you register
                  </p>
                </div>
              </div>
            </div>
          </>
        );
      case 4:
        return (
          <>
            <div className=" d-flex flex-row container ">
              <div className="left-div">
                <img className="mangalyan-icon" src="../Images/logo.png" alt="" />
                <div className="details-header-container">
                  <h4 className="details-header">Personal Details</h4>
                  <p className="details-header-content">
                    {" "}
                    Let's gather some important personal details to help you
                    find the perfect match. 
                    {/* Customize your matrimony profile
                    below. */}
                  </p>
                </div>
                <div className=" form-div">
                  <div className="registration-buttons-group">
                    <label className="registration-name">Maritual Status</label>
                    <div className="button-group">
                      <button
                        className="round-btn"
                        value="married"
                        name="maritalStatus"
                        style={listItemStyle("Married")}
                        onClick={(e) => colorChange("Married", e)}
                      >
                        Married
                      </button>
                      <button
                        className="round-btn"
                        value="un-married"
                        name="maritalStatus"
                        style={listItemStyle("UnMarried")}
                        onClick={(e) => colorChange("UnMarried", e)}
                      >
                        Un-Married
                      </button>
                      <button
                        className="round-btn"
                        name="maritalStatus"
                        value="widowed"
                        style={listItemStyle("widowed")}
                        onClick={(e) => colorChange("widowed", e)}
                      >
                        Widowed
                      </button>
                    </div>
                  </div>
                  <div className="registration-buttons-group">
                    <label className="registration-name mb-2">Height</label>
                    <div className="d-flex gap-2 ">
                      <select
                        className="form-control select"
                        name="feet"
                        onChange={handleChange}
                        value={details.feet}
                      >
                        <option value="">Feet</option>
                        {DetailsData.heights[0].feet.map((feet) => (
                          <option key={feet} value={feet}>
                            {feet}
                          </option>
                        ))}
                      </select>
                      <select
                        className="form-control select"
                        name="inches"
                        onChange={handleChange}
                        value={details.inches}
                      >
                        <option value="">Inches</option>
                        {DetailsData.heights[0].inches.map((inches) => (
                          <option key={inches} value={inches}>
                            {inches}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="registration-buttons-group">
                    <label className="registration-name mb-2">
                      Family Type
                    </label>
                    <select
                      className="form-control select"
                      name="familyType"
                      onChange={handleChange}
                      value={details.familyType}
                    >
                      <option value="" className="">
                        Select
                      </option>
                      {DetailsData.familyType.map((familyType) => (
                        <option key={familyType} value={familyType}>
                          {familyType}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="registration-buttons-group">
                    <label className="registration-name">Family Status</label>
                    <div className="button-group ">
                      <button
                        className={`round-btn ${selectedItem === "married" ? "active" : ""
                          }`}
                        name="familyStatus"
                        style={buttonChangeColor("Lower")}
                        onClick={(e) => buttonChange("Lower", e)}
                        value="Lower"
                      >
                        Lower
                      </button>
                      <button
                        className="round-btn"
                        value="Lower-middle"
                        name="familyStatus"
                        style={buttonChangeColor("lower-middle")}
                        onClick={(e) => buttonChange("lower-middle", e)}
                      >
                        Lower-Middle
                      </button>
                      <button
                        className="round-btn"
                        value="Upper-middle"
                        name="familyStatus"
                        style={buttonChangeColor("upper-middle")}
                        onClick={(e) => buttonChange("upper-middle", e)}
                      >
                        Upper-Middle
                      </button>

                      <button
                        className="round-btn"
                        value="Upper"
                        name="familyStatus"
                        style={buttonChangeColor("upper")}
                        onClick={(e) => buttonChange("upper", e)}
                      >
                        Upper
                      </button>
                    </div>
                  </div>
                  <div className="registration-buttons-group">
                    <label className="registration-name">
                      Any Disabilities
                    </label>
                    <div className="checkbox">
                      <div className="">
                        <input
                          type="radio"
                          value="yes"
                          name="disabilities"
                          onChange={handleChange}
                          checked={details.disabilities === "yes"}
                        />
                        <label className="registration-name">Yes</label>
                        <input
                          type="radio"
                          name="disabilities"
                          onChange={handleChange}
                          value="no"
                          checked={details.disabilities === "no"}
                        />
                        <label>No</label>
                      </div>
                    </div>
                    {details.disabilities === "yes" && (
                      <div>
                        <input
                          className="form-control select"
                          type="text"
                          placeholder="mention here."
                          name="disabilitiesDetail"
                          onChange={handleChange}
                          value={details.disabilitiesDetail}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="right-div">
                <img src="../Images/image2.png" className="side-image" alt="" />
                <div className="right-div-1-cotations">
                  <p className="cotations-registration">
                    Creating connections that last a lifetime
                  </p>
                </div>
              </div>
            </div>
          </>
        );
      case 5:
        return (
          <>
            <div className="d-flex flex-row container">
              <div className="left-div">
                <img className="mangalyan-icon" src="../Images/logo.png" alt="" />
                <div className="details-header-container">
                  <h4 className="details-header">Professional Details</h4>
                  <p className="details-header-content">
                    Share your professional journey to help make meaningful
                    connections. Fill in your education, career, and income
                    details below.
                  </p>
                </div>
                <div className=" form-div ">
                  <label className="registration-name">Highest Education</label>
                  <select
                    className="form-control select"
                    name="higherEducation"
                    onChange={handleChange}
                    value={details.higherEducation}
                  >
                    <option>Select</option>
                    {DetailsData.HighestEducation.map((education) => {
                      return (
                        <option key={education} value={education}>
                          {education}
                        </option>
                      );
                    })}
                  </select>
                  <label className="registration-name">Course Name</label>
                  <input
                    className="form-control select"
                    placeholder="Enter Course Name"
                    type="text"
                    name="courseName"
                    onChange={handleChange}
                    value={details.courseName}
                  />
                  <label className="registration-name"> College Name</label>
                  <input
                    className="form-control select"
                    placeholder="Enter College Name "
                    type="text"
                    name="collegeName"
                    onChange={handleChange}
                    value={details.collegeName}
                  />
                  <label className="registration-name">Occupation</label>
                  <select
                    className="form-control select"
                    name="occupation"
                    onChange={handleChange}
                    value={details.occupation}
                  >
                    <option value="">Select</option>
                    {DetailsData.Occupation.map((occupation) => (
                      <option key={occupation} value={occupation}>
                        {occupation}
                      </option>
                    ))}
                  </select>
                  {details.isOtherSelected && (
                    <input
                      className="form-control"
                      type="text"
                      name="otherOccupation"
                      onChange={handleChange}
                      placeholder="Enter occupation"
                      value={details.occupation}
                    />
                  )}
                  <label className="registration-name">Annual Income</label>
                  <select
                    className="form-control select"
                    name="annualIncome"
                    onChange={handleChange}
                    value={details.annualIncome}
                  >
                    <option value="">Select</option>
                    {DetailsData.Income.map((income) => (
                      <option key={income} value={income}>
                        {income}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="right-div">
                <img src="../Images/image3.png" className="side-image" alt="" />
                <div className="right-div-1-cotations">
                  <p className="cotations-registration">
                    Unveil the magic of companionship
                  </p>
                </div>
              </div>
            </div>
          </>
        );
      case 6:
        return (
          <>
            <div className="d-flex flex-row container">
              <div className="left-div">
                <img className="mangalyan-icon" src="../Images/logo.png" alt="" />
                <div>
                  <h4 className="details-header">Add Photo</h4>
                  <p>
                    Personalize your profile by adding a photo and sharing a bit about yourself. Unlock the door to love with a simple sign-up.
                  </p>
                </div>
                <div className="w-75">
                  <label className="registration-name">About Me</label>
                  <textarea
                    className="form-control select"
                    placeholder="Enter your first name"
                    name="aboutMe"
                    onChange={handleChange}
                    value={details.aboutMe}
                  />
                </div>
                <div>
                  <label htmlFor="fileInput">
                    {details.image ? (
                      <img src={URL.createObjectURL(details.image)} alt="" style={{ width: "250px", height: "250px" }} />
                    ) : (
                      <img src="../Images/addPhoto.svg" alt="moimafe" style={{ width: "250px", height: "250px" }} />
                    )}
                  </label>
                  <input
                    id="fileInput"
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    onChange={handleFileUpload}
                    style={{ display: "none" }}
                  />
                </div>
                {errors && <p style={{ color: "red" }}>{errors}</p>}
              </div>
              <div className="right-div">
                <img src="../Images/image4.png" className="side-image" alt="" />
                <div className="right-div-1-cotations">
                  <p className="cotations-registration">
                    Unlock the door to love with a simple sign-up
                  </p>
                </div>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };
  return (
    <div className="container-fluid">
      <div className="container ">
        <form onSubmit={onSubmit}>
          <div className="registration-formdata ">{formData()}</div>
          <div className="buttons-div  ">
            <button className="back rounded-3" onClick={() => { previous(); }}>
              {step < 2 ? "Back" : "Previous"}
            </button>
            <button className="next rounded-3" onClick={() => { onFormSubmit(); }}>
              {step < 6 ? "Next" : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};