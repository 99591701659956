import React from 'react';
import Popup from 'reactjs-popup';
import './Allusers.css';
import { MyProfileContext } from '../../../../context/ProfileProvider';
import PopUp from '../PopUp';
import { CiSearch } from 'react-icons/ci';

export const Membership = () => {
    const { allProfiles } = React.useContext(MyProfileContext);
    const [searchQuery, setSearchQuery] = React.useState('');
    const [dateFilter, setDateFilter] = React.useState('');

    const filteredProfiles = allProfiles
        .filter((val) => val.payment === true)
        .filter((val) => {
            const firstNameMatch = val.firstName.toLowerCase().includes(searchQuery.toLowerCase());
            const emailMatch = val.email.toLowerCase().includes(searchQuery.toLowerCase());
            const mobileMatch = val.mobile && typeof val.mobile === 'string' && val.mobile.toLowerCase().includes(searchQuery.toLowerCase());
            const createdAtMatch = dateFilter ? new Date(val.createdAt).toLocaleString().includes(dateFilter) : true;
            return firstNameMatch || emailMatch || mobileMatch || createdAtMatch;
        });

    const calculateAge = (dateOfBirth) => {
        const birthDate = new Date(dateOfBirth);
        const currentDate = new Date();
        let age = currentDate.getFullYear() - birthDate.getFullYear();
        if (
            currentDate.getMonth() < birthDate.getMonth() ||
            (currentDate.getMonth() === birthDate.getMonth() &&
                currentDate.getDate() < birthDate.getDate())
        ) {
            age--;
        }
        return {
            age,
        };
    };

    return (
        <>
            <div className="user-inputfield">
                <button className="navbar-btn">{<CiSearch />}</button>
                <input
                    type='text'
                    placeholder='Search by name, email, or mobile'
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <input
                    type='date'
                    placeholder='Filter by date'
                    value={dateFilter}
                    onChange={(e) => setDateFilter(e.target.value)}
                />
            </div>
            <div className='d-flex flex-wrap'>
                {filteredProfiles.map((val) => {
                    const { age } = calculateAge(val.dateOfBirth);
                    return (
                        <div className='main-admincard-container'>
                            <div className='alluser-container'>
                                <div className='first-content-all d-flex'>
                                    <div>
                                        <img className='ign-img' src={val.image[0]} alt='No image'></img>
                                    </div>
                                    <div className='second-content'>
                                        <h6>{val.firstName}</h6>
                                        <p>{age} yrs |  </p>
                                        <p>{val.caste} | </p>
                                        <p>  {val.occupation}</p>
                                        <Popup
                                            modal
                                            className='popup-modal'
                                            trigger={<button className='view-profile-btn'>View Profile</button>}
                                            position='right top'
                                        >
                                            <div className='popup-container'>
                                                <PopUp profileDetails={val} />
                                            </div>
                                        </Popup>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};
