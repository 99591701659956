import React, { useState } from "react";
import "../css/profilePopup.css";
import { MyProfileContext } from "../../../context/ProfileProvider";

const tabs = [{ id: 1, name: "Basic Details" }, { id: 2, name: "Personal Details" }, { id: 3, name: "Professional Details" }];

const ProfilePopup = (props) => {
  const { profileDetails } = props;
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const { myProfile } = React.useContext(MyProfileContext);

  const renderBasicDetails = () => {
    return (
      <div className="popup-tab-content">
        <div className="prfpop-about-section">
          <h1 className="basic-details-section-headers">About</h1>
          <p>{profileDetails.aboutMe}</p>
        </div>
        <div className="prfpop-basic-detail">
          <h1 className="basic-details-section-headers">Basic Details</h1>
          <div className="prfpop-basic-detail-main">
            <div className="prfpop-basic-detail-names">
              <div className="">
                <div className="popup-profile-container">
                  <h1 className="popup-profile-heading">Profile</h1>
                  <p className="popup-profile-content">
                    {profileDetails.profile}
                  </p>
                </div>
                <div className="popup-profile-container">
                  <h1 className="popup-profile-heading">First Name</h1>
                  <p className="popup-profile-content">
                    {profileDetails.firstName}
                  </p>
                </div>
                <div className="popup-profile-container">
                  <h1 className="popup-profile-heading">Second Name</h1>
                  <p className="popup-profile-content">
                    {profileDetails.secondName}
                  </p>
                </div>
                <div className="popup-profile-container">
                  <h1 className="popup-profile-heading">Phone Number</h1>
                  <p className="popup-profile-content">
                    {myProfile.payment === true ? (
                      <>
                        {String(profileDetails.mobile)}{" "}
                        <span className="verified-span">Verified</span>
                      </>
                    ) : (
                      <>
                        {String(profileDetails.mobile)?.slice(0, 3)}xxxx{" "}
                        <span className="verified-span">Verified</span>
                      </>
                    )}
                  </p>
                </div>
              </div>
              <div className="">
                <div className="popup-profile-container">
                  <h1 className="popup-profile-heading">Gender</h1>
                  <p className="popup-profile-content">
                    {profileDetails.gender}
                  </p>
                </div>
                <div className="popup-profile-container">
                  <h1 className="popup-profile-heading">DOB</h1>
                  <p className="popup-profile-content">
                    {profileDetails.dateOfBirth}
                  </p>
                </div>
                <div className="popup-profile-container">
                  <h1 className="popup-profile-heading">Email</h1>
                  <p className="popup-profile-content">
                    {myProfile.payment === true ? (
                      <>
                        {profileDetails.email}{" "}
                        <span className="verified-span">Verified</span>
                      </>
                    ) : (
                      <>
                        {profileDetails?.email.substring(0, 3)}xxx@gmail.com{" "}
                        <span className="verified-span">Verified</span>
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div className="prfpop-basic-detail-names">
              <div>
                <div className="popup-profile-container">
                  <h1 className="popup-profile-heading">Country</h1>
                  <p className="popup-profile-content">
                    {profileDetails.country}
                  </p>
                </div>
                <div className="popup-profile-container">
                  <h1 className="popup-profile-heading">State</h1>
                  <p className="popup-profile-content">
                    {profileDetails.state}
                  </p>
                </div>
                <div className="popup-profile-container">
                  <h1 className="popup-profile-heading">City</h1>
                  <p className="popup-profile-content">{profileDetails.city}</p>
                </div>
              </div>
              <div>
                <div className="popup-profile-container">
                  <h1 className="popup-profile-heading">Caste</h1>
                  <p className="popup-profile-content">
                    {profileDetails.caste}
                  </p>
                </div>
                <div className="popup-profile-container">
                  <h1 className="popup-profile-heading">Sub-Caste</h1>
                  <p className="popup-profile-content">
                    {profileDetails.subCaste}
                  </p>
                </div>
                <div className="popup-profile-container">
                  <h1 className="popup-profile-heading">Religion</h1>
                  <p className="popup-profile-content">
                    {profileDetails.religion}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="basic-photo-album-container">
          <h1 className="basic-details-section-headers">Photo Album</h1>
          <div className="photo-album-images-cont"></div>
          <img
            className="basic-photo-album-img"
            src={profileDetails.image[1]}
            alt="imagenotloaded"
            style={{ filter: myProfile.payment === false ? "blur(4px)" : "none", }}
          />
          <img
            className="basic-photo-album-img"
            src={profileDetails.image[2]}
            alt="imagenotloaded"
            style={{ filter: myProfile.payment === false ? "blur(4px)" : "none", }}
          />
        </div>
      </div>
    );
  };

  const renderPersonalDetails = () => {
    return (
      <div className="prfpop-personal-detail">
        <h1 className="basic-details-section-headers">Personal Details</h1>
        <div className="prfpop-basic-detail-main">
          <div className="prfpop-basic-detail-names">
            <div>
              <div className="popup-profile-container">
                <h1 className="popup-profile-heading">Marital Status</h1>
                <p className="popup-profile-content">
                  {profileDetails.maritalStatus}
                </p>
              </div>
              <div className="popup-profile-container">
                <h1 className="popup-profile-heading">Family Status</h1>
                <p className="popup-profile-content">
                  {profileDetails.familyStatus}
                </p>
              </div>
              <div className="popup-profile-container">
                <h1 className="popup-profile-heading">Height</h1>
                <p className="popup-profile-content">{profileDetails.height}</p>
              </div>
            </div>
            <div>
              <div className="popup-profile-container">
                <h1 className="popup-profile-heading">Any Disabilities</h1>
                <p className="popup-profile-content">
                  {profileDetails.disabilities}
                </p>
              </div>
              <div className="popup-profile-container">
                <h1 className="popup-profile-heading">Family Type </h1>
                <p className="popup-profile-content">
                  {profileDetails.familyType}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderProfessionalDetails = () => {
    return (
      <div className="prfpop-personal-detail">
        <h1 className="basic-details-section-headers">Professional Details</h1>
        <div className="prfpop-basic-detail-main">
          <div className="prfpop-basic-detail-names">
            <div>
              <div className="popup-profile-container">
                <h1 className="popup-profile-heading">Highest Education</h1>
                <p className="popup-profile-content">
                  {profileDetails.higherEducation}
                </p>
              </div>
              <div className="popup-profile-container">
                <h1 className="popup-profile-heading">Occupation</h1>
                <p className="popup-profile-content">
                  {profileDetails.occupation}
                </p>
              </div>
              <div className="popup-profile-container">
                <h1 className="popup-profile-heading">Course Name</h1>
                <p className="popup-profile-content">
                  {profileDetails.courseName}
                </p>
              </div>
            </div>
            <div>
              <div className="popup-profile-container">
                <h1 className="popup-profile-heading">Annual Income</h1>
                <p className="popup-profile-content">
                  INR {profileDetails.annualIncome}
                </p>
              </div>
              <div className="popup-profile-container">
                <h1 className="popup-profile-heading">College Name</h1>
                <p className="popup-profile-content">
                  {profileDetails.collegeName}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 1:
        return renderBasicDetails();
      case 2:
        return renderPersonalDetails();
      case 3:
        return renderProfessionalDetails();
      default:
        return null;
    }
  };

  return (
    <div className="basic-details-container-main">
      <nav className="basic-details-navbar">
        <ul className="basic-details-ul-navbar">Profile</ul>
      </nav>
      <div className="image-details-div">
        <img
          className="profile-image-img"
          src={profileDetails.image[0]}
          alt="not-loaded"
          style={{ filter: myProfile.payment === false ? "blur(4px)" : "none", }}
        />
        <div className="profile-data-div">
          <h1 className="profile-Name-head">
            {profileDetails.firstName} {profileDetails.secondName}
          </h1>
          <p>
            {myProfile.payment === true ? (
              <>
                {String(profileDetails.mobile)}{" "}
                <span className="verified-span">Verified</span>
              </>
            ) : (
              <>
                {String(profileDetails.mobile)?.slice(0, 3)}xxxx{" "}
                <span className="verified-span">Verified</span>
              </>
            )}
          </p>
          <p>
            {myProfile.payment === true ? (
              <>
                {profileDetails.email}{" "}
                <span className="verified-span">Verified</span>
              </>
            ) : (
              <>
                {profileDetails?.email.substring(0, 3)}xxx@gmail.com{" "}
                <span className="verified-span">Verified</span>
              </>
            )}
          </p>
        </div>
      </div>
      <div className="list-data-container">
        <ul className="basic-detail-ul-container">
          {tabs.map((eachTab) => (
            <li
              key={eachTab.id}
              className={
                activeTab === eachTab.id
                  ? "profile-popup-active-tab"
                  : "profile-popup-inactive-tab"
              }
              onClick={() => setActiveTab(eachTab.id)}
            >
              {eachTab.name}
            </li>
          ))}
        </ul>

        <div>{renderTabContent()}</div>
      </div>
    </div>
  );
};

export default ProfilePopup;
