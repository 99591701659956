import React, { useState } from "react";
import "./css/setpassword.css";
import { useNavigate } from "react-router-dom";
import apiUrl from "../../config";
import axios from 'axios';
import { FaRegEyeSlash, FaEye } from "react-icons/fa";


const SetPassword = () => {
  const navigate = useNavigate();
  const [details, setDetails] = useState({
    email: "",
    password: ""
  });
  const [error, setError] = useState('')
  const [isMin8Chars, setIsMin8Chars] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [isFieldFocused, setIsFieldFocused] = useState(false);

  const createpassword = async () => {
    try {
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
      if (!passwordRegex.test(details.password)) {
        return;
      }
      await axios.patch(apiUrl + "v1/profile/addPassword", details);
      navigate('/login');
    } catch (error) {
      console.error("Error creating password:", error);
      setError(error.response.data.message)

    }
  };

  const togglePasswordVisibility = () => {
    setIsFieldFocused(!isFieldFocused);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailRegex.test(value);
      setDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
      if (!isValidEmail) {
        setError('Invalid email address');
      } else {
        setError('');
      }
    } else {
      setDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
      if (!error) {
        setIsMin8Chars(value.length >= 8);
        setHasUpperCase(/[A-Z]/.test(value));
        setHasLowerCase(/[a-z]/.test(value));
        setHasNumber(/\d/.test(value));
        setHasSpecialChar(/[!@#$%^&*()_+]/.test(value));
      }
    }
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="container">
          <div className="setpassword-container">
            <div className="setpassword-innermain-container">
              <div className="setpassword-image-div">
                <img className="setpassword-img" src="../images/maangalya.png" alt="" />
              </div>
              <div className="setpassword-maincontent">
                <h3 className="setpassword-mainheading">Set Your Password</h3>
                <p className="setpassword-mainpara">Create Your Secure Password</p>
              </div>
              <div className="setpassword-innercontent">
                <div className="setpassword-innercontent-one">
                  <p className="setpassword-topcontent">
                    Welcome to
                    <span className="setpassword-span-innercontent">
                      {" "}
                      MAANGALYAAM!
                    </span>
                  </p>
                  <div className="setpassword-bottomcontent-one">
                    <p className="setpassword-bottomcontent">
                      {" "}
                      To ensure the security of your account, please create a
                    </p>
                    <p className="setpassword-bottomcontent">
                      {" "}
                      strong and memorable password.
                    </p>
                  </div>
                </div>
                <div className="setpassword-innercontent-two">
                  <p className="setpassword-bottomcontent">
                    {" "}
                    Your journey to finding love begins with a secure
                  </p>
                  <p className="setpassword-bottomcontent">foundation.</p>
                </div>
              </div>
              <div className="setpassword-form-container">
                <form onSubmit={onFormSubmit}>
                  <div className="setpassword-email-content">
                    <p className="setpassword-content-style">Email</p>
                    <input
                      className=" form-control setpassword-email"
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={details.email}
                      onChange={handleChange}
                    />
                  </div>
                  {error && <p style={{ color: 'red' }}>{error}</p>}

                  <div className="setpassword-contents">
                    <label className="setpassword-content-style">Password</label>
                    <div className="setpassword-input-container">
                      <input
                        className="form-control setpassword-password"
                        type={isFieldFocused ? "text" : "password"}
                        placeholder="Enter here"
                        name="password"
                        value={details.password}
                        onChange={handleChange}
                      />
                      {isFieldFocused ? (
                        <FaRegEyeSlash onClick={togglePasswordVisibility} className="setpassword-eye-icon" />
                      ) : (
                        <FaEye onClick={togglePasswordVisibility} className="setpassword-eye-icon" />
                      )}
                    </div>
                  </div>
                </form>
                <div className="set-icons">
                  <p className={`setset-para ${isMin8Chars ? "strike" : ""}`}>
                    <img src={isMin8Chars ? "../Images/right.svg" : "../Images/wrong.svg"} alt="" />
                    Min 8 characters
                  </p>
                  <p className={`setset-para ${hasUpperCase ? "strike" : ""}`}>
                    <img src={hasUpperCase ? "../Images/right.svg" : "../Images/wrong.svg"} alt="" />
                    1 Upper case Letter
                  </p>
                  <p className={`setset-para ${hasLowerCase ? "strike" : ""}`}>
                    <img src={hasLowerCase ? "../Images/right.svg" : "../Images/wrong.svg"} alt="" />
                    1 Lower case Letter
                  </p>
                  <p className={`setset-para ${hasNumber ? "strike" : ""}`}>
                    <img src={hasNumber ? "../Images/right.svg" : "../Images/wrong.svg"} alt="" />
                    1 Number
                  </p>
                  <p className={`setset-para ${hasSpecialChar ? "strike" : ""}`}>
                    <img src={hasSpecialChar ? "../Images/right.svg" : "../Images/wrong.svg"} alt="" />
                    1 Special character
                  </p>
                </div>
                <button className="setpassword-password-btn" onClick={createpassword}>
                  Set Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetPassword;